import React from 'react';

const OutcomesContent = (props) => {
	return (
		<div className="detailsContent">
			<p>Philosophy is huge.  The number of ways to teach an introductory philosophy course is practically without limit.  This particular course, <em>Philosophy and Contemporary Issues</em>, bends toward philosophy that is <em>current</em> and <em>relevant</em>.  It is structured around three sorts of philosophical topics: </p>

			<ul>
				<li>classic philosophical issues and debates that are of relevance to contemporary affairs, current science, or modern life</li>
				
				<li>new philosophical issues (or new takes on old philosophical issues) that have emerged with the rise of new technology and scientific understanding</li>

				<li>philosophical issues that enjoy popular attention and that have captured the contemporary imagination</li>
			</ul>

			<p>By the end of this course, you'll have a deeper understanding of a variety of current-day philosophical issues and be able to converse intelligently about them.</p>

			<p>A second goal of the couse is for you to learn not just about philosophical topics, but about <em>how to do</em> philosophy.  You'll learn to reason well and think critically about deep issues.  When you come out of the course, you will be better at identifying, evaluating, and constructing arguments using a variety of philosophical methods.  These skills can potentially carry over to any future career you pursue.</p>
	    </div>
	);
}

export default OutcomesContent;