import React from 'react';
import { Anchor } from 'antd';
import Media from 'react-media';

const SyllabusAnchor = ( {details, changeDetailsTab}) => {

	const { Link } = Anchor;

	const noAddressBar = (e, link) => {
	  e.preventDefault();
	};

	return (
		<Anchor affix={false} 
            	showInkInFixed={true} 
            	onClick={noAddressBar} 
            	style={{background: "#001529", color: "@gold-1", marginLeft: 30}} 
            	getContainer={()=>document.getElementById("layoutMainContent")}
            	offsetTop={0}
            	offsetBottom={0}>
    		<Link href="#contentTop" title="Title" />
    		<Link href="#coursedesc" title="Description" />
    		<Link href="#calendar" title="Schedule" />

    		<Media query="(max-width: 992px)">
	      		{matches => matches ?
	      		<>
                <Link href="#outcomes" title="Outcomes" />
	      		<Link href="#readings" title="Readings" />
	      		<Link href="#evaluation" title="Evaluation" />
	      		<Link href="#policies" title="Policies" />
                <Link href="#resources" title="Resources" />
                <Link href="#contact" title="Contact" />
	      		</>
	      		:
        		<Link href="#info" title="Details">
                    <div onMouseEnter={(e)=>{changeDetailsTab("1",e)}}><Link href={details==="1" ? "#info" : "#info1"} title="Outcomes" /></div>
                    <div onMouseEnter={(e)=>{changeDetailsTab("2",e)}}><Link href={details==="2" ? "#info" : "#info2"} title="Readings" /></div>
        			<div onMouseEnter={(e)=>{changeDetailsTab("3",e)}}><Link href={details==="3" ? "#info" : "#info3"} title="Evaluation" /></div>
        			<div onMouseEnter={(e)=>{changeDetailsTab("4",e)}}><Link href={details==="4" ? "#info" : "#info4"} title="Policies" /></div>
                    <div onMouseEnter={(e)=>{changeDetailsTab("5",e)}}><Link href={details==="5" ? "#info" : "#info5"} title="Resources" /></div>
                    <div onMouseEnter={(e)=>{changeDetailsTab("6",e)}}><Link href={details==="6" ? "#info" : "#info6"} title="Contact" /></div>
        		</Link>
        		}
    		</Media>
    	</Anchor>
		);
}

export default SyllabusAnchor;
