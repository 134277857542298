import React from 'react';
import { Button } from 'antd';

const AnimationPlayButton = (props) => {

	return (
		<Button onClick={props.click} type="" 
			style={{
					margin: "auto", 
					width: 200, 
					padding: "20px 30px 40px 30px"}}> 
				{props.children}
		</Button>
	);
}

export default AnimationPlayButton;