import React from 'react';
import moment from 'moment';

const allClasses = [

	{
		category: "Introduction",
		title: "What Is Philosophy?",
		hashID: "wip",
		lecture: 'whatisphilosophy',
		readingsO: [["Stich and Donaldson, chapter 1", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
    		<p>This course is an introduction to <i>Philosophy</i> with a special emphasis on <i>Contemporary Issues</i>. We'll be looking at how philosophy informs current-day debates, how philosophy has been changed by new technologies and new ways of living, and at age-old philosophical topics that are still relevant to the modern world and even your everyday life.</p>
    		<p>But what exactly is philosophy?  This is, in itself, a philosophical question!  The best way to learn about philosophy is simply to do philosophy, so we'll dive in soon.  Still, it makes sense to spend a little time in this first class discussing what it is that philosophers do. </p>
	    	</>,
	},

	{
		category: "Introduction",
		title: "Arguments",
		hashID: "arguments",
		readings: [["Stich and Donaldson, chapter 2", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		slides: "arguments",
		content: ()=><>
    		<p>If nothing else, philosophers make arguments.</p>
    		<p>This doesn't mean that philosophers bicker or squabble or quarrel (though they do plenty of those, to be sure!).  It means that philosophers provide reasons for thinking that their views are true.  In their purest form, philosophical arguments are aimed at seeking truth rather than convincing opponents; the goal is proof, not persuasion.</p>
    		<p>In this class, you'll learn about ways to evaluate arguments and ways to construct strong arguments for yourself.  The patterns of good reasoning you will learn in the class can impact the way that you think about pretty much anything.  Pretty glorious.</p>
	    	</>,
	},

	{
		category: "Introduction",
		title: "Fallacies",
		hashID: "fallacies",
		slides: "fallacies",
		readings: [["Stich and Donaldson, chapter 2", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [["Texas State Department of Philosophy: Informal Fallacies", "https://www.txstate.edu/philosophy/resources/fallacy-definitions.html"],
					["Wikipedia list of Fallacies", "https://en.wikipedia.org/wiki/List_of_fallacies"],
					["Wikipedia list of Cognitive Biases", "https://en.wikipedia.org/wiki/List_of_cognitive_biases"]],
		content: ()=><>
    		<p>This class continues our investigation of arguments and argument structure.</p>
    		<p>A good way to learn to how to reason well is to learn how to avoid reasoning badly.  That's why in addition to learning about strong argument structures you should applaud, we'll also learn about weak argument structures you should boo.  We'll talk about patterns of poor thinking.  Anti-patterns. <em>Fallacies.</em></p>
    		<p>We'll pay special attention to my favorite fallacy &mdash; the <em>Use/Mention Error</em> &mdash; and show how easy it is to accidentally slip into and how much havoc it can wreak on thought.</p>
	    	</>,
	},

	// This class has gone trribly two times in a row!  I absolutely need to restructure it.
	// Not all are errors of reasoning.  The strawman fallacy, for example, isn't a error of reasoning.
	// Definitely get rid of the Necessary and Sufficient conditions portion.  It's a hot mess.  Get rid of the part where I ask, "Are these actually instances of begging the question?"
	// The use/mention error portion goes well.
	// Replace this whole thing with stuff from the critical thinking course on system 1 system 2, I think.  Keep begging the question because it comes up in the Cartesian Circle.  Maybe keep equivocation.  Scrap a lot of the rest.

				/* ------------ */
				/* EPISTEMOLOGY */
				/* ------------ */

	{
		category: "Metaphysics",
		title: "Simulation",
		video: [["Vox Video Lab presentation on the Simulation Argument", "https://www.youtube.com/watch?v=J0KHiiTtt4w"]],
		readings: [[`Nick Bostrom: "The Simulation Argument: Why the Probability That You Are Living in a Matrix is Quite High"`, "texts/BostromSA.pdf"]],
		readingsO: [[`David Chalmers: "The Matrix as Metaphysics"`, "./texts/ChalmersMM.pdf"]],
		content: ()=><>
			<p>Let's test out our new philosophical skills by drilling down on a single argument.  This one is a modern take on a very old philosophical idea.</p>
			<p>You're probably familiar with <em>The Matrix</em>, the movie in which Keanu Reeves's character discovers that he is living in a virtual world.  The <em>simulation argument</em> takes seriously the idea that we are in something like The Matrix. It has convinced many people that we're all probably living in a computer simulation.</p>
			<p>A lot of you will find this conclusion pretty ridiculous.  That's why it's a great way to kick off the course.  It feels like something must be wrong with the argument... but what?  Philosophy involves argument, not simple disagreement. The simulation argument is very precise, and it is not clear where (or if!) it goes wrong.  Try to think clearly about which premises you disagree with and whether the inference is correct, and be prepared to share your reasoning in class as we debate whether we are living in a video game.</p>
			</>,
		hashID: "simulation",
		slides: "simulation",
		prompt: "Bostrom offers three claims on page 2 of his paper.  He argues that one of them must be true.  Do you agree that one of them must be true?  Which one are you most inclined to accept, and why?",
	},

	// This went poorly too.
	// The propositions are too wordy and I keep switching between them and their denials.  Simplify them like the Vox video does.
	// They are best understood as involving degrees of certainty, and that's difficult to explain at this stage.  I want to say that you don't have to believe any of them but your credences in the three should add to 1.  How can I do this?  Making the three deductive arguments as I do is confusing.  It's esepcially problematic given that I stress that this is a good example of philosophical argument as we've learned two classes ago.
	// The "discussion points" as I have them are poorly organized.  I flipped back and forth and they didn't correspond to natural discussion.
	// 2023: It's a popular class, but now that Bostrom is cancelled and I have a video on Musk, the class is CURSED.  Take out the video (sadly), and restructure those terrible arguments full of all sorts of double negatives.  They can be made so much simpler and less confusing!!!

	{
		category: "Epistemology",
		title: "Doubt",
		readings: [["Stich and Donaldson: chapter 5, sections 1–4", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [["René Descartes, Meditations on First Philosophy, Meditation 1", 
					"/texts/DescartesM.pdf"]],
		content: ()=><>
			<p>The simulation argument is a metaphysical argument: it tries to establish something about the fundamental nature of the world.  But some of you might come away with a slightly different worry: one about how we could <em>know</em> whether or not we live in a virtual world. Could we ever know? Reality might be very different than it appears to us... so can we ever be sure about anything?</p>
			<p>These are questions of epistemology: the study of knowledge. Over the next few weeks, we'll be looking at how knowledge is possible at all. </p>
			<p>We'll begin with the work of a 17<sup>th</sup> century French philosopher named René Descartes who hoped to establish a secure foundation for the sciences. His method consisted of imagining scenarios not unlike The Matrix: ones in which appearances of the world are misleading. Can we know that we're not dreaming?  Can we know that we're not manipulated by an evil demon who is causing us to see a fake reality?  In order to figure out what we know, Descartes begins by investigating the sorts of things it is possible to doubt.</p>
				</>,
		hashID: "doubt",
		slides: 'doubt',
		prompt: "It's easy to say that you might be dreaming right now. But you probably don't actually think you are dreaming right now. Why do you not think that you're dreaming?  Give your best argument for thinking that you are not dreaming, then give a counterargument against that argument.",
	},

	// EXCELLENT CLASS. Solid lecturing for the first half, then dealing with the students' existential fears.
	// I wonder if I should do this right away, before the simulation argument.  It also nicely builds off of the lecture on arguments: finding a foundational set of premises.  Maybe the fallacies and simulation classes should go.  I could even put arguments in the epistemolog section.


	{
		category: "Epistemology",
		title: "Certainty",
		readings: [["Stich and Donaldson: chapter 5, sections 5-9", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [["René Descartes, Meditations on First Philosophy, Meditations 2-4", 
					"/texts/DescartesM.pdf"]],
		content: ()=><>
			<p>Descartes established that all sorts of apparent facts can be potentially doubted.  The next step was to ask which facts are not up for doubt.</p>
			<p>One of his conclusions was that he could not doubt that he existed.  Only something that exists can doubt, so if he managed to doubt that he existed, that would prove that he existed.  This is the origin of one of the more famous sentences in philosophy, which you have likely heard before: "I think, therefore I am."</p>
			<p>This was just the first step in an attempt to secure certainty for a much wider body of knowledge.  We'll look at Descartes' "<em>cogito</em> argument" in detail, and investigate whether it really does provide a ground for all scientific knowledge in the way that Descartes hoped it would.</p>
			</>,
		slides: "certainty",
		hashID: "certainty",
		prompt: <><p>Many philosophers following Descartes have claimed that we know our conscious states with perfect certainty: if I am visually experiencing the color red, then I know that I am visually experiencing red, and no one can tell me that I am wrong. But here is an argument against this line of thought:</p>
			<p style={{paddingLeft: "2em", fontStyle: "italic"}}>Being in love is a conscious state if anything is. But a person can be wrong about whether he or she is in love! Therefore, we do not know our conscious states with perfect certainty.</p>
			<p>Do you agree that we can be wrong about being in love? What might Descartes have to say about this?</p></>,
	},


	{
		category: "Epistemology",
		title: "Knowledge",
		readings: [["Stich and Donaldson: chapter 8, sections 1–4; then, chapter 3, pages 29-30", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>Maybe shooting for absolute certainty is too demanding a goal.  Epistemologists today are usually more concerned with knowledge than with certainty.  What is knowledge?  What does it take for us to know that the dinosaurs went extinct, or that strawberries are perennial plants, or that New York City is in New York State?</p>
			<p>A common answer is that knowledge is true belief held for good reasons.  But what count as "good reasons"?</p>
			<p>Can I know that something is true if I believe it just because a professor said it?  If I read something on social media, is trusting the person who posted the link good enough for my belief to count as knowledge?  If I believe something simply in order to fit in, can I know it?</p>
			</>,
		hashID: "knowledge",
		slides: "knowledge",
		prompt: "Read about the difference between evidential and pragmatic reasons for belief on pages 29 and 30.  Do you have any beliefs that you hold primarily for pragmatic reasons rather than evidential reasons?  Do you think that it would be fair for someone to criticize you for having these beliefs?"
	},

	{
		category: "Epistemology",
		title: "Justification I",
		readings: [["Stich and Donaldson: chapter 8, sections 5 and 6", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>Think of something you believe: "The sky is sometimes blue", "Clarkson is in Potsdam", "cheetahs are fast", etc.</p>
			<p>Now imagine that you meet a precocious seven-year-old who doesn't believe you.  Think of an argument that might convince her.</p>
			<p>Okay, now imagine that she doesn't buy your premises, and you need to argue for the truth of them with new arguments.</p>
			<p>Okay, now imagine that she doesn't buy your new premises, and you need to argue for the truth of those with even more new arguments.</p>
			<p>This seven-year-old is very annoying.  But her tenacity hints at an interesting philosophical problem.  Our beliefs are justified by other beliefs, but what justifies those beliefs?  Is there an eventual bedrock?  Can the process of justification go on forever?</p>
		</>,
		hashID: "justification1",
		slides: "justification1",
		prompt: `Suppose you meet someone who believes that molemen live beneath the Earth's crust.  When you ask them why they think that, they say, "Oh, no reason at all. My beliefs about molemen are epistemically basic beliefs.  They're self-justified. I don't need to have reasons."  What could you say in response?  Do you think this example causes problems for foundationalism?`,
	},

	{
		category: "Epistemology",
		title: "Justification II",
		readings: [["Stich and Donaldson: chapter 8, sections 7 and 8", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>We'll continue our investigation of justification in this class by considering different theories about how beliefs get justified.  You will learn about the difference between <em>internalist</em> and <em>externalist</em> theories of justification, and we will focus on an externalist theory of justification known as <em>reliabilism</em>.</p>
		</>,
		hashID: "justification2",
		slides: "justification2",
		prompt: `Describe a scenario where two people share the same belief, but one person formed it through a reliable process and the other person formed it through an unreliable process.  (Try to come up with a case where this actually happened to people you know.)`,
	},

	// this week did not go well.  too theoretical and too hard.  keep the part on knowledge, maybe move into the class on opinion, and either jettison the justification classes or combine them.
	// Second time teaching: no, it went surprisingly well this time!  I think it still needs work, but it went much better.  I could change how I talk about internalism, I think.  I liked holding off on reliabilism for a little while.  Talking about brains in vats in order to introduce internalism worked well... I could do a better job of it, but I think it was the right strategy.
	// 2022: This week should be restructured.  I ran out of matieral in the previois justification class, and this class was really hard.  Suggestion: on knowledge class, take out the prudential and evidential bit, add Gettier (good for discussion).  In Justification 1, start with Evidential and Prudential reasons, then do Clifford (good for discussion I think).  Then don't be cute with the regress stuff... start by showing them the problem of the evidential regress, THEN give bit about structure of solutions.  Give other regresses than before and after.  (Is a part of?  The circular possibility is cute.  Do "behind in a line" and show that there's normally a stopping point.)  I think I can then move problems with foundationalism et al into the Justification 2 class.  Internalism and externalism need more slides to motivate, maybe?  They latched onto the Doctor Zed example and said "for all you know, he's not a fraud, so you're justified", showing that they hadn't gotten internalism yet.  Give an example of the difference between internal and external.  Being rasied in a cult is good.  Maybe even do this before the criticisms.
	// 2023: I wasn't on the ball enough to alter earlier classes but I was scared about this one (previous class too theoretical and too hard), so I added a bunch of stuff on fallibilism and contextualism instead.  Expecting a shitshow.  Now, the fallibilism stuff seems good (see Jim pryor's intro notes on whether knowledge requires certainty, so I am tempted to do a class on this before all the classes on justification.)  Another thing I am thinking is to do the courses on belief before the classes on justification?  Maybe not.  Kind of a hard choice.

	{
		category: "Epistemology",
		title: "Experts",
		readings: [[`Jamie Carlin Watson: "Expertise"`, "https://1000wordphilosophy.com/2018/10/25/expertise/"]],
		readingsO: [[`Alvin Goldman: SEP article on Social Epistemology, Section 3.2: "Learning from the Testimony of Experts"`, "https://plato.stanford.edu/entries/epistemology-social/#LeaTesExp"]],
		video: [[`John Corvino, "The Use of Experts"`, "https://www.youtube.com/watch?v=Kk7jqLs5cRw"]],
		content: ()=><>
			<p>Over the last couple of weeks, we've concerned ourselves with some philosophical debates about the nature of knowledge and justification.  It's time to apply these concepts to concrete situations you might find yourself in.  We'll spend the next three classes on a question that has become particularly pressing with the blitzkrieg of misinformation and disinformation delivered through the internet: Who Should You Trust?</p>
			<p>One way to achieve justification for your beliefs is through testimony from a reliable expert.  But how do you identify experts?  Is that doctor selling a weight loss pill a genuine authority or a snake oil salesman?  Is that risible op-ed column the product of honest research or political hackery?  When should you believe what you read?</p>
			</>,
		hashID: "experts",
		slides: "experts",
		prompt: <><p>A good way to identify whether someone is an expert is to see what other experts think about them.  If you want to know whether a physicist is legit, you can see if they are cited in papers by other physicists.  But there's an obvious problem here: how do you know that the "experts" you're consulting are themselves experts? If you keep asking experts to validate another person's expertise, you'll eventually run out of candidates, and you'll get an inner circle of people all validating one another's expertise.</p> <p>Say that someone presents themself as an expert in some weird field that sounds like bunk, like Subspace Crystal Reincarnation Theory.  They point out that they are heavily cited by a bunch of other experts in the field. When you look into it, all of these "experts" cite one another. It's a con!</p> <p>Pick a field that you're not deeply familiar with (say: quantum physics, or gender studies, or Freudian psychiatry, or evolutionary biology).  How can you be sure that this field isn't similarly bunk?  How can you establish the expertise of a whole group of experts?</p></>,
	},

	{
		category: "Epistemology",
		title: "Crackpots",
		content: ()=><>
			<p>It's a sad but common story: a good-hearted but credulous sort gets suckered by an unscrupulous conman then sucked into believing a conspiracy theory.  Some people even con themselves: unreasonably confident of their own genius, they get lost in outlandish puzzlebox delusions divorced from truth and reality.</p>
			<p>What can we learn from crackpots and cult leaders who make serious mistakes of reasoning?  Can we look at the pitfalls of crackpot science to learn what responsible science requires? In this class, we'll look at how to identify crackpots and conspiracy theorists, how to avoid falling under their sway, and how to avoid making their mistakes ourselves.</p>
			<p> There's also a bit of a puzzle here regarding how much of a crackpot you <em>should</em> be.  Scientists who made brilliant discoveries were ridiculed in their day, and the CEOs of successful startups had to break with conventional wisdom to get where they are.  (<a href="https://www.upress.umn.edu/book-division/books/outsider-theory">See this book.</a>)  But most people who delight in breaking with conventional wisdom are... crackpots.  Shouldn't you be worried about being a crackpot if you notice that you're far afield from conventional wisdom?  When should you be intellectually audacious and when should you be intellectually humble?</p>
			</>,
		hashID: "crackpots",
		readings: [[`Pascal Boyer: "How I Found Glaring Errors in Einstein's Calculations"`, 
					"http://cognitionandculture.net/blogs/pascal-boyer/how-i-found-glaring-errors-in-einsteins-calculations/"],
					[`Quassim Cassam: excerpt from "Conspiracy Theories"`,
					"https://iai.tv/articles/how-do-conspiracy-theories-come-about-auid-1235"]],
		readingsO: [[`John Baez: "The Crackpot Index"`,"http://math.ucr.edu/home/baez/crackpot.html"],
					[`Jared Millson: "Conspiracy Theories"`, "https://1000wordphilosophy.com/2020/12/17/conspiracy-theories/"],
					[`Jonathan Matheson: "Why Think For Yourself?"`,`https://www.philosophersmag.com/essays/298-why-think-for-yourself`]],
		slides: "crackpots",
		prompt: "What is your most crackpottish or outlandish belief? Why don't you think that other people agree with you?",
	},

	// https://xkcd.com/2217/

	// 2019: I had to cancel class so I combined experts and crackpots into one class.  It was probably a good idea.  The experts class went really well.  I demanded that they justify why I should be treated as an expert and not a crackpot.  It was provocative and funny and easy enough for them to respond to.

	// https://getpocket.com/explore/item/bad-thinkers

	// 2020: I cancelled class and combined this one with the next fake news class, skipping the stuff on physics crackpots and just talking about conspiracy theories and fake news.  Not a standout but not bad.  I'm a little nervous about all the politics.

	// 2021: went fine, but I should probably have more examples and incorporate more material from the presentation in Critical Thinking
	// 2023: Went well.  I like just writing a bunch of conspiracy theories up on the board.  Maybe the Boyer is unneccessary.  I added a Johnathan Matheson reading to the optionals, and I think it might be fun to drill down on whether intellectual autonomy at the expense of truth is something valuable.

	{
		category: "Epistemology",
		title: "Fake News",
		hashID: "fakenews",
		readings: [[`Regina Rini: "Fake News and Partisan Epistemology"`, "https://kiej.georgetown.edu/fake-news-partisan-epistemology/"]],
		readingsO: [[`Regina Rini: "Deepfakes are Coming.  We Can No Longer Believe What We See."`, "https://www.nytimes.com/2019/06/10/opinion/deepfake-pelosi-video.html"],
				[`C Thi Nguyen: "Escape the Echo Chamber"`, "https://aeon.co/essays/why-its-as-hard-to-escape-an-echo-chamber-as-it-is-to-flee-a-cult"]],
		content: ()=><>
				<p>Fake news!  It's been in the news.</p>
				<p>Fake news is deliberate disinformation. False propaganda. How can we identify it?  What should we do about it?</p>
			</>,
		slides: "fakenews",
		prompt: "Do you consider fake news or propaganda to be a problem? Discuss some ways in which you have protected yourself from fake news in the past.",
	},

	// class didn't go extremely well, but it wasn't a disaster.  I had high hopes.  We didn't address the part of Regina's argument about partisanship being an epistemic virtue.  To be sure, it's an interesting point but it's hard to see exactly how it relates to the rest of her argument.  I said I'd address it next class (but we didn't).
	// 2021: exact same deal.
	// 2022: Same deal, but now I want to get rid of this class.  Replace it with the C Thi Nguyen article 
	// 2023: Total disaster this time!  I can save this class by replacing it with Nguyen and better incorporating the material from the Critical Thinking course.  Really did not like this.

	{
		category: "Epistemology",
		title: "Disagreement",
		readings: [['Jonathan Matheson: "The Epistemology of Disagreement"', "https://1000wordphilosophy.com/2018/05/14/the-epistemology-of-disagreement/"]],
		video: [['Jennifer Lackey: "Can There Be Religious Disagreement Between Epistemic Peers?"', "https://www.youtube.com/watch?v=CFNdAzL3SAw"]],
		readingsO: [[`Thomas Kelley: "The Epistemic Status of Disagreement"`, "https://www.princeton.edu/~tkelly/esod.pdf"]],
		content: ()=><>
			<p>Let's say you're chatting with someone you consider a real smarty. They seem pretty cool and on the ball, and they tell you all sorts of weird facts that you didn't know. But then they say something that causes you to gasp.  They disagree with you on something important: maybe about whether God exists, or about some hot-button political issue. </p>
			<p>This is someone you have trusted until now.  You even took them to be smarter than you.  But suddenly, they've stabbed you with a conversational dagger.  What should you do with this new disagreement?  Should you ignore their opinion?  Or should you split the difference between your own view and their view?</p>
			<p>You can begin to see a problem here.  There are very smart people who believe all sorts of preposterous things.  If you ignore the testimony of people who disagree with you, or you decide that they must be the one making a mistake rather than you, or you just "agree to disagree", then you are being dogmatic.  But if you constantly let people you disagree with influence your beliefs, then you risk losing hold of your own commitments.  What a conundrum!  What is a responsible thinker to do?</p>
			</>,
		hashID: "disagreement",
		slides: "disagreement",
		prompt: 'No matter what your religious beliefs are, there are more people on Earth who disagree with them than who agree with them, and many of those people are very smart. Do you think this gives you any reason to question your religious views, or are you justified in thinking those others are going wrong?',
	},

	// 2019: awesome class.  One student told me it's a shame classes are so short.  I  mostly ignored the slides.  Made them think about past disagreements they've had (make sure to emphasize that disagreement doesn't imply fight or even vocalized disagreement, in the future).  Asking about sources of disagreement was maybe confusing for them.  Then structured the class around the dilemma: more people disagree with your religion than agree with it.  Should you split the difference?  Why is this different than splitting the difference in the case of two thermometers with different readings?

	// 2021: I tried the same thing but it was much rougher.  I couldn't see peoples' faces and I think that contributed to me feeling it didn't go well.  It wasn't awful, but I'm a little annoyed that it felt so humdrum and blase.

	// 2022: I still like this class but it needs more.  The Rini stuff on partisan bias at the end should definitely be a part of the previous class, not this one.

	{
		category: "Epistemology",
		title: "Faith",
		readings: [[`Tanya Luhrmann: "The Faith Frame: Or, Belief is Easy, Faith is Hard"`, "texts/LuhrmannFF.pdf"]],
		readingsO: [[`Tania Lombrozo: "Are Factual and Religious Beliefs the Same?"`, "https://www.npr.org/sections/13.7/2014/10/20/357519777/are-factual-and-religious-belief-the-same"]],
		content: ()=><>
			<p>Philosophers and cognitive scientists use the word "belief" in a way that sounds funny to a lot of people.  They might talk about the belief that dogs have four legs, or the belief that there's beer in the fridge.  But most people wouldn't say, naturally and unprompted, "I believe there's beer in the fridge." They'd say something like, "I <em>think</em> there's beer in the fridge."  In ordinary English, the word "belief" is normally reserved for matters of faith or hope or metaphysics.</p>
			<p> Maybe everyone is using one word to talk about two different things.  An interesting and controversial new thesis is that the word "belief" covers two very different kinds of mental states.  There are the quotidian, everyday "beliefs" that we've been talking about in most of the class, but there are also religious beliefs and  matters of faith.  Traditionally, philosophers have thought that religious beliefs and everyday beliefs are different in important ways, but they're essentially of the same kind.  But now, some renegade philosophers think they might not be the same type of thing at all.</p>
			<p>In this class, we'll read a very interesting paper by an anthropologist who studies religion cross-culturally, and who takes seriously the philosophical thesis that religious attitudes are different in kind from everyday belief.</p>
			</>,
		hashID: "faith",
		slides: "faith",
		prompt: <><p>Here's an argument that faith is not a kind of belief:</p>
			<p style={{paddingLeft: "2em", fontStyle: "italic"}}>Forming beliefs isn't something that we choose to do: everyday beliefs just happen to us. If I see you jump over a log and I have no reason to think that I was hallucinating, then I end up believing you jumped over a log: I can't help it.  It's not like I have say in the matter.  But faith isn't like that.  Faith is something that we choose to do.  Therefore, faith is not a kind of belief.</p>
			<p>Appraise this argument.  Is faith a choice?  Is belief a choice?</p></>,
	},

	{
		category: "Epistemology",
		title: "Opinion",
		readings: [["Pew Research Center Survey on Opinion", "https://www.pewresearch.org/quiz/news-statements-quiz/"],
		[`Justin P. McBrayer: "Why Our Children Don't Think There Are Moral Facts"`, `https://archive.nytimes.com/opinionator.blogs.nytimes.com/2015/03/02/why-our-children-dont-think-there-are-moral-facts/`]
			],
		readingsO: [[`Opinion survey results`, "https://www.journalism.org/2018/06/18/distinguishing-between-factual-and-opinion-statements-in-the-news/"],
				[`John Corvino: "The Fact/Opinion Distinction"`, "http://www.philosophersmag.com/essays/26-the-fact-opinion-distinction"]],
		videoO: [[`John Corvino: "The Fact/Opinion Distinction"`, "https://www.youtube.com/watch?v=V0C3_W15Tc4"]],
		content: ()=><>
			<p><a href="https://www.youtube.com/watch?v=pWdd6_ZxX8c">"That's just your <em>opinion</em>, man!"</a></p>
			<p>That's something you might hear from someone who wants to belittle or downgrade your point of view.  But what is that rude person actually saying?  What <em>are</em> opinions?  Are they just beliefs?  Are they certain kinds of beliefs?  Or are they something else entirely?</p>
			<p>We're going to do something a little different with the reading for this class.  We're going to look at a psychological study on opinions, and we'll see if we can't apply a bit of sorely-needed philosophy to it. First, try taking the survey for yourself, then skim the article providing commentary about the results.</p>
			<p>The word "opinion" is ambiguous and has multiple meanings. One of the problems with this study is that it assumes a particular understanding of what the word means. What do you think the researchers mean by "opinion"?  Can you think of some alternate definitions?  Do you think that all the survey respondents had the same meaning in mind? We'll go over a bunch of possible meanings of "opinion" in class that are crucial to healthy thinking about the relation between fact, belief, and opinion. </p> 
			</>,
		hashID: "opinion",
		slides: "opinion",
		prompt: "Here's one way that someone might characterize opinions: an opinion is a belief that someone could reasonably disagree with.  What do you think of this definition?",
	},

// 	A list of possible precisifications of 'opinion'. (Dictionary definitions are terrible. They're just as ambiguous, if not more so.)  Not all of these are good, but I can imagine all being offered. 

// I'm ignoring legal uses of the term, as well as uses where 'opinion' just straightforwardly means 'belief' (as in: "It is my professional opinion that..."). Also, there are two types of entity that 'opinion' might refer to: a statement, or a mental state that has a statement as its content. They've gotta be defined separately.

// You can get pretty different judgments about whether statements like "I like ice cream", "Ice cream is good", or "Murder is wrong" are opinions or not depending on which of the following definitions you're relying on.

// ===

// An opinion (statement) is characteristically:

// (1) normative, value-laden
// (2) an expression of idiosyncratic personal preference or personal standing
// (3) that which we can have reasonable and educated disagreement about
// (4) not truth-apt or not determinately true or false
// (5) untestable or unverifiable*
// (6) some combination of the above

// An opinion (mental state) is characteristically:

// (A) A belief that has an opinion-statement as its content (choose an account of opinion-statements from the above list)
// (B) A false belief
// (C) A belief held for bad reasons
// (D) A mental state formed for some non-epistemic reason, such as an article of faith, hope, or an expression of political affiliation**
// (E) Some combination of the above

// ===

// * I'm not sure I buy this as a good reading of the word 'opinion', but it's here to placate the verificationists in this thread.
// ** This is Daniel Dennett's account of opinions.

// I structured this class badly.  This is too didactic.

// 2022: I am going to try adding some stuff on degrees of belief and confidence.  It might be a good capper to this section.  But it honestly doesn't seem like a great solution.



				/* ---------- */
				/* EXPERIENCE */
				/* ---------- */

	{
		category: "Mind",
		title: "Dualism",
		readings: [["Stich and Donaldson: chapter 10, sections 1 and 2", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [["Stich and Donaldson: chapter 11, sections 2 and 3", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"],
					[`Jennifer McWeeny: "Princess Elisabeth and the Mind-Body Problem"`, "./texts/McWeenyPEMBP.pdf"]],
		content: ()=><>
			<p>Over the next three classes, we'll be looking at responses to one of the oldest and thorniest issues in all of philosophy: the mind-body problem. What is the relationship between the body and the mind? How does mind bind a bond to a bony body?</p>
			<p>The first theory we will look at is <em>substance dualism</em>, which states that mind and body are two entirely different kinds of things.  Our bodies live in this gritty realm of molecules and atoms, and our consciousnesses live in a ghostly realm of soul stuff. Neither can be reduced to the other in any meaningful way.</p>
			<p>Descartes, whom we previously encountered in our early epistemology classes, was an eloquent defender of what has come to be known as Cartesian dualism.  He entered into a pointed philosophical correspondence on the topic with Princess Elisabeth of Bohemia, who posed what many consider a decisive objection to substance dualism.  In class, we will restage their debate, and discuss some modern responses to the central issue.</p>
			</>,
		hashID: "dualism",
		slides: "dualism",
		prompt: "Princess Elisabeth requested an explanation from Descartes for how minds could causally interact with matter.  One potential response (not one Descartes actually gave) is that minds never do causally interact with matter: it just seems like they do.  What do you think about this response?",
	},

	{
		category: "Mind",
		title: "Mind in Matter",
		readings: [["Stich and Donaldson: chapter 10, sections 3 and 4", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p><em>Dualism</em> holds that the mental and the physical are made of two different kinds of stuff. <em>Physicalism</em> holds that there's just one kind of stuff: the stuff of physics.  Every mental thing is a physical thing.</p>
			<p>For most of the history of philosophy, scholars have found physicalism difficult to swallow.  How could consciousness be nothing more than swarms of atoms and electrical impulses?  Surely if we described everything physical about the world, we wouldn't have explained consciousness.  Philosophers have termed this <em>the explanatory gap</em>.  Physicalists who want to bridge this gap have their work cut out for them!</p>
			<p>In this class, we'll look at two solutions to the mind-body problem that physicalists have proposed. <em>Philosophical behaviorism</em> identifies mental states with dispositions to behave in certain ways. The <em>mind-brain identity theory</em> identifies mental states with brain processes.</p>
			<p>After doing the readings, please activate your posterior multimodal and heteromodal association cortex, heteromodal prefrontal cortex, and medial limbic regions in order to deploy semantic processing. (In simpler language: please think about the readings before coming to class.)</p>
			</>,
		hashID: "physicalism",
		slides: "physicalism",
		prompt: "You have been put in charge of a research team.  Your job is to figure out when fetuses first become conscious.  How would you go about your task?  What experiments would you need to perform?",
	},

	{
		category: "Mind",
		title: "Computers and Mind",
		readings: [["Stich and Donaldson: chapter 10, section 5", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		videoO: [[`Interview with Andy Clark: "Your Body is Scanned, Destroyed, then Reproduced. Do ‘You’ Live on the Copy?"`, "https://aeon.co/videos/your-body-is-scanned-destroyed-then-reproduced-do-you-live-on-the-copy"]],
		content: ()=><>
			<p>We'll close up shop on our series of responses to the mind-body problem with a theory known as <em>functionalism</em>.  Functionalism holds that any sort of state qualifies as a mental state if it has certain causes and effects. It's a complicated proposal, so please do the readings carefully.</p>
			<p>Functionalism is the theory preferred by those who want to claim that the mind is literally a computer.  If mental states are functional states, then it should not make a difference what sort of matter instantiates the mental states.  Our minds could be made from networks of organic neurons or of silicon circuitry; we could be metal or we could be meat. We could in principle upload our minds to the cloud.  All that matters is that certain causal structures be in place.</p>
			<p>As the philosopher Andy Clark puts it in the assigned optional video, we could be "patterns in information space."</p>
		</>,
		hashID: "computers",
		slides: "functionalism",
		prompt: "Suppose that someone claims to have invented a way to upload your mind to a computer.  They could then download your mind into a robot body on Mars, allowing you to vacation on another planet for a while before your mind is returned to your body.  What would you want to know before deciding to go through with it?  Would you ever decide to do it?",
	},

	{
		category: "Mind",
		title: "AI",
		content: ()=><>
			<p>The assignment for today is to watch Alex Garland's film <em>Ex Machina</em>. (It's available on Netflix, for those of you with access.)  We'll discuss a number of thought experiments in class that are brought up in the context of the movie, including the Turing Test, Mary the Color Scientist, and the Chinese Room experiment.  What features of Ava caused Caleb to see humanity and moral worth?  Do you think she was conscious and did Ava have rights?  What do you make of her actions at the film's end?</p>
			<p><em>Ex Machina</em> is a fascinating movie; we won't be able to hit all the points that are worthy of discussion.  If you're interested in reading some thought-provoking reactions to the film, I can recommend <a href="https://birthmoviesdeath.com/2015/05/11/film-crit-hulk-smash-ex-machina-and-the-art-of-character-identification">this article</a> and <a href="https://womenwriteaboutcomics.com/2015/05/ex-machina-a-white-feminist-parable-for-our-time/">this article</a>.</p>
			</>,
		video: [["Ex Machina", `https://clarkson.kanopy.com/product/ex-machina-0`]],
		readingsO: [[`"What Do You Think about Machines That Think?": 192 scholars respond`, "https://www.edge.org/responses/what-do-you-think-about-machines-that-think"],
					[`Christopher Langton: "Artificial Life"`, "http://faculty.washington.edu/lynnhank/Langton.pdf"],
					[`Gary Marcus and Ernest Davis: "How To Build Aritifical Intelligence We Can Trust"`, `https://www.nytimes.com/2019/09/06/opinion/ai-explainability.html`]],
		hashID: "ai",
		slides: "ai",
		prompt: <p>Every year, the website Edge.org asks a single question to an enormous number of scholars in all sorts of different fields. In 2015, they asked the question "what do you think about machines that think?", and 192 people responded.  You can find their responses <a href="https://www.edge.org/responses/what-do-you-think-about-machines-that-think">here</a>.  Browse through them until you find one that approaches the topic from an angle you find interesting.  What about it captured your attention?</p>,
	},
	// http://content.time.com/time/magazine/article/0,9171,135672,00.html
	// 2021: students adored the movie.  I went in underprepared, I think: the slides are too sketchy and it wasn't a good class.  This surprised me.

	{
		category: "Mind",
		title: "Self-Modification",
		readings: [[`Peter Suber: "Saving Machines From Themselves"`,
					"http://legacy.earlham.edu/~peters/writing/selfmod.htm"]],
		content: ()=><>
			<p>Technology can increase performance.  Eyeglasses help us see; energy drinks keep us alert; painkillers stave off headaches; the web acts like an external source of memory; sports therapy techniques keeps players in the game.</p>
			<p>Technology will keep pushing onward.  Combined with our drive for self-improvement and our desire to meet our goals, we will keep coming up with new methods of personal enhancement.  Will this ever causes problems? Will there ever be a point at which we risk losing ourselves?</p>
			<p>Let's imagine a near future in which surgery or drugs could cheaply and easily modify our deep mental structures.  Care about something so deeply that it's causing you pain?  Just push a button, and poof!  You don't care about it any more!  Can you not shake a certain belief that is standing in the way of advancement at a job?  Just rewire yourself to have different beliefs!</p>
			<p>Would you trust yourself with the power to deeply modify your cognition? If you had the power to give this power to an AI, would you do it? </p>
			</>,
		hashID: "selfmodification",
		slides: "selfmod",
		prompt: "If you had the power to modify your deep structure, would you trust yourself to use it? If you had the power to give this power to an artificially intelligent being (an AI), would you do it?",
	},

	// I think I should remove this, or move it elsewhere.  It hasn't gone great either time. Too much quoting of the paper.  It's more on ethics than phil mind anyway.  Maybe just move it there.

	{
		category: "Mind",
		title: "Animal Minds",
		readings: [[`Peter Godfrey-Smith: "The Mind of an Octopus"`,"https://www.scientificamerican.com/article/the-mind-of-an-octopus/?redirect=1"],
					[`Thomas Nagel: "What Is It Like To Be a Bat?", from the first full paragraph on page 438 to end of page 440`, "https://warwick.ac.uk/fac/cross_fac/iatl/study/ugmodules/humananimalstudies/lectures/32/nagel_bat.pdf"]],
		readingsO: [[`Thomas Nagel: "What Is It Like To Be a Bat?", full paper`, "https://warwick.ac.uk/fac/cross_fac/iatl/study/ugmodules/humananimalstudies/lectures/32/nagel_bat.pdf"],
		[`Michael Graziano: "The Octopus: An Alien Among Us"`, `https://lithub.com/the-octopus-an-alien-among-us/?utm_source=pocket-newtab`]],
		content: ()=><>
				<p>It doesn't take much effort to put yourself in your friend's shoes.  You can easily imagine what it would like to be him or her. Things get a little harder when trying to imagine what it would be like to be your pet.  Still, most people will happily say they can kind of understand what a dog's inner life would be like.</p>
				<p>Things get dicier when we start considering animals further from domestic familiarity.  What would it be like to be a shark, an octopus, or a bumblebee?  Would the experience be unimaginably alien or indescribable?  Would it be like anything at all?</p>
				<p>Animal consciousness raises a host of interesting and difficult questions about what minds are and the kinds of minds there can be.</p>
			</>,
		hashID: "animals",
		slides: "animals",
		prompt: "Is there anything it is like to be an octopus? A sparrow? A mosquito? A sunflower? A river?  Where do you draw the line?  What features of the creatures are making you draw the line where you do?",
	},

	// http://nautil.us/issue/76/language/language-is-the-scaffold-of-the-mind
	// 2021: excellent class.  Got complimented on the octofacts.  Import a lot of stuff form the octopus slides in the phil mind course for next time.

	{
		category: "Mind",
		title: "New Experiences",
		readings: [[`Joshua Rothman: "Seeing and Hearing for the First Time, on YouTube"`, "https://www.newyorker.com/culture/cultural-comment/seeing-and-hearing-for-the-first-time-on-youtube"], [`Dustin Grinnell: "My Mind's Eye is Blind – So What's Going on in My Brain?"`,"https://www.newscientist.com/article/2083706-my-minds-eye-is-blind-so-whats-going-on-in-my-brain/"]],
		videoO: [[`Laurie Paul: "The Transformative Experience"`, "https://www.edge.org/conversation/la_paul-la-paul-the-transformative-experience"]],
		content: ()=><>
			<p>Is it possible to know what it would be like to have a new type of experience?  One that you've never had before?</p>
			<p>Some cases don't seem so hard. If you've never ridden a rollercoaster, it does not seem impossible to imagine what it would feel like. In the 18<sup>th</sup> century, the Scottish philosopher David Hume presented a thought experiment: suppose that you've seen every shade of blue, except, for some reason, a single certain shade that you've never come across.  If you were presented with a color wheel missing that shade, would you be able to imagine the blue that would fill in the gap? Many people feel that you would.</p>
			<p>Other cases are harder.  If you've never eaten a durian, but only had its flavor described to you, can you really know what it would taste like?  Can a man ever truly understand how pregnancy feels?  Can a blind person know what it would be like to have sight?  (Videos of people having completely new sensory experiences are a popular YouTube genre.)</p>
			<p>Madeleine L'Engle's novel <em>A Wrinkle In Time</em> features alien creatures who have a type of sense perception that we don't have.  Could we ever fully understand what it would be like to be them simply through conversation?</p>
			</>,
		hashID: "newexperiences",
		slides: "experiences",
		prompt: <>
			<p>The philosopher Laurie Paul has written quite a bit about undergoing completely new experiences.  In <a href="https://www.edge.org/conversation/la_paul-la-paul-the-transformative-experience">this presentation</a>, she describes a fun fictional example about deciding to become a vampire: </p>
			<p style={{paddingLeft: "2em", fontStyle: "italic"}}>We're going to pretend that modern-day vampires don't drink the blood of humans; they're vegetarian vampires, which means they only drink the blood of humanely farmed animals. You have a one-time-only chance to become a modern-day vampire. You think, "This is a pretty amazing opportunity, do I want to gain immortality, amazing speed, strength, and power? But do I want to become undead, become an immortal monster and have to drink blood? It's a tough call." Then you go around asking people for their advice and you discover that all of your friends and family members have already become vampires. They tell you, "It is amazing. It is the best thing ever. It's absolutely fabulous. It's incredible. You get these new sensory capacities. You should definitely become a vampire." Then you say, " Can you tell me a little more about it?" And they say, "You have to become a vampire to know what it's like. You can't, as a mere human, understand what it's like to become a vampire just by hearing me talk about it. Until you're a vampire, you're just not going to know what it's going to be like."</p>
			<p style={{paddingLeft: "2em", fontStyle: "italic"}}>The question you need to ask yourself is how could you possibly make a rational decision about whether or not to become a vampire? You don't know, and you can't know what it's like. You can't know what you'd be choosing to do if you became a vampire, and you can't know what you're missing if you pass it up. </p>
			<p> The conclusion of this argument is that there is no rational basis for deciding whether to become a vampire. What do you think of this argument? Would you become a vampire?</p>
			</>,
	},

	// 2021: skipped this class and folded it into the animals class.  I remember it not going real great last time.

	{
		category: "Mind",
		title: "Free Will I",
		readings: [["Stich and Donaldson: chapter 9, sections 1 and 2", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>Suppose that you could rewind time to a particular spot in the past and then hit play once again.  Would all the same events take place exactly as they did the first time?</p>
			<p>If you think they would, then you're probably a determinist.  For a determinist, the unfolding of the future is fully determined by some initial starting conditions and the laws of nature.</p>
			<p>The problem that some people have with this idea is that it seems to leave no room for the possibility of free will.  If my future decisions are fully determined by things as they stand right now... wouldn't that mean that I have no say in the matter?  Wouldn't that mean that free will is an illusion?</p>
			</>,
		hashID: "will1",
		slides: "determinism",
		prompt: "Some people think that if determinism is true, then it makes no sense to punish criminals for their crimes, since their bad behavior was determined by the state of the universe long before they were born.  Do you agree that punishing criminals in a deterministic universe makes no sense?",
	},
	// I think I should motivate determinism more directly with the schizophrenia case and with Libet's experiments right at the beginning. (we do Libet next class)
	// 2021: Went awesome.  Students had minds blown.

	{
		category: "Mind",
		title: "Free Will II",
		readings: [["Stich and Donaldson: chapter 9, sections 3 and 4", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>The arguments discussed in the previous class seem pretty powerful.  If we have free will, then the laws of physics can't fully spell out how we will act in the future; and if the laws of physics determine the future, then we can't have free will.  Determinism or free will: it's one or the other.</p>
			<p>It's surprising, then, that many philosophers reject this binary.  In this class, we'll discuss how determinism and free will could possibly be compatible with one another.</p>
			</>,
		hashID: "will2",
		slides: "freewill",
		prompt: "Some people think that in order to have the sort of free will that is required for morality, it must be the case that human decision-making is not governed by the laws of physics.  Do you agree that morality requires human decision-making to not be dictated by the laws of physics?",
	},

	// 2021: thought it went badly.  Worse than last class anyway.

	{
		category: "Mind",
		title: "Addiction",
		audio: [[`Hanna Pickard: "Understanding Drug Addiction", a BBC Radio 4 Four Thought Interview`, "https://www.bbc.co.uk/programmes/b08xbjjb"]],
		content: ()=><>
			<p>Abstract arguments for the existence of free will become grounded when we ask about particular cases.  Mental illnesses give us some particularly vivid examples of instances in which it's not clear whether a person acted freely.  Does a person in the grip of psychosis act freely when he or she acts on a delusion?  Does the depressed individual decide to stay in bed?  Is it ever appropriate to hold people responsible for their actions when they are beset with severe mental illnesses?</p>
			<p>In this class, we'll consider the case of addiction.  If someone has a drug addiction, is their free will impaired?</p>
			</>,
		hashID: "addiction",
		slides: "addiction",
		prompt: <>
			<p>What is the relationship between addiction and free will? Does someone with a gambling addiction freely decide to gamble?  Or is their decision to gamble forced upon them?</p>
			<p>How about drug addiction? Is a drug addict's decision to do drugs importantly different than the gambling addict's decision to gamble?</p>
			</>,
	},

	// 2021: Terrible class.  The list of mental disorders is not very interesting.  The Rat Park stuff hits though.  Thought: replace this with a class on metal disorder and moral responsibility in general.  Also, maybe read Fingarette so that we can later play that super sad movie about him dying in the class on death and it'll hit harder.
	// 2022: Not as bad as it could have been, but I am sensing that energy is flagging in the class.

	{
		category: "Mind",
		title: "Identity I",
		readings: [[`Stich and Donaldson: Chepter 11, section 4`, "https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>What makes you <em>you</em>?</p>
			<p>There's a sense in which you're not the same person as the person you were eight years ago.  A lot has happened since then.  People change as they grow.  Your personality has been molded by new experiences and new life situations.  Your body has replaced all the cells you once had with a completely new set of cells.  Yet even through all these changes, something has remained constant.  You're you.  But what does that mean?</p>
			<p>In this class, we'll look at some potential answers to this question of personal identity, paying special to the <em>memory theory</em> of the philosopher John Locke, who argued that the continuity of the self is largely determined by the continuity of memory.</p>
			</>,
		hashID: "identity1",
		slides: "identity1",
		prompt: "Many major world religions hold that people are reincarnated after they die, and some are reincarnated as animals other than humans. What do you think about the possibility of reincarnation?  If you were reincarnated as a caterpillar, but had no memories of your previous life as a human, and did not even have the cognitive sophistication to think about once having been a human, what does it mean to say that the caterpillar is you?",
	},

	// 2021: kinda fun to jaw about Star Trek, the Ship of Theseus, transporters, etc.  A conversational class.

	{
		category: "Mind",
		title: "Identity II",
		readings: [[`Nick Humphreys and Daniel Dennett: "Speaking For Our Selves"`, "texts/DennettSFOS.pdf"]],
		content: ()=><>
			<p>In the last class, we investigated personal identity by thinking about how a single self is able to persist over time.  In this class, we'll investigate the possibility of multiple selves occupying a single body at a single time.</p>
			<p>We'll look at two different real-world mental disorders in order to interrogate this possibility.  Dissociative Identity Disorder (once called Multiple Personality Disorder) is a psychiatric condition in which an individual alleges that he or she has multiple personalities, or even multiple selves that inhabit a single body.  Is it really possible for many people to be housed in a single body?  Or should we think of these cases in a different way?</p>
			<p>We'll also look at split-brain patients, who have undergone a procedure in which two halves of the brain have been severed.</p>
			</>,
		hashID: "identity2",
		slides: "identity2",
		prompt: "Can multiple people exist in a single body?",
	},

	// readingsO: [[`Alison Gopnik: "How an 18th-Century Philosopher Helped Solve My Midlife Crisis"`, "https://www.theatlantic.com/magazine/archive/2015/10/how-david-hume-helped-me-solve-my-midlife-crisis/403195/"]],

	// 2021: this class was not good and I don't think it went well in 2019.  The Dennett and Humphreys paper is on the narrative theory of self, and it should be taught in a simpler way than cobbling it together from long quotations. The video on the split brain is great for discussion though.  Maybe next time mention that most animals have two fields of vision, as describe in GOdfrey-Smith's octopus book.

	// 2022: Must redo this class.




				/* ------ */
				/* ETHICS */
				/* ------ */


	{
		category: "Ethics",
		title: "Utilitarianism",
		readings: [["Stich and Donaldson, chapter 14, sections 1-3", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>Suppose that you come up with an idea for an app that could make you a lot of money but would compromise a lot of people's personal data.  Should you make it?  This question is not about whether the app would work, or whether it would make you rich, or whether it would be legal.  It's about whether building the app would be <em>ethical</em>.</p>
			<p>There's a difference between the way things are and the way they ought to be, and there's a difference between predicting what you will do and deciding what you should do.  Science and engineering courses tell you how things work and about ways to change the world. They don't typically tell you about how you <em>should</em> change the world.</p>
			<p>This final section of the course will introduce you to the study of ethics and morality.  We'll start by considering some theories about what it means to be a moral agent and to make good decisions.  First, we'll discuss <em>utilitarianism</em>, which holds, in essence, that you should do the greatest good for the greatest number of people.  In class, we'll discuss some of the complications that arise when you try to formalize this dictum into a rigorous philosophy.</p>
			</>,
		hashID: "ethics1",
		slides: "ethics",
		prompt: "Do you think that we should always do whatever brings about the greatest amount of happiness?  Try to describe a case in which a person could do something that would bring about the greatest happiness for the greatest number of people, but intuitively, it would be the wrong thing to do.  Argue why you think this case either is or is not a problem for utilitarianism.",
	},

	{
		category: "Ethics",
		title: "Kantianism",
		readings: [["Stich and Donaldson, chapter 14, sections 4-6", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		content: ()=><>
			<p>The primary competitor to utilitarianism is often taken to be Kantianism, named after the philosopher Immanuel Kant.  If utilitarian is associated with the slogan "the greatest good for the greatest number", Kantianism can be associated with the slogan "never compromise".  According to Kantians, morality consists of acting from universal laws that are founded in rationality and that do not admit excuses or exceptions.</p>
			<p>TV shows and other pieces of fiction like to pit utilitarians and Kantians against one another.  Conflict is born when one character wants to sacrifice a person for the greater good and another character refuses to break what they see as an inflexible moral rule.</p>
			<p>This sort of conflict at the center of ethics can make it feel like the discipline is on shaky ground.  It's important to emphasize that although the two theories disagree about what you should do in certain cases (and fiction likes to exploit these cases), they still agree much of the time on what is right to do.  The theories can't both be correct &mdash; they make competing claims &mdash; but they're working toward the same goal of understanding the nature of morality and advising us on the sorts of ways we ought to live.  As the philosopher Derek Parfit puts it, these different ethical theories are "climbing the same mountain on different sides."</p>
			</>,
		hashID: "ethics2",
		slides: "ethics2",
		prompt: <>
			<p>Describe a time when you wanted to do something, but then you thought or were told by someone else, "But what if everyone did that?!"</p>
			<p>Do you think that this sort of question ever gives you a good reason to reconsider your actions?</p>
		</>,
	},

	{
		category: "Ethics",
		title: "Relativism",
		readings: [["Stich and Donaldson, chapter 12", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [[`Paul Boghossian, "The Maze of Moral Relativism"`, "https://opinionator.blogs.nytimes.com/2011/07/24/the-maze-of-moral-relativism/"]],
		audioO: [[`Interview with Paul Boghossian on moral relativism, Philosophy Bites Podcast`, "https://philosophybites.com/2011/10/paul-boghossian-on-moral-relativism.html"]],
		content: ()=><>
			<p>One of the worries that students often have about ethics is that ethics seems less "real" or "objective" than the descriptive sciences.  In the face of so much disagreement over morality, especially across different cultures, it can be tempting to say that ethical statements are subjective or relative. Sentences like "euthansia is wrong" can sometimes seem to be in the same camp as sentences like "cilantro is delicious."</p>
			<p>But!  Even people who say that morality is relative tend to live as if they believe in universal moral rules.  A philosopher might say that she doesn't see how morality could be objective... but she will still refrain from kicking dogs for fun, and she will denounce the actions of other people who kick dogs for fun even if those other people were raised in a culture that doesn't frown on dog-kicking.  Her attitude toward dog-kickers will be nothing like her attitude toward people who dislike cilantro.  When you look at how people act, true moral relativists or moral skeptics are rare.</p>
			<p>What are we to make of all this?  Are there ethical facts?  Can ethics really be objective?  These metaethical questions are some of the toughest questions in all philosophy.  We will turn a piercing gaze upon them.</p>
			</>,
		hashID: "relativism",
		slides: "relativism",
		prompt: "Do you or do you not think that it is fair or acceptable to criticize the moral practices of cultures different than your own?  What challenges do you face in maintaining this perspective?",
	},

	// 2023: I want to revamp the ethics section to be more like the Ethics course I'm teaching, where I introduce reflective equilibrium, then do relativism, then do the theories.  This relativism class went awful this time around.  I don't think it's always awful.  Had a walkout and worried about that.

	{
		category: "Ethics",
		title: "Charity",
		readings: [["Stich and Donaldson, chapter 15, sections 1 and 2", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [["Giving What We Can website", "https://www.givingwhatwecan.org/"]],
		content: ()=><>
			<p>Everyone taking this course lives in relative comfort to many others in the world.  What do we owe to those who are worse off?  Is it ethically acceptable to spend money on small luxuries when that same amount of money could prevent a person in the Third World from contracting malaria, or cure a baby of blindness? Would you be willing to ruin the shoes you are wearing right now if you could jump in a pool and save a child from drowning?</p>
			<p>Are you sure that you're not currently in a morally equivalent scenario?</p>
			<p>In this class, we confront the question of what we owe to others.</p>
			</>,
		hashID: "charity",
		slides: "charity",
		prompt: "Try to give an argument that nearly everyone is morally obligated to give some of their money to charity. Then try to give an argument that no one is morally obligated to give money to charity.  Where do you think the truth lies?",
	},

	{
		category: "Ethics",
		title: "Cars",
		readings: [[`Patrick Lin, "The Ethics of Autonomous Cars"`,"https://www.theatlantic.com/technology/archive/2013/10/the-ethics-of-autonomous-cars/280360/"],
					["The Moral Machine", "http://moralmachine.mit.edu/"]],
		videoO: [[`Youtube streamers do a Let's Play of The Moral Machine`,"https://www.youtube.com/watch?v=9VVqYmf5s18"],
				[`Another streamer`, "https://www.youtube.com/watch?v=bThajoCIJng"],
				[`Another streamer (you can search for more... there are lots!)`, "https://www.youtube.com/watch?v=xCeOpOkiS-4"]],
		content: ()=><>
			<p>There's a very famous thought experiment developed by the philosopher Philippa Foot known as <em>The Trolley Problem.</em>  A runaway trolley is racing down a track toward five people who are unaware of the danger.  You won't be able to warn them in time.  What you <em>can</em> do is pull a lever to divert the train onto a track occupied by just a single person.  Should you do nothing and let five people be crushed? Or should you pull the lever and cause the single person to be crushed?</p>
			<p>Trolleyology has captured the public imagination.  It's the focus of <a href="https://www.facebook.com/TrolleyProblemMemes/">a bunch of popular memes</a>. It has also been the focus of a debate about how engineers ought to develop autonomous vehicles. Self-driving cars will have to manage all sorts of risks: risk to the driver, risk to pedestrians, risk to other cars, and so on.  There will be trade-offs.  How much risk to the driver is permissible at the expense of risk to pedestrians?  Will we have to explicitly implement moral principles into the car software in order to help them weigh the acceptability of these trade-offs?  Can thinking about the trolley problem help us?</p>
			<p>One of the assigned "readings" this class is a quiz from the MIT Media Lab called "The Moral Machine".  It'll put you in the shoes (tires?) of a self-driving car and ask you to make Trolley Problem-like decisions. Give it a go, and afterward, be sure to check out the videos of people playing the game in the Optional Videos section.  It is fun and interesting to watch other people puzzle through these moral scenarios.</p>
			</>,
		hashID: "cars",
		slides: "cars",
		prompt: "Take the Moral Machine quiz.  Which scenario did you find most difficult to answer?  Do you think that your survey results can help the makers of self-driving cars?",
	},

	// The researchers who run the Moral Machine now have a bunch of data about how various regular people think a car should act in various weird scenarios.  What now?  Should they just average out the regular-person responses and try to make cars act like that, or would that be a bad idea?  Are the scenarios too cartoonish and gamified to be useful, or is the data still helpful even if it is unrealistic?  How, if it all, do you think the survey results can help us make decisions about how to program self-driving cars?

	// Goals: introduce to the trolley problem
		// get them to see the difference between kantian and utiliatarian intuitions
		// further introduce them to the use of thought experiments in making one's ethical beliefs consistent
		// tell them about a few variants (eg the fat man)
	// Application: the puzzle of self-driving cars
		// have them work through some ethical scenarios describing self-driving cars
		// get them to question the relevance of these thought experiments for self-driving cars (big phil puzzle)
		// emphasize that there are various ethical actors involved, each who might need to make different decisions 
	// Also: other ethical issues about cars!
		// get them to list some


	{
		category: "Ethics",
		title: "Eating Meat",
		readings: [["Stich and Donaldson, chapter 15, section 3", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		readingsO: [[`Sam Bloch: "The Hype and the Hope Surrounding Lab-Grown Meat"`, "https://newfoodeconomy.org/new-harvest-cell-cultured-meat-lab-meat/"],
					[`J. Kenji López-Alt, "The Ethics of Foie Gras: New Fire for an Old Debate"`, "https://www.seriouseats.com/2015/01/foie-gras-new-fire-for-an-old-debate.html"],
					[`Gabriel Rosenberg and Jan Dutkiewicz: "The Viral Story of a Girl and her Goat"`, "https://www.vox.com/future-perfect/23669586/goat-girl-4-h-shasta-county-seizure"]],
		content: ()=><>
			<p>Most of us on the planet regularly eat flesh.  Most of us have the option not to. We eat meat because it tastes good.  This means that animals are killed solely for pleasure.  When put in such stark terms, is the practice any better than dog-fighting or bear-baiting?</p>
			<p>When you ask people about their meat-eating, you get a huge range of responses.  Some people are adamant there is nothing wrong with it.  Others say that it's wrong to eat certain animals, but other animals are (literally) on the table.  Some people draw the line at eating factory-farmed meat, or eating foie gras, or eating baby animals.  Some people wouldn't want to eat the meat of an animal that they had to personally slaughter beforehand, while others say that it's because they have personally slaughtered animals that they feel comfortable eating meat.</p>
			<p>Eating meat is increasingly becoming a guilty pleasure for many people.  They choose not to think too hard about where meat comes from. Loren Lomasky calls this "the veal of ignorance."</p>
			<p>This is a philosophy class.  We don't have a choice about not thinking hard.  Thinking hard is mandatory.</p>
			</>,
		hashID: "meat",
		slides: "meat",
		prompt: "What is your best ethical justification for eating meat?",
	},

// https://www.newyorker.com/magazine/2019/11/25/can-babies-learn-to-love-vegetables

	{
		category: "Ethics",
		title: "Voting",
		content: ()=><>
			<p>Here's an argument that a lot of people find infuriating.  I think it tacitly underlies a lot of voter apathy.</p>
			<p style={{paddingLeft: "1em"}}> <em>Voting in most political elections is useless. The purpose of voting is to influence the outcome of an election.  But I can be practically certain that my vote will not influence anything at all!  Very few elections come down to a single vote, so I am fully justified in believing that my vote will have no impact upon who wins.  Yes, of course: if everyone thought like this, then that would be bad.  But I am not everyone!  I don't control anyone else's vote.  My decision can impact only my own vote.  And because I know that my own vote won't make a difference, there's really no point in bothering to head to the polls.</em></p>
			<p>How would you respond to this argument?  This paradox of voting (also known as <em>Downs paradox</em>) is an example of a <em>collective action problem</em>.  In class, we'll consider a suite of other collective action problems, including a famous one known as the Prisoner's Dilemma. Once you understand the structure of collective action problems, you'll see them everywhere in your daily life.</p>
			</>,
		readings: [[`None`, ""]],
		readingsO: [[`Jason Brennan: "Voting as an Ethical Issue"`, "http://assets.press.princeton.edu/chapters/i9464.pdf"],
			[`Jason Brennan: SEP article on "The Ethics and Rationality of Voting"`, "https://plato.stanford.edu/entries/voting/"],
			[`Jason Wyckoff: The Prisoner’s Dilemma`, "https://1000wordphilosophy.com/2014/04/24/the-prisoners-dilemma/"]
			],
		hashID: "voting",
		slides: "voting",
		prompt: "Do you think that there is any point in voting in a Federal election if you are confident that your vote won't change the outcome of the election?",
	},

	// http://assets.press.princeton.edu/chapters/i9464.pdf
	// DO NOT DO THE PRISONER'S DILEMMA PORTION IN THE FUTURE
	// Total disaster.  Just having discussions about the voting argument is enough.
	// Find South Park clip for Brennan's argument.
	// Talk about "right to complain"
	// Use Stich and Donaldson's pro-voting argument?
	// Use global poverty example to differentiate from first-past-the-post reasoning.
	// Kantian argument for Brennan's position.  Voting is like being a mailman.

	// 2021: I DIDN'T DO ANY OF THE ABOVE AND MADE A HASH OF IT AGAIN!
	// The McDonalds and the charity cases are totally different and should not be included.  They ruin the flow of the whole class.  The Prisoner's dilemma is too hard too.
	// Just talk about the following questions:
	// Do you have a moral duty to vote?  Do you have the right to complain if you don't?
	// Should you vote if you know it won't make a difference?
	// Is there a moral duty to vote in a specific way?  Is spoiling your ballot okay and staying home not?
	// Is tactical voting permissible?
	// Should you vote if you are underinformed?
	// Then maybe at the end do collective action problems without talking about the prisoner's dilemma.

	{
		category: "Ethics",
		title: "Friendship",
		readings: [[`Aristotle, Nicomachean Ethics, Book VIII, chapters 1-7`, "http://classics.mit.edu/Aristotle/nicomachaen.8.viii.html"]],
		//readingsO: [[`Lauren Larson: "The Men Who Have Mostly Female Friends"`,"https://www.gq.com/story/men-with-mostly-female-friends"]],
		content: ()=><>
			<p>What is a friend?  What kinds of friend are there? Why do we value friendship?</p>
			<p>This is a topic that undergraduate students usually have a lot to say about.  But it's also a very old topic.  Way back in ancient Greece, Aristotle distinguished three types of friendship.  In a friendship of utility, the friendship is based upon the two parties getting something from one another.  In a friendship of pleasure, the relationship is based upon taking pleasure and enjoyment in one another's company. And then there are perfect friendships: the ones that are held because the friends mutually admire each other, and each person is challenged to be as virtuous as the other.  Most people will never have such a friendship, Aristotle thought.  Even two thousand years later, there is something lovely and something right about this analysis.</p>
			<p>But is it still applicable to modern-day friendships?  Or is it hopelessly dated, and a depiction only of friendship between men in ancient Greece?  Aristotle thought only men were capable of perfect friendships.  That's unacceptable, obviously. We would at the very least have to update that bit.  But maybe that's a hint that the whole thing is dated.  Are modern-day friendships different?  Are there new types of friendship? Do contemporary differences in gender roles complicate things?</p>
			<p>Think about all the kinds of friendships that you have or that you wish to have; we'll try to figure out what it is about various kinds of friendship that make them valuable.</p>
			</>,
		hashID: "friendship",
		slides: "friendship",
		prompt: "Are Aristotle's three types of friendship still applicable to modern-day friendships, or is the system hopelessly outdated?",
	},

	{
		category: "Ethics",
		title: "Privacy",
		readings: [[`Uh-Oh: Silicon Valley is Building a Chinese-Style Social Credit System`, "https://www.fastcompany.com/90394048/uh-oh-silicon-valley-is-building-a-chinese-style-social-credit-system"],
			[`David Velleman: "The Genesis of Shame", sections 1-6`, "texts/VellemanGS.pdf"]],
		content: ()=><>
			<p>The right to privacy is increasingly violated in a world in which information is more and more easily accessible.  Does this matter?  Why do we have a right to privacy at all?</p>
			</>,
		hashID: "privacy",
		slides: "privacy",
		prompt: "Sometimes it is said that the loss of privacy is only a problem for people with something to hide, and that if you're a good person, then you have nothing to hide.  Velleman gives some reasons to reject this claim.  What do you think?",
	},

	{
		category: "Ethics",
		title: "Genetic Engineering",
		readings: [[`Michael Sandel: "The Case Against Perfection"`, "https://www.theatlantic.com/magazine/archive/2004/04/the-case-against-perfection/302927/"]],
		videoO: [["What If We Could Rewrite the Human Genome?", "https://www.youtube.com/watch?v=BSL3k9ezGoA"]],
		content: ()=><>
			<p>The technology to genetically screen fetuses or to alter the genome of a fetus is rapidly advancing.  Parents will soon be able to select desireable traits or to wipe out undesireable traits in their babies.  With this technology comes ethical challenges.  What ethical problems do you foresee?  Is it always, sometimes, or never appropriate to engineer fetuses that have particular traits?</p>
			<p>In this class, you will be introduced to some of the bioethical questions that surround genetic enhancement, CRISPR, cloning, and eugenics.</p>
			</>,
		hashID: "genetic",
		slides: "genetic",
		prompt: "The technology to genetically screen fetuses or to alter the genome of a fetus is rapidly advancing.  Parents will soon be able to select desirable traits or to wipe out undesirable traits in their babies.  With this technology comes ethical challenges.  What ethical challenges do you foresee?  Is it always, sometimes, or never appropriate to engineer fetuses that have particular traits?",
	},

		{
		category: "Ethics",
		title: "War",
		hashID: "war",
		content: ()=><>
			<p>In this class, we will consider a host of questions about the morality of war.  Are there just wars and unjust wars?  What makes the difference between the two?  Do soldiers who kill others in the context of an unjust war bear moral responsibility, or is it correct to say that they were just following orders and that the moral responsibility falls upon the commanding officers and the politicians who decide to go to war?  Is there a moral difference between intentionally targeting civilians and performing manuevers in which one expects civilians to die?  Is the use of drones morally complicated?</p>
			<p>I've assigned two episodes of the excellent podcast Hi-Phi Nation for this class; one is optional.  If you are especially interested in this topic and think you'll listen to both, start with the optional episode, featuring an interview with a soldier-philosopher.  It comes sequentially before the other.</p>
			</>,
		audio: [[`Hi-Phi Nation, Season 1 Episode 3: The Morality of War`, "https://hiphination.org/complete-season-one-episodes/episode-3-the-morality-of-war-feb-7th-2017/"]],
		audioO: [[`Hi-Phi Nation, Season 1 Episode 2: Moral Exploitation`, "https://hiphination.org/complete-season-one-episodes/episode-two-moral-exploitation-jan-31-2017/"]],
		slides: "war",
		prompt: "Some wars are unjust wars, conducted for bad reasons. Do soldiers who fight in unjust wars bear responsibility for wrongful deaths, or is the moral responsibility entirely held by the commanding officers and politicians who decide to go to war?  In what sort of circumstances does a soldier have a moral duty to disobey his or her commanding officer? (Each of the two assigned podcast episodes will help you think about this issue from different perspectives.)",
	},

	// I think I should not teach this class on war any more.  It went terribly, and I am much too trepidatious to teach this material.

	//https://www.youtube.com/watch?v=jAhjPd4uNFY

	// {
	// 	category: "Ethics",
	// 	title: "Immigration",
	// 	content: ()=><p>Text goes here</p>,
	// 	hashID: "immigration",
	// 	readings: [[`Michael Huemer: "Is There a Right to Immigrate?`, "https://rintintin.colorado.edu/~vancecd/phil215/Huemer3.pdf"]]
	// },

	// Reparations?

	{
		category: "Ethics",
		title: "Happiness",
		audio: [[`The Happiness Lab with Laurie Santos, episode 2: "The Unhappy Millionaire"`, 
					`https://www.happinesslab.fm/season-1-episodes/the-unhappy-millionaire`]],
		content: ()=><>
			<p>Hedonic utilitarians seek pleasure and happiness.  But what do these terms mean?  Are there multiple sorts of happiness?  Would you sacrifice happiness to achieve other goals?</p>
			</>,
		hashID: "happiness",
		slides: "thatwhichmatters",
		prompt: <>
			<p>Is being happy an ultimate end for you?  Would you sacrifice happiness in order to achieve other goals?</p>
			</>,
	},

	// I punted on this class and folded it into the next class on hedonism, which made some sense.  But for next time, when talking about types of happiness, see:
	// https://www.drtimlomas.com/lexicography
	// https://www.standard.co.uk/culture/books/happiness-dictionary-happy-definition-words-world-contentment-love-a4094886.html
	// https://www.bbc.com/future/article/20170126-the-untranslatable-emotions-you-never-knew-you-had

	{
		category: "Ethics",
		title: "That Which Matters",
		readings: [["Stich and Donaldson, chapter 13", 
					"https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson"]],
		//readingsO: [[`Anne Helen Peterson: "How Millennials Became the Burnout Generation"`, "https://www.buzzfeednews.com/article/annehelenpetersen/millennials-burnout-generation-debt-work"]],
		content: ()=><>
			<p>In this class, we'll discuss the meaning of life.  No biggie.</p>
			</>,
		hashID: "matters",
		slides: "thatwhichmatters2",
		prompt: <>
			<p>Why go to college?  Is it to make money?  Why make money?  Is it to buy things that give you pleasure?  Why aim for personal pleasure?</p>
			<p>Many of our goals are waypoints that we strive for in order to achieve some further goal.  This chain probably has to stop somewhere.  What's your ultimate goal?  What matters to you more than anything else?</p>
			</>,
	},

	// https://www.vox.com/future-perfect/2019/11/7/20903337/human-extinction-pessimism-hopefulness-future

	// 2021: I mucked around with these last four classes... I did only three and put one on hedonism, one on existentialism, and one on death.  The Hedonism class went GREAT.  I love it when students say "that's so deep" in the chat.  I think this was probably a showcase.

	{
		category: "Ethics",
		title: "Death",
		video: [[`A 97-Year-Old Philosopher Ponders Life and Death: 'What Is the Point?'`,`https://www.youtube.com/watch?v=qX6NztnPU-4`]],
		audioO: [[`Hi-Phi Nation, Season 1 Episode 1: The Wishes of the Dead`, "https://hiphination.org/complete-season-one-episodes/episode-one-the-wishes-of-the-dead/"]],
		content: ()=><>
			<p>A feature of everyone alive is that they will one day cease being alive.  Some people don't dwell too much on this fact; others are terrified by the fact that they will eventually slide into non-existence.  What is death?  Should we fear it?  Does death harm us if we no longer exist once we die?</p>
			</>,
		hashID: "death",
		slides: "death",
		prompt: "Usually, breaking our moral obligations to another person harms them in some way.  But sometimes we makes promises to people about what we will do after they die.  For instance, we might promise to burn someone's embarrassing correspondence, bury them instead of cremating them, or keep a secret that would tarnish their reputation.  However: once a person dies, they no longer exist.  Can a non-existent person be harmed?  Do we have any moral obligations to the dead?",
	},

	{
		category: "Ethics",
		title: "The Future",
		content: ()=><>
			<p>Our final class will continue our morbid musings from the previous class.  We're going to die someday. Why should we care about what happens in the future once we die?</p> 
			</>,
		hashID: "future",
		slides: "future",
		readings: [[`Samuel Scheffler: "The Importance of the Afterlife. Seriously."`, "https://opinionator.blogs.nytimes.com/2013/09/21/the-importance-of-the-afterlife-seriously/"]],
		videoO: [["Children of Men", "https://www.imdb.com/title/tt0206634/"]],
		prompt: "Suppose that the Children of Men scenario came true.  No more babies could ever be born again, and so one generation from now, humanity would cease to exist.  Do you think others would live life differently? Would you live life differently?",
	},





];


const classDates = [
	'01-13',
	'01-16',
	'01-18',
	'01-20',
	'01-23',
	'01-25',
	'01-27',
	'01-30',
	'02-01',
	'02-03',
	'02-06',
	'02-08',
	'02-10',
	'02-13',
	'02-15',
	'02-17',
	'02-20',
	'02-22',
	'02-27',
	'03-01',
	'03-03',
	'03-06',
	'03-08',
	'03-10',
	'03-20',
	'03-22',
	'03-24',
	'03-27',
	'03-29',
	'03-31',
	'04-03',
	'04-05',
	'04-07',
	'04-10',
	'04-12',
	'04-14',
	'04-17',
	'04-19',
	'04-21',
	'04-24',
	'04-26',
	'04-28'
].map(x=>moment(x+'-2023 11:50', "MM-DD-YYYY HH:mm"));
//].map(x=>moment(x+'-2019', "MM-DD-YYYY"));

const examPeriod = [
	'05-03',
	'05-04',
	'05-05',
	'05-08',
	'05-09'
].map(x=>moment(x+'-2023', "MM-DD-YYYY"));

const breakDays = [
	'02-23',
	'02-24',
	'03-13',
	'03-14',
	'03-15',
	'03-16',
	'03-17'
].map(x=>moment(x+'-2023', "MM-DD-YYYY"));

const quizDates = [
	'02-08',
	'03-24',
	'04-21'
].map(x=>moment(x+'-2023', "MM-DD-YYYY"));

const paperDeadlines = [
	'03-08',
	'05-03'
].map(x=>moment(x+'-2023', "MM-DD-YYYY"));

const allClassesWithDates = allClasses.map(
	(currclass, index)=>({...currclass, date: classDates[index] || moment('01-01-2525','MM-DD-YYYY')})
);

export { classDates, paperDeadlines, examPeriod, breakDays, quizDates};
export default allClassesWithDates;


	// {
	// 	category: "Mind",
	// 	title: "Where Am I?",
	// 	content: ()=><>
	// 		<p>Today's reading is a fun one. It's better without any spoilers, so I'll stay mum on what it's about.</p>
	// 		<p>Just know that it's the text of a live presentation given by the philosopher Daniel Dennett.  Do enjoy!</p>
	// 		</>,
	// 	readings: [[`Daniel Dennett: "Where Am I?"`, "texts/DennettWAI.pdf"]],
	// 	hashID: "whereami",
	// 	prompt: "To be added",
	// },