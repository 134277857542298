import React from 'react';
import { List, Collapse, Avatar } from 'antd';

const ResourcesContent = (props) => {

	const pages = [
		{
			title: "The Stanford Encyclopedia of Philosophy", 
			addr: "https://plato.stanford.edu/", 
			desc: "The Stanford Enyclopedia covers a huge range of philosophical issues.  It's written by experts, it's constantly updated... it's a colossally useful resource. The level of discourse is often pretty advanced, so you may find some parts difficult to understand. Still, it's a great place to start when first investigating a new philosophical topic."
		},
		{
			title: "The Internet Encyclopedia of Philosophy", 
			addr: "https://www.iep.utm.edu/", 
			desc: "A friendly competitor to the Stanford Enyclopedia. The quality of the entries is also high. (Please use these two resources before turning to Wikipedia, which is not very reliable on philosophical issues.)"
		},
		{
			title: "1000 Word Philosophy",
			addr: "https://1000wordphilosophy.com/",
			desc: "A site that presents introductory articles, each under 1000 words, on a large variety of philosophy topics.  A great place to poke around."
		},
		{
			title: "PhilPapers", 
			addr: "https://philpapers.org/", 
			desc: "Philpapers is a database of nearly every contemporary philosophy paper. You can look up everything recently published on a particular topic, everything recently written by a particular author, or everything recently published by a particular journal. The site first came online a little over a decade ago, and it has revamped the whole field."
		},
		{
			title: "Aeon Philosophy",
			addr: "https://aeon.co/philosophy",
			desc: "Aeon magazine regularly publishes good articles about philosophy for the general public.  They're often written by professional philosophers."
		},
		{
			title: "3:16 Interviews with Philosophers",
			addr: "https://316am.site123.me/blog/index-of-all-interviewees",
			desc: "Richard Marshall has been interviewing professional philosophers about their work for over a decade now. If a contemporary philosopher we read interests you, look them up here: the interviews are very informative."
		},
		{
			title: "What Is It Like To Be a Philosopher?",
			addr: "http://www.whatisitliketobeaphilosopher.com/",
			desc: "Professional philosophers are interviewed about their journeys.  These interviews are longer, more personal, and more biographical than the interviews at the link above."
		},
	];

	const writing = [
		{
			title: "The Pink Guide to Philosophy",
			addr: "https://sites.google.com/a/wellesley.edu/pinkguidetophilosophy/",
			desc: "A friendly introduction to reading and writing philosophy for students who have never previously taken a philosophy course."
		},
		{
			title: "How to Write Philosophy Papers",
			addr: "https://www.kevindorst.com/uploads/8/8/1/7/88177244/how_to_write_philosophy_papers__revised_8.31.16_.pdf",
			desc: "A handout by philosopher Kevin Dorst that summarizes interviews with professors on how they wanted their undergraduates to write philosophy papers."
		},
		{
			title: "How to Write a Philosophy Essay",
			addr: "https://drive.google.com/file/d/1QulLNalOIENHut_rYG4r946iFJx-7Lf5/view",
			desc: "A straightforward and to-the-point handout by philosopher Rachel Fraser."
		},
		{
			title: "What Not To Do",
			addr: "https://drive.google.com/file/d/1W_QCS8bJlMm5mGB9iLfm0ohe4rX2BRWz/view",
			desc: `A second handout by Dr. Rachel Fraser describing traps that poor philosophy papers often fall into. The handout begins provocatively: "Lots of students don’t know what sounds crap and what sounds good. Here is my attempt to demonstrate by example what you should be aiming for in a philosophy essay."`
		},
		{
			title: "Guidelines on Writing a Philosophy Paper", 
			addr: "http://www.jimpryor.net/teaching/guidelines/writing.html", 
			desc: "Some helpful advice from philosopher Jim Pryor.  This page is a classic, oft-recommended resource.  It is more in-depth than most of the other resources you will find."
		},
		{
			title: "How to Write a Philosophy Paper", 
			addr: "https://writingcenter.unc.edu/tips-and-tools/philosophy/", 
			desc: "A handout from the Writing Center at the University of North Carolina."
		},
		{
			title: "Clarkson University Writing Center",
			addr: "https://sites.clarkson.edu/cuwrite/sample-page/writing-resources/",
			desc: "Avail yourself of everything that Clarkson has to offer! Schedule an appointment with the Writing Center and they'll help you improve your papers.  Their site includes all sorts of writing resources you might find valuable, so be sure to explore."
		},
	];

	const podcasts = [
		{
			title: "Hi-Phi Nation", 
			addr: "https://hiphination.org/", 
			desc: "Hi-Phi Nation is the newest podcast on this list. It has rocketed to popular acclaim thanks to its high production values and its storytelling format.  Highly recommended."
		},
		{
			title: "Philosophy Talk", 
			addr: "https://www.philosophytalk.org/", 
			desc: "Philosophy Talk is probably the biggest and most popular podcast on the list: it has been running as a radio program since 2004. Two Stanford professors bring in guests to discuss the Big Questions. Older episodes are behind a paywall."
		},
		{
			title: "Very Bad Wizards", 
			addr: "https://verybadwizards.fireside.fm/", 
			desc: "A philosopher and a psychologist ponder questions about human morality in casual conversation."
		},
		{
			title: "History of Philosophy Without Any Gaps",
			addr: "https://historyofphilosophy.net/",
			desc: "The most scholarly podcast of any in this list, History of Philosophy Without Any Gaps has a mandate to cover all the nooks and crannies of philosophy that usually get skipped over. It's an impressive undertaking and it's always educational.",
		},
		{
			title: "Philosophy Bites",
			addr: "https://philosophybites.com/",
			desc: "Quick but incisive interviews with distinguished philosophers about their work."
		},
		{
			title: "Philosophy For Our Times",
			addr: "https://iai.tv/iai-podcast",
			desc: "A live podcast in which the world's leading thinkers debate today's biggest ideas. Produced by The Institute of Art and Ideas."
		},
		{
			title: "Radiolab", 
			addr: "https://www.wnycstudios.org/shows/radiolab", 
			desc: "Radiolab is not specifically a podcast about philosophy (it's more often about science), but it's a fun listen and it often engages with philosophical issues."
		},
	];

	const Panel = Collapse.Panel;


	return (
		<Collapse className="detailsResourcesAccordion">
			<Panel header="Philosophy Resources" key="1">
		      <List
			    itemLayout="horizontal"
			    dataSource={pages}
			    renderItem={item => (
			      <List.Item>
			        <List.Item.Meta
			          avatar={<Avatar style={{ color: '#fffbe6', backgroundColor: '#faad14' }} icon="bulb" />}
			          title={<a href={item.addr} className="detailsResourceTitle">{item.title}</a>}
			          description={<p className="detailsResourceDesc">{item.desc}</p>}
			        />
			      </List.Item>
			    )}
			  />
		    </Panel>

		    <Panel header="Paper-Writing Resources" key="2">
		      <List
			    itemLayout="horizontal"
			    dataSource={writing}
			    renderItem={item => (
			      <List.Item>
			        <List.Item.Meta
			          avatar={<Avatar style={{ color: '#fffbe6', backgroundColor: '#faad14' }} icon="edit" />}
			          title={<a href={item.addr} className="detailsResourceTitle">{item.title}</a>}
			          description={<p className="detailsResourceDesc">{item.desc}</p>}
			        />
			      </List.Item>
			    )}
			  />
		    </Panel>

		    <Panel header="Podcasts" key="3">
		      <List
			    itemLayout="horizontal"
			    dataSource={podcasts}
			    renderItem={item => (
			      <List.Item>
			        <List.Item.Meta
			          avatar={<Avatar style={{ color: '#fffbe6', backgroundColor: '#faad14' }} icon="audio" />}
			          title={<a href={item.addr} className="detailsResourceTitle">{item.title}</a>}
			          description={<p className="detailsResourceDesc">{item.desc}</p>}
			        />
			      </List.Item>
			    )}
			  />
		    </Panel>
	    </Collapse> 



	);
}

export default ResourcesContent;
