import React, { Component } from 'react';
import Media from 'react-media';
import { Row, Col, Card } from 'antd';

import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import Hero from '../Components/Hero/Hero';
import ContentCard from '../Components/ContentCard/ContentCard';
import ScheduleCal from '../Components/ScheduleCal/ScheduleCal';
import SyllabusDetails from '../Components/SyllabusDetails/SyllabusDetails';
import SyllabusDetailsSmall from '../Components/SyllabusDetails/SyllabusDetailsSmall';
import IntroContent from './syllabus/IntroContent';




class PageContentSyllabus extends Component {

	render() {
		return (
			<>
		     	<Row type="flex" justify="center" id="title"> 
				    <Col style={{textAlign: "center", marginTop: 30, minWidth: "calc(100vw / 24 * 22)"}}
				    	xs={{ span: 22 }} xl={{span: 24}}>
				    	<Card cover={<Hero />} bordered={false} hoverable className="heroCard">
						<h3 className="textCenter"> a Clarkson University course</h3>
		            	<h3 className="textCenter"> Dr. Richard Dub </h3>
				    	</Card>
				    </Col>
				</Row>

	      	  	<ContentCard id="coursedesc">
	      	  		<IntroContent />
			    </ContentCard>
			   
			    <ContentCard id="calendar">
				    <ScheduleCal />
				</ContentCard>

				<Media query="(max-width: 992px)">
			      	{matches => matches 
			      	?
			      	<SyllabusDetailsSmall />
			      	: 
			      	<ContentCard id="info">
						<SyllabusDetails detailsTabOpen={this.props.detailsTabOpen} 
										changeDetailsTab={this.props.changeDetailsTab}/>
					</ContentCard>
					}
				 </Media>

				 <div style={{height: "10vh"}}/>
				 <ScrollToTopOnMount />
			</>
		);
	}
}

export default PageContentSyllabus;

// this is only a class component because I am trying to get scroll restoration to work.