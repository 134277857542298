import React from 'react';
import { List, Avatar, Divider } from 'antd';

const ContactContent = (props) => {

	const { Item } = List;

	const contactInfo = [	["desktop", "Website", <a href="https://www.richarddub.net">richarddub.net</a>],
							["mail", "Mail", <a href="mailto:richard.dub@gmail.com">rdub@clarkson.edu</a>],
							["bank", "Office", <a href="https://sites.clarkson.edu/gallery/browse-the-potsdam-campus-art-locations/price-hall/">Price Hall 2201</a>],
							["clock-circle", "Office hours", <p style={{textAlign: 'right'}}>MWF 1:00-2:00 <br />Th 1:00-4:00<br /><a href="https://calendly.com/richarddub">Click to schedule</a></p>]
						];

	const contactLines = contactInfo.map(x => 	<Item key={x[1]}>
													<div style={{width: "10%"}}>
														<Avatar style={{ color: '#fffbe6', backgroundColor: '#faad14' }} icon={x[0]}/>
													</div>
														<div style={{ display: "flex", justifyContent: "space-between", width: "90%"}}>
															<div className="detailsContactLineText detailsContactLineTextVertCenter">{x[1]}</div>
															<div >{x[2]}</div>
														</div>
												</Item>);

	return (
		<div className="detailsContactBox">
			<h3 style={{textAlign: "center", opacity: 0.8}}>Dr. Richard Dub</h3>
			
			<List style={{width: "100%", margin: "auto", maxWidth: "600px"}}>
				{contactLines}
			</List>
			<div style={{height: "30px"}}> </div>
			<Divider />
			<p style={{textAlign: "center", opacity: 0.8}}> Also available by appointment. </p>
	    </div>
	);
}

export default ContactContent;
