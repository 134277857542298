import React from 'react';
import { Anchor } from 'antd';
import allClasses from '../../../content/courseContentIndex';

const CategoryAnchor = ( {category} ) => {

	const { Link } = Anchor;

	const noAddressBar = (e, link) => {
	  e.preventDefault();
	};

    const listOfLinks = allClasses.filter(x=>x.category===category).map( classInfo => <Link key={classInfo.hashID} href={"#"+classInfo.hashID} title={classInfo.title}/> );

	return (
		<Anchor affix={false} 
            	showInkInFixed={true} 
            	onClick={noAddressBar} 
            	style={{background: "#001529", color: "@gold-1", marginLeft: 30}} 
            	getContainer={()=>document.getElementById("layoutMainContent")}
            	offsetTop={80}
            	offsetBottom={-30}>
    		{listOfLinks}
    	</Anchor>
	);
}

export default CategoryAnchor;