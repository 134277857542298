import React from 'react';
import './ContentBoxStyles.less';
import { List } from 'antd';

const ReadingsBox = ( props ) => {

	let heading = "Readings";
	if (props.audio) {
		heading = "Audio";
	} else if (props.video) {
		heading = "Video";
	}

	return (
			<div className="readingsBoxWrapper">
    			<List
    				className="readingsBox"
    				header={<h3 style={{opacity: 0.7, marginBottom: "0px"}}>{(props.optional) ? "Optional "+heading : heading}</h3>}
    				bordered={false}
      				dataSource={props.readings}
      				renderItem={item => (
        					<List.Item >
        						<div style={{textAlign: "center", width: "100%"}}>
          						<a href={item[1]}>{item[0]}</a>
          						</div>
        					</List.Item>
      				)}>
    			</List>
    		</div>
	);
}

export default ReadingsBox;