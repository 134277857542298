import React from 'react';
import './DivsWhatItsLike.less';
import WinkyEye from './gidgets/WinkyEye';

const DivsWhatItsLike = (props) => {

	const size = props.size;

	let eyesize = (size===" wideCanvas") ? 46 : 40;
	eyesize += (size===" wideCanvas"||size===" topbars wideCanvas") ? "vh" : "vw";

	return (	
				<div className="whatItsLike" style={{display: "flex", justifyContent: "center", overflow: "hidden"}}>
					<div className={"WILEverWonder whatItsLikeElem"+size}>
						<div className="WILEverWonderElem">ever wonder</div>
						<div className="WILEverWonderElem">.</div> 
						<div className="WILEverWonderElem">.</div> 
						<div className="WILEverWonderElem">.</div>
					</div>

					<div className="WILMyRed">
						<div className="WILchunk1 WILchunk">whether <span className="red">your RED</span></div> 
						<div className="WILchunk2 WILchunk">is the same as</div>
						<div className="WILchunk3 WILchunk"><span className="red">my RED</span>?</div>
					</div>

					<div className="WILEyes"> 
						<div className="WILEyesChunk WILEyesChunk1">how things</div>
						<div className="WILEyesChunk WILEyesChunk2">would look</div>
						<div className="WILEyesChunk WILEyesChunk3">through another</div>
						<div className="WILEyesChunk WILEyesChunk4">person's eyes</div>
					</div>

					<WinkyEye size={eyesize}/>

					<div className="WILToBeContainer">
						<div className="WILToBeIntro">
							<div>what it would be like to be</div> 
							<div className="dotpadding">.</div>
							<div className="dotpadding">.</div>
							<div className="dotpadding">.</div>
						</div>
						<div className="WILToBeItem WILToBeItem1">a bat?</div>
						<div className="WILToBeItem WILToBeItem2">disabled?</div>
						<div className="WILToBeItem WILToBeItem3">a different gender?</div>
						<div className="WILToBeItem WILToBeItem4">a bird?</div>
						<div className="WILToBeItem WILToBeItem5">a cat?</div>
						<div className="WILToBeItem WILToBeItem6">a different religion?</div>
						<div className="WILToBeItem WILToBeItem7">delusional?</div>
						<div className="WILToBeItem WILToBeItem8">a spider?</div>
						<div className="WILToBeItem WILToBeItem9">old?</div>
						<div className="WILToBeItem WILToBeItem10">a different race?</div>
						<div className="WILToBeItem WILToBeItem11">very rich?</div>
						<div className="WILToBeItem WILToBeItem12">a computer?</div>
						<div className="WILToBeItem WILToBeItem13">immortal?</div>
						<div className="WILToBeItem WILToBeItem14">nothing at all?</div>
					</div>

					<div className="WILYouYouContainer">
						<div className="WILWhatMakes">what makes</div>
						<div className="WILYou WILYou1">YOU</div>
						<div className="WILYou WILYou2">YOU</div>
						<div className="WILQMark">?</div>
					</div>
				</div>
		);
}

export default DivsWhatItsLike;