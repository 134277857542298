import React from 'react';
import './WinkyEye.less';

const WinkyEye = (props) => {

	return (
		<div className="eyescale" style={{"--eyesize": props.size}}>
		  <div className="winkyeye">
		    <div className="winkyiris">
		      <div className="winkypupil">
		        ?
		      </div>
		    </div>
	    	{props.open ? null : <div className="winkyeyelid" />}
		  </div>
		 </div>
		);
}

export default WinkyEye;
