import React from 'react';
import { Button, Divider, Calendar, Popover, Alert, Icon } from 'antd';
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';
import courses, { classDates, paperDeadlines } from '../../content/courseContentIndex';
import { examPeriod, breakDays, quizDates } from '../../content/courseContentIndex';
import Media from 'react-media';

import './ScheduleCal.less';


const ScheduleCal = ( props ) => {

	const firstDay = classDates[0].clone().date(1);
	const lastDay = examPeriod.slice(-1)[0];
	const currentDay = moment();
	const defaultDay = (currentDay.diff(firstDay)<0) ? firstDay : (currentDay.diff(lastDay)<0) ? currentDay : lastDay;
	const classDatesDOY = courses.map(x=>x.date.dayOfYear());
	const paperDeadlinesDOY = paperDeadlines.map(x=>x.dayOfYear());
    const quizDatesDOY = quizDates.map(x=>x.dayOfYear());
	const examPeriodDOY = examPeriod.map(x=>x.dayOfYear());
	const breakDaysDOY = breakDays.map(x=>x.dayOfYear());
	const indexNextClass = Math.min(courses.length-1, classDates.reduce(((accum, day) => {return (currentDay.diff(day)<0) ? accum : accum+1}), 0));
	let monthname = currentDay.format("MMMM");


	// function that renders the contents of a date square when given a date
  	const dateCellRender = (value) => {
  		let dateContents = null;
  		const DOY = value.dayOfYear();
  		if (classDatesDOY.includes(DOY)) {
  			const indexCurrDisplayClass = classDatesDOY.indexOf(DOY);
  			const dateAlertBanner = 
  				<HashLink to={`/${courses[indexCurrDisplayClass].category.toLowerCase()}#${courses[indexCurrDisplayClass].hashID}`}>
  					<Alert banner showIcon={false} type="info" message={<div className="calBanner">{courses[indexCurrDisplayClass].title}</div>} />
  					{(paperDeadlinesDOY.includes(DOY)) ? <Alert banner showIcon={false} type="error" message={<div className="calBanner">PAPER DUE</div>} /> : null }
            {(quizDatesDOY.includes(DOY)) ? <Alert banner showIcon={false} type="error" message={<div className="calBanner">QUIZ</div>} /> : null }
  				</HashLink>;
			dateContents = 	<ul className="calendarDateList">
								<li style={{position: "relative"}}>
			  						<Media query="(min-width: 760px)">
							      		{matches => matches ? 
							      			dateAlertBanner :
								      		<Popover content={dateAlertBanner} title={value.format("MMM D")}>
											    <div className="calendarSmallContentBox"/>
											</Popover>}
									</Media>
								</li>
							</ul>
  		}
  		if (examPeriodDOY.includes(DOY)) {
  			dateContents = 	<Media query="(min-width: 760px)">
                  <>
  								<Alert banner showIcon={false} type="warning" message={<div className="calBanner">Exam Period</div>} />
                  {(paperDeadlinesDOY.includes(DOY)) ? <Alert banner showIcon={false} type="error" message={<div className="calBanner">PAPER DUE</div>} /> : null }
                  </>
  							</Media>
  			
  		}
  		if (breakDaysDOY.includes(DOY)) {
  			dateContents = 	<Media query="(min-width: 760px)">
  								<Alert banner showIcon={false} type="success" message={<div className="calBanner">Break Day</div>} />
  							</Media>
  			
  		}
  		return dateContents;
  	}


  	

  	// function that renders the header of the calendar
  	const renderCalHeader = ({ value, type, onChange, onTypeChange }) => {
  		monthname = value.format("MMMM");
  		//manipulating a nonlocal variable like this seems dangerous. It's to help with the renderFullCell method below.  If things start acting funny, consider making this component stateful and setting it in state.
  		const firstOfCurrMonth = value.clone().date(1).startOf('date');
  		const firstOfNextMonth = value.clone().month(value.month()+1).date(1).startOf('date');
  		return (
  			<div className="calendarHeaderCenter">
	  			<div className="calendarHeader">
	  				<div className="calendarMonthChangeBtnHolder">
	  					{!(firstOfCurrMonth.diff(firstDay)<=0) && 
		  					<Button className="calMonthChangeBtn" onClick={ () => {
		  						const newMonth = value.clone().month(value.month()-1);
		  						onChange(newMonth);} }> 
		  						<Icon type="left" style={{opacity: 0.9}}/> 
		  					</Button>}
	  				</div>
	  				<div className="monthHoldingBox">
		            	<h3 className="textCenter calMth">{monthname}</h3>
		            </div>
		            <div className="calendarMonthChangeBtnHolder">
		            	{(firstOfNextMonth.diff(lastDay)<0) && 
		           		<Button className="calMonthChangeBtn"onClick={ () => {
	  						const newMonth = value.clone().month(value.month()+1);
	  						onChange(newMonth);} }> 
		           			<Icon type="right" style={{opacity: 0.9}}/> 
		           		</Button>}
		            </div>
	          	</div>
          	</div>
  			);
  	}



  	/* This is a copy of antdesign's own code for rendering a date cell.  It's dangerous to use: I'm depending on antd
  	not changing their css class names. Just remove these and the dateFullCellRender attributes from the components in the render function below.  It's just to make each month show only days in that month.*/

  	const zerofixed = (num) => {
  		if (num < 10) {
		    return `0${num}`;
		}
	  	return `${num}`;
	}

	const renderFullCell = (value) => {
  		if (value.format("MMMM")===monthname) {
  			return (       
	  			<div className="ant-fullcalendar-date">
			        <div className="ant-fullcalendar-value">{zerofixed(value.date())}</div>
			        <div className="ant-fullcalendar-content">{dateCellRender(value)}</div>
			    </div>
			    );
  		} 
  		return null;
  	}

  	/* end dangerous code */

	return (
			<>
				<h2 className="textCenter"> Course Schedule </h2>
				<h3 className="textCenter"> Next Class: {courses[indexNextClass].title}</h3> 
				<div style={{display: 'flex', justifyContent: 'center', padding: "4px 0"}}>
					<Button>
						<HashLink
							to={`/${courses[indexNextClass].category.toLowerCase()}#${courses[indexNextClass].hashID}`}>
							Jump to Next Class
						</HashLink>
					</Button>
				</div>
				<Divider />
				<Media query="(min-width: 760px)">
			      	{matches => matches ? 
			      		<Calendar fullscreen={true} validRange={[firstDay, lastDay]} defaultValue={defaultDay}
    					dateCellRender={dateCellRender} headerRender={renderCalHeader} dateFullCellRender={renderFullCell} /> : 
			      		<Calendar fullscreen={false} validRange={[firstDay, lastDay]} defaultValue={defaultDay}
    					dateCellRender={dateCellRender} headerRender={renderCalHeader} dateFullCellRender={renderFullCell}
    					 />}
				</Media>
    		</>
	);
}

export default ScheduleCal;
