import React, {Component} from 'react';
import {Button} from 'antd';
import './SkipIntroBtn.less';

class SkipIntroBtn extends Component {

	componentDidMount () {
		setTimeout(this.animBtn, 30);
	}

	animBtn = () => {
		if (!!document.querySelector(".skipIntroBtn")) {
			document.querySelector(".skipIntroBtn").classList.add("skipIntroBtnAnim");
		}
	}

	render () {
		return (
			<Button className="skipIntroBtn" onClick={this.props.handleOnEnd}>
				SKIP INTRO
			</Button>
			);
	}
}
 export default SkipIntroBtn;