import React from 'react';
import './DivsNews.less';

const DivsNews = (props) => {

	return (
		<div className="news">

			<div className="newsCanTrust">
				when you can trust
			</div>

			<div className="newsTrustablesContainerContainer">
				<div className="newsTrustablesContainer">
					<div className="newsTrustable newsTrustable1">a journalist<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable2">a scientist<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable3">a sibling<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable4">a politician<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable5">a streamer<span className="newsQMark">?</span></div>
				</div>
				<div className="newsTrustablesContainer">
					<div className="newsTrustable newsTrustable6">a vlogger<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable7">a professor<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable8">a friend<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable9">a guru<span className="newsQMark">?</span></div>
					<div className="newsTrustable newsTrustable10">a headline<span className="newsQMark">?</span></div>
				</div>
			</div>

			<div className="newspaperContainer">
			  <div className="newspaper">
			    <div className="newsHeadline">WHAT IS FAKE NEWS?</div>
			    <div className="newspaperColumns">
			      <div className="newspaperWords"></div>
			      <div className="newspaperWords"></div>
			    </div>
			    <div className="newspaperPicture"></div>
			  </div>
			</div>

		</div>
	);

}

export default DivsNews;