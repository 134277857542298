import React, {Component} from 'react';
import { Icon } from 'antd';

class LoadingAnim extends Component {

	componentDidMount() {

	}

	componentWillUnmount () {
		this.props.togglePlaying();
	}

	render () {
		return <Icon type="loading" spin style={{fontSize: "200px"}}/>
	}
}

export default LoadingAnim
