import React from 'react';
import './DivsEthics.less';
import pig from '../../../img/pig.svg';
import meat from '../../../img/meat.svg';
import bacon from '../../../img/bacon.svg';
import steak from '../../../img/steak.svg';
import knife from '../../../img/knife.svg';

const DivsEthics = (props) => {

	const letterify = (arr, className) => {
		return arr.map((x,ind)=> <div key={ind} style={{display: "inline-block", position:"relative"}} className={(x==="!") ? "ethicsDummySpace" : className}>
						{x}
					</div>);
	}

	return (
		<div className="ethics" style={{display: "flex", justifyContent: "center", overflow: "hidden"}}>

			<div className="ethicsVeggie">
				<div className="ethicsIsItOKto">
					{letterify([..."is!it!okay!to!"], "ethicsOKLetter")}
				</div>
				<div className="ethicsKill">
				kill
				</div>
				<div className="ethicsAndEatAnimals">
					{letterify([..."!and!eat!animals?"], "ethicsAnimalsLetter")}
				</div>
			</div>

			<div className="ethicsGround">
				<div className="ethicsThreeIcons ethicsThreeIcons1">
					<img src={pig} alt="Pig" className="ethicsPig ethicsIcon ethicsPig1"/>
					<img src={meat} alt="Meat" className="ethicsMeat ethicsIcon ethicsMeat1"/>
					<div className="ethicsKnifeBlock">
						<img src={knife} alt="Knife" className="ethicsKnife ethicsIcon ethicsKnife1"/>
					</div>
				</div>
				<div className="ethicsThreeIcons ethicsThreeIcons2">
					<img src={pig} alt="Pig" className="ethicsPig ethicsIcon ethicsPig2"/>
					<div className="ethicsOink">oink</div>
					<img src={bacon} alt="Meat" className="ethicsMeat ethicsIcon ethicsMeat2"/>
					<div className="ethicsKnifeBlock">
						<img src={knife} alt="Knife" className="ethicsKnife ethicsIcon ethicsKnife2"/>
					</div>
				</div>
				<div className="ethicsThreeIcons ethicsThreeIcons3">
					<img src={pig} alt="Pig" className="ethicsPig ethicsIcon ethicsPig3" style={{transform: "scale(-1,1)"}}/>
					<img src={steak} alt="Meat" className="ethicsMeat ethicsIcon ethicsMeat3"/>
					<div className="ethicsKnifeBlock">
						<img src={knife} alt="Knife" className="ethicsKnife ethicsIcon ethicsKnife3"/>
					</div>
				</div>
			</div>

			<div className="ethicsOwe">
				<div className="ethicsOweText">
					<span>what do we owe</span> <span>to those who have</span> <div className="ethicsLess">less?</div>
				</div>
			</div>

			<div className="ethicsPrivacy">
				<div className="ethicsPrivacyText">
					<div>why is</div> 
					<div className="ethicsPrivacyContainer">
						<div className="ethicsPrivacyWord">privacy</div> 
						<div className="ethicsPrivacyWall" />
					</div>
					<div>important?</div>
				</div>
			</div>

			<div className="ethicsJustWar">
				<div className="ethicsSuchAThing objectiveWord">
					is
				</div> 
				<div className="objectiveWord">
					morality
				</div>
				<div className="ethicsWar objectiveWord">
					<span className="ethicsOb">ob</span><span className="ethicsSub">sub</span>jective?
				</div>
			</div>

			<div className="ethicsHugeDoorContainer">
					<div className="ethicsPurpose">
						<div className="ethicsPurposeWord">What</div>
						<div className="ethicsPurposeWord">gives</div>
						<div className="ethicsPurposeWord">life</div>
						<div className="ethicsPurposeWord">purpose?</div>
					</div>
			</div>

			<div className="ethicsDoorContainer">
				<div className="ethicsDoor ethicsDoor1">
					<div className="ethicsSwing"></div>
					LOVE
				</div>
				<div className="ethicsDoor ethicsDoor2">
					<div className="ethicsSwing"></div>
					TRUTH
				</div>
				<div className="ethicsDoor ethicsDoor3">
					<div className="ethicsSwing"></div>
					FAME
				</div>
				<div className="ethicsDoor ethicsDoor4">
					<div className="ethicsSwing"></div>
					FAMILY
				</div>
				<div className="ethicsDoor ethicsDoor5">
					<div className="ethicsSwing"></div>
					PLEASURE
				</div>
				<div className="ethicsDoor ethicsDoor6">
					<div className="ethicsSwing"></div>
					SERVICE
				</div>
				<div className="ethicsDoor ethicsDoor7">
					<div className="ethicsSwing"></div>
					MONEY
				</div>
				<div className="ethicsDoor ethicsDoor8">
					<div className="ethicsSwing"></div>
					SUCCESS
				</div>
				<div className="ethicsDoor ethicsDoor12">
					<div className="ethicsSwing"></div>
					HONOR
				</div>
				<div className="ethicsDoor ethicsDoor10">
					<div className="ethicsSwing"></div>
					PASSION
					</div>
				<div className="ethicsDoor ethicsDoor9">
					<div className="ethicsSwing"></div>
					CREATION 
					</div>
				<div className="ethicsDoor ethicsDoor11">
					<div className="ethicsSwing"></div>
					VIRTUE
				</div>
			</div>

			<div className="finalEverWonder">
				<div className="finalEverWonder2">
					<div className="finalEverWonderWord">ever</div>
					<div className="finalEverWonderWord">wonder?</div>
				</div>
			</div>



		</div>
	);

}

export default DivsEthics;