import React from 'react';
import { Collapse } from 'antd';
import { quizDates, paperDeadlines } from '../courseContentIndex.js'; 

const EvaluationContent = (props) => {

	const Panel = Collapse.Panel;

	const quizDatesEnglish = quizDates.map(val=>val.format("MMMM Do")).reduce( (acc, curr, index, arr) => {
		let string = acc+", ";
		if (index===arr.length-1) {
			string+="and ";
		}
		string+=curr;
		return string;
	} );

	const paperDeadlinesEnglish = paperDeadlines.map(val=>val.format("MMMM Do")).reduce( (acc, curr) => `${acc} and ${curr}`);

	return (
		<>
		<div className="detailsContent">
			<p>Your grade will be based on short weekly writing prompts, two short papers, three short multiple-choice quizzes, and class participation.</p>
			<p>
				I reserve the right to curve grades at the end of the course, but I will only increase grades. I will never decrease an individual grade in order to curve the class grade, so this can only benefit you.
			</p>
		</div>


		<Collapse className="detailsAccordion">
			<Panel header={<span>Quizzes <b style={{paddingLeft: "1em"}}>30%</b></span>} key="1">
			<div className="detailsEvalDesc">
				<p> Three multiple choice quizzes designed to test your comprehension of the material will take place during the semester. They will take place on Moodle. On quiz days, I'll enable them at the end of class and keep them open for you to finish until the beginning of the next class. Once you begin the quiz, you will have thirty minutes to complete it. They should not take nearly this long to complete: around ten minutes or so.</p>
				<p> Each will be worth 10% of your final grade.  I'll design the quizzes to heavily feature topics that emerge in class discussion, so it benefits you to attend and engage.</p>
				<p> Quiz dates are {quizDatesEnglish}.  Make-up quizzes will not be given unless you have prior permission or a very strong excuse backed up with official documentation from the university.</p>
			</div>
		    </Panel>

		    <Panel header={<span>Writing Prompt Responses <b style={{paddingLeft: "1em"}}>28%</b></span>} key="2">
		      <div className="detailsEvalDesc">
		      	<p> On most weeks, you'll have to submit a very short response to a writing prompt.  Your responses should be 200-400 words in length (that's one or two standard-length paragraphs).</p>
		      	<p> These responses are meant to exercise your writing and reasoning skills while also allowing you to experiment with philosophy.  They'll be graded pass/fail and they won't be graded harshly.  All I want to see is that you have done the reading and you have grappled with the question.  That is enough for full marks.  You'll get a zero on a week if you don't do the response, or if you write on something unrelated, or if you clearly have not done the reading.  Otherwise, you can expect a checkmark.</p>
		      	<p> Here's how it will work: most days of class will have a writing prompt about the reading.  You can find the writing prompts on this website. You'll have to choose one of the writing prompts for that week and submit your response before that day's class.  In other words, you can choose to write on the Monday reading (due Monday), the Wednesday reading (due Wednesday), or the Friday reading (due Friday).</p>
		      	<p> Note that there is only one option on Week 1 and there are only two options on Week 6.</p>
		      	<p> Because you typically have multiple options about which response to do, late or unsubmitted submissions won't be considered and make-up responses won't be allowed.</p>
		      	<p> You'll end up writing 14 responses over the semester. Each will be worth 2% of your final grade.</p>
		      </div>	
		    </Panel>

		    <Panel header={<span>Short Essays <b style={{paddingLeft: "1em"}}>36%</b></span>} key="3">
		      <div className="detailsEvalDesc">
		      	<p>Twice during the semester, I will make available a list of paper topics on arguments that relate to the material in class.  You are to choose one and analyze the argument in a paper that is 800-1200 words in length (3-5 pages).</p>
		      	<p> The Resources section of this website contains some links to helpful guides on writing philosophy papers.</p>
		      	<p> Each paper will be worth 18% of your final grade.  They are due on {paperDeadlinesEnglish}.</p>
		      	<p> The slides from my class presentation on what I will be looking for in your papers are available <a href="https://dubitatioslides.netlify.com/slides/paper">here</a>.</p>
		      </div>
		    </Panel>

		    <Panel header={<span>Class Participation <b style={{paddingLeft: "1em"}}>6%</b></span>} key="4">
		      <div className="detailsEvalDesc">
		      	<p>This course is a conversation, and it will only work if you all contribute!  In order to encourage you to speak up and share your views, a small part of your grade depends on participation in class discussion.
		      	</p>

		      	<p>I know that this might be more difficult for some people who are naturally shy, who speak English as a second language, or who learn better without social stress.  The demands here are not heavy.  Speaking up once every two weeks or so will be enough for you to get the full four percent.  I want everyone to feel like they are a part of the class dynamic even if they enter into discussion only occasionally.</p>

		      	<p>A word of advice: get involved in discussion early in the course!  It will immediately make you feel more comfortable.  The longer you go without speaking, the harder it will be to do so later on... especially when using a technology as unfamiliar as Zoom.  And remember not to compare yourself to the students who talk the most often. Most students will chirp in only occasionally, and that's perfectly fine.</p>
		      </div>
		    </Panel>

	    </Collapse> 
	    </>
	    
	);
}

export default EvaluationContent;