import React, { Component } from 'react';
import './Hero.less';

class Hero extends Component {

  state = {
    currentPhilFont: Math.floor(Math.random()*5)+1,
    currentContempFont: Math.floor(Math.random()*5)+1,
    philHiding: false,
    contempHiding: false,
    nextToChangeIsPhil: true,
    philFontsArray: [],
    contempFontsArray: [],
    rotationInterval: false,
  }

  changePhil = () => {
    let fonts = [...this.state.philFontsArray];
    if (!fonts.length) {
      fonts = [1,2,3,4,5].filter(num=>num!==this.state.currentPhilFont);
    }
    let nextFontNum = fonts.splice(Math.floor(Math.random()*fonts.length), 1)[0];
    this.setState(oldstate=>({philHiding: true}));
    setTimeout(()=>(this.setState(oldstate=>({
                                        currentPhilFont: nextFontNum,
                                        nextToChangeIsPhil: false,
                                        philFontsArray: fonts }))), 600);
    setTimeout(()=>(this.setState(oldstate=>({philHiding: false }))), 700);
  }

  changeContemp = () => {
    let fonts = [...this.state.contempFontsArray];
    if (!fonts.length) {
      fonts = [1,2,3,4,5].filter(num=>num!==this.state.currentContempFont);
    }
    let nextFontNum = fonts.splice(Math.floor(Math.random()*fonts.length), 1)[0];
    this.setState(oldstate=>({contempHiding: true}));
    setTimeout(()=>(this.setState(oldstate=>({
                                        currentContempFont: nextFontNum,
                                        nextToChangeIsPhil: true,
                                        contempFontsArray: fonts }))), 600);
    setTimeout(()=>(this.setState(oldstate=>({contempHiding: false }))), 700);
  }

  changeNext = () => {
    if (this.state.nextToChangeIsPhil) {this.changePhil()} else {this.changeContemp()};
  }

  resetInterval = () => {
    if (this.state.rotationInterval) {clearInterval(this.state.rotationInterval);}
    const rotationInterval = setInterval(this.changeNext, 4600);
    this.setState(oldstate=>({rotationInterval: rotationInterval}));
  }

  onClickPhil = () => {
    if (!this.state.philHiding) {
      this.changePhil();
      this.resetInterval();
    }
  }

  onClickContemp = () => {
    if (!this.state.contempHiding) {
      this.changeContemp();
      this.resetInterval();
    }
  }

  componentDidMount() {
    setTimeout(this.changePhil, 1500);
    const rotationInterval = setInterval(this.changeNext, 4600);
    this.setState(oldstate=>({rotationInterval: rotationInterval}));
  }

  componentWillUnmount() {
    if (this.state.rotationInterval) {clearInterval(this.state.rotationInterval);}
  }

  render() {
    let philClasses = `heroPhilContent${this.state.currentPhilFont}`;
    if (this.state.philHiding) {philClasses += " heroPhilHidden"};
    let contempClasses = `heroContempContent${this.state.currentContempFont}`;
    if (this.state.contempHiding) {contempClasses += " heroContempHidden"};

    return (
        <div id="hero">
          <div className="heroTextHalf heroPhil" onClick={this.onClickPhil}>
            <div className={philClasses}>Philosophy</div>
          </div>
          <div className="heroAnd"><b>&</b></div>
          <div className="heroTextHalf heroContemp" onClick={this.onClickContemp}>
            <div className={contempClasses}>Contemporary Issues</div>
          </div>
          <div style={{opacity: 0.01, position: "absolute", transform: "translateX -100vw", fontSize: "1px"}}>
            <span style={{fontFamily: "Sail-Philosophy"}}>P</span>
            <span style={{fontFamily: "AnglicanText-Philosophy"}}>P</span>
            <span style={{fontFamily: "Dalek-Philosophy"}}>P</span>
            <span style={{fontFamily: "Primitive-Philosophy"}}>P</span>
            <span style={{fontFamily: "Unifraktur-Philosophy"}}>P</span>
            <span style={{fontFamily: "Ailerons-Contemporary-Issues"}}>C</span>
            <span style={{fontFamily: "TNG-Contemporary-Issues"}}>C</span>
            <span style={{fontFamily: "Halogen-Contemporary-Issues"}}>C</span>
            <span style={{fontFamily: "Elianto-Contemporary-Issues"}}>C</span>
            <span style={{fontFamily: "VT323-Contemporary-Issues"}}>C</span>
          </div>
        </div>
    );
  }
}

export default Hero;