import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// EXTRA UTILITIES USED
// ====================

// Ant Design (antd) for UI components

// Create-React-App

// Craco, with Craco's Ant-Design plugin.  
// Ant Design uses the Less CSS Preprocessor, but that's not supported by CRA.
// Check package.json for modifications to override Less variables.
// Process explained at https://mobisoftinfotech.com/resources/blog/web-programming/set-up-a-create-react-app-project-with-ant-design-and-less-support/

// fonts aggresively subsetted and converted to WOFF2 with glyphhangar
// https://github.com/filamentgroup/glyphhanger

// Many fonts from Google Fonts, Ping and Echo from Typotheque, self-hosted fonts found on free font sites

// background image generated at Trianglify
// https://trianglify.io/

// background image optimized using Optimizilla
// https://imagecompressor.com/

// moment.js
// https://momentjs.com/

// react-router-dom
// using https://blog.pshrmn.com/entry/simple-react-router-v4-tutorial/ for help

// React Howler
// raf (request animation frame, see how it's used in React Howler)
// animejs

// react-container-dimensions
// https://github.com/okonet/react-container-dimensions

// react-media for viewport size queries
// https://github.com/ReactTraining/react-media