import React, { Component } from 'react';
import { Layout } from 'antd';
import 'antd/lib/button/style';
import { Switch, Route, Redirect } from 'react-router-dom';

import SideMenu from './Components/SideMenu/SideMenu';
import SideMenuDrawerButton from './Components/SideMenu/SideMenuDrawerButton';
import AnimationCover from './Components/IntroAnimation/AnimationCover/AnimationCover';
import Prompts from './Components/Prompts/Prompts.js';
import Lecture from './Components/Lecture/Lecture.js';

import PageContentSyllabus from './content/PageContentSyllabus';
import PageContent from './content/PageContent';
import Media from 'react-media';
import './App.less';



class App extends Component {


	/********************************************
	/       State 							    /
	********************************************/

	state = {
		siderCollapsed: true,
		detailsTabOpen: "1",
		showAnimation: false,
	}

	/********************************************
	/      toggle IntroAnimation method   		/
	********************************************/


	toggleIntroAnimation = () => {
		this.setState(oldstate=>({showAnimation: !oldstate.showAnimation}));
	}

	componentDidMount () {
		if (!localStorage.getItem("skipAnimation")) {
			this.setState({showAnimation: true});
		}
	}


	/********************************************
	/       App Methods to pass thru props      /
	********************************************/

	toggleSider = () => {
		this.setState(oldstate=>({siderCollapsed: !oldstate.siderCollapsed}));
	}

	setSider = broken => this.setState(oldstate=>({siderCollapsed: broken}));

	changeDetailsTab = (key, e) => {
		this.setState(oldstate=>({detailsTabOpen: "0"}));
		setTimeout(() => {this.setState(oldstate=>({detailsTabOpen: key}))}, 5);
	}
	

	/********************************************
	/       Route rendering methods              /
	********************************************/


	renderSyllabus = props => <PageContentSyllabus {...props} 
									detailsTabOpen={this.state.detailsTabOpen}
									changeDetailsTab={this.changeDetailsTab} 
									 />;

	renderPageIntroduction = props => <PageContent {...props} page="Introduction" />;

	renderPageMeta = props => <PageContent {...props} page="Metaphysics" />;

	renderPageKnowledge = props => <PageContent {...props} page="Epistemology" />;

	renderPageMind = props => <PageContent {...props} page="Mind" />;

	renderPageEthics = props => <PageContent {...props} page="Ethics" />;

	renderPagePrompts = props => <Prompts {...props} />

	renderWIPLecture = props => <Lecture {...props} />



	/********************************************
	/        Render methods                   /
	********************************************/

	render() {
	  	const { Content } = Layout;

	    return (

	    	<>
		      {this.state.showAnimation  
		      	?
		      	<AnimationCover toggleIntro={this.toggleIntroAnimation}/>
		      	:
		      	<Layout hasSider={true}>
		      		<SideMenu 	collapsed={this.state.siderCollapsed} 
		      					setSider={this.setSider} 
		      					changeDetailsTab={this.changeDetailsTab} 
		      					details={this.state.detailsTabOpen}
		      					toggleIntro={this.toggleIntroAnimation}/>
				    <Layout style={{height: '100vh', overflowY: 'scroll', overflowX: "hidden"}} id="layoutMainContent" >
					      <Content id="contentContainer">
					      		<div id="contentTop"></div>
					      		<Switch>
								  <Route path='/syllabus' render={this.renderSyllabus}/>
								  <Route path='/home' render={this.renderSyllabus}/>
								  <Route path='/introduction' render={this.renderPageIntroduction}/>
								  <Route path='/metaphysics' render={this.renderPageMeta}/>
								  <Route path='/knowledge' render={this.renderPageKnowledge}/>
								  <Route path='/epistemology' render={this.renderPageKnowledge}/>
								  <Route path='/mind' render={this.renderPageMind}/>
								  <Route path='/experience' render={this.renderPageMind}/>
								  <Route path='/ethics' render={this.renderPageEthics}/>
								  <Route path='/prompts' render={this.renderPagePrompts}/>
								  <Route exact path='/intro'> <Redirect to='/introduction' /></Route>
								  <Route path='/lectures' render={this.renderWIPLecture}/>
								  <Route path='/lectures/whatisphilosophy' render={this.renderWIPLecture}/>
								  <Route exact path='/' render={this.renderSyllabus}/>
								  <Route render={this.renderSyllabus} />
								</Switch>
					      </Content>
				    </Layout>
				    <Media query="(max-width: 992px)">
				      	{matches => matches && <SideMenuDrawerButton siderCollapsed={this.state.siderCollapsed} toggleSider={this.toggleSider}/>}
					</Media>
		      	</Layout>
		      }
	      	</>
	    );
  }
}

export default App;