import React from 'react';
import { Divider } from 'antd';
import { HashLink } from 'react-router-hash-link';

const IntroContent = (props) => {
	return (
		<>
			<h2 className="textCenter"> PHILOSOPHY & CONTEMPORARY ISSUES </h2>
        	<h3 className="textCenter"> <i>Ever Wondered?</i> </h3>
        	<Divider />
            <div style={{fontFamily: "Echo, Ping, sans"}}>
        		<p>The word “philosophy” sounds pretty antiquated.  It's easy to think that philosophy died out with the toga.  Not true!  Philosophy is alive and well and more vital than ever.</p>
        		<p>Philosophy tries to answer questions that we don't have established frameworks for answering.  It's where we shine a light on the assumptions of other disciplines and on everyday unthinking routine.</p>
        		<p>This course will introduce you to philosophical issues that are still hotly debated and that are relevant to contemporary issues.  This is not just a course about <em>appreciating</em> philosophy. We're not going to just nod our heads at what a bunch of dead guys have said. This is a course about <em>doing</em> philosophy.  We'll ask tough questions and try to make progress on them ourselves. We'll look at questions about science, questions about ethics, questions about free will, and questions about consciousness.</p>
        		<p>If you are a curious person, you'll find much to think about.</p>
                <p>Please explore this website.  There are all sorts of interesting things to click on and read. If you'd like to start out by learning more about what philosophy is, you can jump into the lecture notes from the first class, available <HashLink to="/lectures/whatisphilosophy">here</HashLink>.</p>
                <p>An official course syllabus can be downloaded <a href="texts/Syllabus.docx">here</a>.</p>
            </div>
	    </>
	);
}

export default IntroContent;
