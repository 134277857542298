import React from 'react';
import { Divider, Button } from 'antd';

import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import ContentCard from '../Components/ContentCard/ContentCard';
import ReadingsBox from './ReadingsBox';
import classes from './courseContentIndex.js';
import './ContentBoxStyles.less';

import { HashLink } from 'react-router-hash-link';

const PageContent = (props) => {

	const filteredClasses = classes.filter(x=>x.category===props.page);
	const classContentDisplays = filteredClasses.map((x,index) => 
		<ContentCard id={x.hashID} key={x.hashID+"card"}>
			<h2 className="textCenter"> {x.title} </h2>
			<h3 className="textCenter"> {x.date.format("MMMM Do")} </h3>
			<Divider />
			<div className="contentBoxContentWrapper">
				<div className="contentBoxTextWrapper">
					{(x.subtitle) ? <h3 className="textCenter" style={{paddingTop: "16px", fontFamily: "Ping, Echo, sans"}}> {x.subtitle} </h3> : null}
					{x.content()}
				</div>
				{(x.readings || x.readingsO || x.audio || x.audioO || x.video || x.videoO) ?
					<div className="contentBoxSiderWrapper">
						{(x.readings) ? <ReadingsBox readings={x.readings}/> : null}
						{(x.audio) ? <ReadingsBox readings={x.audio} audio/> : null}
						{(x.video) ? <ReadingsBox readings={x.video} video/> : null}
						{(x.readingsO) ? <ReadingsBox readings={x.readingsO} optional/> : null}
						{(x.audioO) ? <ReadingsBox readings={x.audioO} audio optional/> : null}
						{(x.videoO) ? <ReadingsBox readings={x.videoO} video optional/> : null}
						{(x.prompt) ? <div className="readingsBoxWrapper">
										<Button className="writingPromptButton" size="large">
											<HashLink to={"/prompts#"+x.hashID}>
												Writing Prompt
											</HashLink>
									  	</Button>
									  </div>: null}
						{(x.lecture) ? <div className="readingsBoxWrapper">
										<Button className="writingPromptButton" size="large">
											<HashLink to={"/lectures/"+x.lecture}>
												Lecture Notes
											</HashLink>
									  	</Button>
									  </div>: null}
						{(x.slides) ? <div className="readingsBoxWrapper">
										<Button className="writingPromptButton" size="large" href={`https://dubitatioslides.netlify.app/slides/${x.slides}`}>
											Slides
									  	</Button>
									  </div>: null}
					</div>
				: null}
			</div>
			{(index===0) ? <ScrollToTopOnMount /> : null}
		</ContentCard>
		);

	return (
		<>
			{classContentDisplays}	   	    		
			<div style={{height: "35vh"}}/>
		</>
	);
}

export default PageContent;