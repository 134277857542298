import React from 'react';
import { Row, Col, Card } from 'antd';
import './ContentCard.less';

const ContentCard = (props) => {

	return (
			<Row type="flex" justify="center" id={props.id}>
		    	<Col xs={{ span: 22 }} xl={{ span: 20 }} xxl={{span: 16}} className="contentColumn">
			    	<Card bordered={true} hoverable className={"contentFontInfo content"+props.id}>
			    		{props.children}
			    	</Card>
		    	</Col>
		   	</Row>
		);

}

export default ContentCard;