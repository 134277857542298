import React from 'react';

const PoliciesContent = (props) => {
	return (
		<div className="detailsContent">

			<h3 className="detailsPoliciesHeading">Attendance</h3>
			<p>Attendance is mandatory. If your absentee status crosses a certain threshold, university regulations oblige me to report you to the office of the Dean of Students. If we are obliged to hold classes online, keeping your video on is not required, but it is appreciated.</p>

			<h3 className="detailsPoliciesHeading">Classroom Environment</h3>
			<p>The material in this course will generate debate and argument.  You will disagree with your fellow students. There is a delicate balancing act here: we want to maintain a safe and respectful classroom environment while also allowing all sorts of propositions to be up for debate. We must together guard against any discussion spiralling into something heated or uncomfortable. </p>
			<p> Our guiding principle is to be respectful toward others. Don't intentionally antagonize, and try not to escalate if you feel yourself being antagonized. It’s okay to argue for provocative theses, and it's okay to voice viewpoints that you think might be unpopular, but don’t do so simply in order to be provocative. Always try to empathize with your fellow students.</p>
			<p> If you think that someone’s behavior is threatening, harassing, bullying, or creating a negative classroom atmosphere, please notify me. You can create a throwaway e-mail address and send me a message anonymously, if you’d like. <em>Disrespect directed toward other students will not be tolerated.</em>
			</p>

			<h3 className="detailsPoliciesHeading">Disabilities</h3>
			<p>Please inform the Office of Accessibility Services of special needs that you may have, such as requiring extra time on quizzes. The sooner you notify them, the better I will be able to accommodate you.</p>

			<h3 className="detailsPoliciesHeading">Academic Integrity</h3>
			<p>Violations of the Clarkson University academic integrity policy (including cheating, plagiarism, or downloading a paper from a paper mill) will have serious and disastrous repercussions.  The punishment for cheating will always be worse for you than the worst outcome had you not cheated, and it is far easier to spot cheating than you think.  You can familiarize yourself with the Clarkson undergraduate regulations <a href="https://www.clarkson.edu/sites/default/files/2018-09/ugrad-regulations.pdf">here</a>.
			</p>
			<p>Don't let this dissuade you from having conversations about your papers and the course material with other classmates outside of class.  Conversation is always encouraged!</p>


			<h3 className="detailsPoliciesHeading">Metapolicy</h3>
			<p>I reserve the right to change elements of this syllabus during the semester.  I will only do so for good reasons and with fair warning.</p>

			

	    </div>
	);
}

export default PoliciesContent;