import React from 'react';
import './DivsFinal.less';

const DivsFinal = ( props ) => {

	return (
			<>				
				<div className="finalWords" style={{display: "flex", justifyContent: "center"}}>
					<div className="finalWord finalWordP">
						<div className="finalWordP1">Philosophy</div>
						<div className="finalWordP2">Philosophy</div>
						<div className="finalWordP3">Philosophy</div>
					</div>
					<div className="finalWord finalWordA">and</div>
					<div className="finalWord finalWordC">
						<div className="finalWordC1">Contemporary</div>
						<div className="finalWordC2">Contemporary</div>
						<div className="finalWordC3">Contemporary</div>
					</div>
					<div className="finalWord finalWordI">
						<div className="finalWordC1">Issues</div>
						<div className="finalWordC2">Issues</div>
						<div className="finalWordC3">Issues</div>
					</div>
				</div>
				<div className="finalCredit">
					a course by Dr. Richard Dub
				</div>
			</>

		);

}

export default DivsFinal;