import React from 'react';
import './DivsComputer.less';

const DivsComputer = (props) => {

	const beat = props.beat;

	const createGattaca = () => {
		let str = "";
		for (let i=0; i<250; i++) {
			let rand = Math.random();
			if (rand<0.25) {
				str+="G";
			} else if (rand<0.5) {
				str+="A";
			} else if (rand<0.75) {
				str+="T";
			} else {
				str+="C";
			}
		}
		return str;
	}

	const createBinGattaca = () => {
		let str = "";
		for (let i=0; i<250; i++) {
			let rand = Math.random();
			if (rand<0.17) {
				str+="G";
			} else if (rand<0.34) {
				str+="A";
			} else if (rand<0.51) {
				str+="T";
			} else if (rand<0.68) {
				str+="C";
			} else if (rand<0.85) {
				str+="1";
			} else {
				str+="0";			
			}
		}
		return str;
	}

	const createBin = () => {
		let str = "";
		for (let i=0; i<250; i++) {
			let rand = Math.random();
			if (rand<0.5) {
				str+="1";
			} else {
				str+="0";			
			}
		}
		return str;
	}

	let gatstr1 =""; let gatstr2=""; let gatstr3="";

	if (beat<84 && beat>63) {
		gatstr1 = createGattaca();
		gatstr2 = createGattaca();
		gatstr3 = createGattaca();
	} else if (beat<90 && beat>63) {
		gatstr1 = createBinGattaca();
		gatstr2 = createBinGattaca();
		gatstr3 = createBinGattaca();
	} else if (beat>=90 && beat<113) {
		gatstr1 = createBin();
		gatstr2 = createBin();
		gatstr3 = createBin();
	}

	return (
			<div className="computer" style={{display: "flex", justifyContent: "center", overflow: "hidden"}}>
					
					<div style={{height: "100%", width: "100%", display: "relative"}}>
						<div className="CompBGStrings CompBGString1">
							<div className="GattacaString1">
								{gatstr1}
							</div>
						</div>
						<div className="CompBGStrings CompBGString2">
							<div className="GattacaString2">
								{gatstr2}
							</div>
						</div>
						<div className="CompBGStrings CompBGString3">
							<div className="GattacaString3">
								{gatstr3}
							</div>
						</div>
					</div>

					
					<div className="CompWorry">
						<div className="CompShouldWorry">should we be worried about</div>

						<div className="CompWorries">
							<div className="CompUhOh CompGE">
								<div>
									{[..."GENETIC"].map((x,ind)=><span className="GATTACAletter" key={ind}>{x}</span>)}
								</div> 
								<div>
									{[..."ENGINEERING?"].map((x,ind)=><span className="GATTACAletter" key={ind}>{x}</span>)}
								</div>
							</div> 
							<div className="CompUhOh CompCE">
								<div className="CompCognitive">
									{[..."COGNITIVE"].map((x,ind)=>
										<div style={{display: "inline-block", position:"relative"}} key={ind}>
											<span className="ENHANCEletter ENHANCEword1" style={{position: "absolute"}}>{x}</span>
											<span className="ENHANCEletter ENHANCEword2">{x}</span>
										</div>
										)}
								</div> 
								<div className="CompEnhancement">
									{[..."ENHANCEMENT?"].map((x,ind)=>
										<div style={{display: "inline-block", position:"relative"}} key={ind}>
											<span className="ENHANCEletter ENHANCEword1" style={{position: "absolute"}}>{x}</span>
											<span className="ENHANCEletter ENHANCEword2">{x}</span>
										</div>
										)}
								</div>
							</div>
							<div className="CompUhOh Comp1sand0s">
								<div>
									{[..."101101001"].map((x,ind)=><div style={{display: "inline-block", position:"relative"}} key={ind} className="CompAI1s">{x}</div>)}
								</div>
								<div>
									{[..."010001110101"].map((x,ind)=><div style={{display: "inline-block", position:"relative"}} key={ind} className="CompAI0s">{x}</div>)}
								</div>
							</div>
							<div className="CompUhOh CompAI">
								<div>
									{[..."ARTIFICIAL"].map((x,ind)=><div style={{display: "inline-block", position:"relative"}} key={ind} className="CompArtificial">{x}</div>)}
								</div> 
								<div>
									{[..."INTELLIGENCE?"].map((x,ind)=><div style={{display: "inline-block", position:"relative"}} key={ind} className="CompIntelligence">{x}</div>)}
								</div> 
							</div>
						</div>

						<div className="ComputerIcon">
							<div className="comp-elements">
							  <div className="comp-center">
							    <div className="comp-screen-border">
							      <div className="comp-screen"></div>
							    </div>
							  </div>
							  <div className="comp-center">
							    <div className="monitor-stem"></div>
							  </div>
							  <div className="comp-center">
							    <div className="monitor-base"></div>
							  </div>
							</div>
							<div className="comp-machine">
							  <div className="comp-center-line">
							    <div className="comp-fan-line"></div>
							  </div>
							  <div className="comp-center-line">
							    <div className="comp-fan-line"></div>
							  </div>
							  <div className="comp-center-line">
							    <div className="comp-fan-line"></div>
							  </div>
							</div>
						</div> 
					</div>

					<div className="CompUpload">
						could my consciousness be uploaded to a computer?
					</div>

					<div className="CompScreenGridContainer">
						<div className="CompScreenGrid">
						</div>
					</div>

					<div className="CompTyping">
						<div className="CompTypingWord">
							{[..."what"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."if"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."we're"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."living"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."in"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."a"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
						<div className="CompTypingWord">
							{[..."simulation?"].map((x,ind)=><div key={ind} style={{display: "inline-block", position:"relative"}} className="CompSimulationLetter">{x}</div>)}
						</div>
					</div>

					<div className="CompWhoa">whoa!</div>
					
			</div>
		);

}

export default DivsComputer;