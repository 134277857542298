import { Component } from 'react';

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    const LayoutBox = document.getElementById("layoutMainContent");
    if (LayoutBox) {
    	LayoutBox.scrollTop = 0;
    }
  }

  render() {
    return null;
  }
}

export default ScrollToTopOnMount;