import React from 'react';
import { Divider } from 'antd';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount.js';
import ContentCard from '../ContentCard/ContentCard.js';
import './Lecture.less';


const Lecture = (props) => {

	return (
		<>
			<ContentCard >
				<div className="lectureCard">
				<h2 style={{textAlign: "center"}}> What Is Philosophy?</h2>
				<Divider />

				<p>Philosophy!</p>
				<p>What is it?</p>
				<p>  What's it good for?</p>

				<p>Let's get this out of the way: for most people, the word "philosophy" sounds pretentious and faintly ridiculous.  Academics call themselves philosophers all the time and they don't think the title sounds any weirder than "biologist" or "historian". But for your average Joe on the street, philosophy died with the toga, and calling yourself a philosopher is like calling yourself a sorceror or a prophet. </p>

				<p>(Once, when I was in graduate school, my friend and I were talking with some guy at a bar and we mentioned that we would be teaching philosophy over the summer. My friend called us "philosophers". The guy at the bar said to her, in a sarcastic lah-di-dah voice, "Ooo-ooh! Well, this summer, <em>I'm</em> going to be... a <em>butler</em>.")</p>

				<p>Most people don't know what it is that philosophers do.  At bookstores like Barnes and Noble, works by Nietzsche are shelved next to woo-woo New Age books on teas that can recolor your aura.  I was recently at a public library with a combined section for "Philosophy and Ghosts."  None of this is correct!  Philosophy is not ghostology!</p>

				<p>Some impudent souls even have the gall to declare that philosophy has been supplanted by science.  Scientists have taken over the project of figuring out how the world works, so philosophy is dead.  The people making these pronouncements are usually just not aware of what philosophy actually is.  They still have in mind the misleading image of a monk on a mountaintop pontificating.  There was a nice recent turnabout by Bill Nye (the Science Guy).  He said that science has made philosophy irrelevant, but he received a lot of criticism, so he took a look at contemporary philosophy and <a href="https://qz.com/960303/bill-nye-on-philosophy-the-science-guy-says-he-has-changed-his-mind/">delivered a retraction</a>, admitting that philosophy is not what he thought.  Once he started reading philosophy, he said, he couldn't sleep for months.</p>

				<p>Happily, philosophy is on an upswing!  There are two shows currently on air that star philosopher protagonists (<em>The Good Place</em> and <em>A.P. Bio</em>).  CEOs and tech companies are hungry to hire employees with philosophical training because they recognize the creativity and rigor of reasoning that philosophy provides.  The public is taking the Science Guy's lead: they are starting to get it.  Right now, right at this moment: philosophy is lit.  Get hype.</p>

				<h3>Enough advertisement. Stay focused. What Is Philosophy?</h3>

				<p>Students new to philosophy nearly always zip to the idea that philosophy is the study of fundamental reality or fundamental ideas.  That's an answer that metaphysicians might especially like.  (Metaphysicians are philosophers who ask questions like "does reality depend on consciousness?" or "what kind of existence do numbers have?".)  But I don't think it can be a correct answer, or at least a complete one.  It includes lots of fields of study that aren't philosophy.  Isn't quantum physics fundamental?  How about math?  Defining philosophy as the study of fundamental reality includes too much.  </p>

				<p>Even worse, it includes too little.  There are lots of things non-metaphysician philosophers research that aren't obviously "fundamental reality".  There are philosophers who study how emotions affect imagination, or what propaganda is, or whether you should stop eating meat, or how the word 'the' works.  There's a sense in which these questions might be <em>linked</em> to issues about fundamental reality.  But it would have to be a pretty weak sense.  Politics and psychology and linguistics are all linked to issues about fundamental reality in a weak sense too.  If that's all you mean by "the study of fundamental reality", then you haven't distinguished what's special about philosophy.</p>

				<p>(What's neat about the argument I just gave is that it employs a bunch of methods that are super common tools of philosophers.  Demanding precision in the terms one uses, checking to see if definitions are too inclusive or too restrictive: those are the signature argumentative moves of a philosopher.  It makes perfect sense to deploy them here.  The question "what is philosophy?" is, in itself, a philosophical question! This isn't true of most other fields.  "What is biology?" is not a biological question, because biology is the study of organisms, and biology itself is not an organism.)</p>

				<p>No one has come to any agreement about what philosophy is.  There are at least as many conceptions of what philosophy is as there are philosophers.  The best way to learn about philosophy is to just <em>do</em> philosophy, and we'll be doing some soon enough.  In the meantime, let's settle for the second best way to learn what philosophy is, by going through a quick battery of many of the various ways that some contemporary philosophers conceive of philosophy.  None of the characterizations on this list is perfect or definitive, but they each get at something that is relatively distinctive about a lot of philosophical questions.</p>

				<h3>Primordial Soup</h3>

				<p>We'll start with the answer that I personally like best. Philosophy is the study of questions at the fringes: questions that we don't have established methods for answering.  There are all sorts of questions that come naturally to us &mdash; questions about consciousness or free will, for example &mdash; but that generate a lot of reasonable disagreement on how to even start answering them.  The space of possible solutions is enormous and indistinct and it's not obvious how to whittle it down.</p>

				<p>Sometimes we start making progress on one of these questions.  At that point, the question then breaks off and drifts away, like an iceberg cracking off an Antarctic shelf.  It becomes an entirely new academic discipline.  Philosophy is where the sciences come from. </p>

				<p>When you look back at the history of science, this often seems right.  A lot of scientific disciplines splintered off of philosophy.  Linguistic semantics is a particularly clear recent case of a discipline cracking off.  Psychology and computer science also have originating principles rooted in philosophy.  The word "scientist" is actually pretty recent; scientists used to be called "natural philosophers".  Nowadays we call Isaac Newton a scientist and Gottfried Leibniz a philosopher, but at the time, they were both just considered natural philosophers, competing with one another and engaged in the same sorts of pursuits.  Biology, chemistry, physics: all were once considered a part of natural philosophy until they were eventually housed in their own university departments.</p>

				<p>So, on this conception of philosophy, philosophy is a grabbag of questions that aren't yet able to be housed in their own departments, because there is so much uncertainty and reasonable disagreement about on how to go about answering them.  It's scholarship at the fringes.  I love this answer because of how <em>exciting</em> it makes philosophy. Research into uncharted epistemic borderlands!  Intellectual expeditions into the wild unknown!  Here be dragons!  It also explains why it looks like philosophy so often never seems to make any progress.  The reason is that as soon as we make progress on a question... it turns into non-philosophy.  Philosophy is the primordial soup of systematic knowledge.</p>

				<h3>Shoulds and Oughts</h3>

				<p>But that's just one conception of philosophy. I doubt it's even all that popular.  There are many philosophers whose stomachs would churn at the suggestion that philosophy is just immature science.  The philosopher Ludwig Wittgenstein claimed that if a philosophical question turned out to be answerable by science, then it was never a philosophical question to begin with.</p>

				<p>Maybe we need a totally different conception of philosophy.  What might delineate philosophy from the sciences and from the other humanities?  A few subject matters seem distinctively philosophical.  One is <em>normativity</em>.  The sciences are <em>descriptive</em>: they describe how the world is, how it works, what will happen if you manipulate it in a certain kind of way.  They don't tell you what's good about the world, how it ought to be, or what you should do in various situations.  Questions about goodness, justice, fairness, beauty, purpose... these are <em>normative</em> or <em>evaluative</em> questions.  They are a central tentpole of the field of philosophy and they seem relatively absent from most other fields.  </p>

				<p>Obviously, a lot of philosophical questions seem descriptive, not normative, like those about what consciousness is or about whether the universe has a first cause or about the structure of language.  But many of them might have secretly normative aspects that are hard to see.  And one could think descriptive questions will eventually be shuttled off to their own descriptive disciplines... but the normative topics will still remain, and <em>they</em> are core philosophy.</p>

				<h3>Subjectivity</h3>

				<p>Another property of philosophy that many think is absent from the sciences is <em>the first-person perspective</em>.  The sciences and many of the other humanities give you a perspective on the world that is third-personal and objective: the theories that it builds are not tied to any particular person's individual viewpoint.  Many people think this misses an important realm of knowledge: first-personal experience.  I can try to predict what you will do in the future, and I can try to predict what I will do in the future, but that is different from <em>deciding</em> what I will do in the future.  The predictions are made from the third-person; the decision is made from the first-person.  Philosophy investigates the world from this latter perspective.</p>

				<p>The idea that philosophy is home to questions about the first person manifests itself differently in different philosophers.  For some, the first-person perspective is simply another topic of study, albeit one that is unique to philosophy. Other philosophers (including many who call themselves "phenomenologists" or "existentialists") draw much larger metaphysical conclusions from the contents of subjective experience.</p>

				<h3>Conceptual Analysis</h3>

				<p>I haven't yet mentioned one of the more glaring differences between philosophy and the sciences.  Scientists do experiments.  The sciences are empirical. Researchers have to go out into world to find evidence for their theories.  Even other humanities disciplines like sociology and history require more engagement with the contingent facts about the world.  Much of philosophy seems like something you can do without leaving a comfy armchair.</p>

				<p>One of the explanations that has been given for this apparent fact is that philosophy concerns itself primarily with concepts.  In order to discover the nature of society, we have to go outside and explore society; to discover the nature of my <em>concept</em> of society, I can just think hard about what my concept is.  Whether a hot dog is a sandwich is probably best answered by getting clear on the concept of "sandwich," not by going out and analyzing sandwiches.  That's a silly and trivial example, obviously. Thinking about what is implied by a concept of "murder," or "consent," for example, might be more difficult and pressing.</p>

				<p>I should note that some "experimental philosophers" have rebelled against the notion that philosophy must be confined to the armchair, and they have taken to empirically investigating the concepts of different populations.</p>

				<h3>Philosophical Therapy</h3>

				<p>I have found that new students also often turn to the idea that philosophy is the study of questions that have no answer. (Or at least, no single correct answer; or at least, no correct answer that we can know.)  But think for a moment about how weird this would make philosophy as a discipline.  Why would philosophers try to answer questions that have no answer?  The enterprise is doomed from the start!  All hope of progress would be misguided!  The above conceptions of philosophy at least all presumed that progress on philosophical questions is possible.</p>

				<p>Still, some philosophers sadly nod their heads and say, yes, this is our lot. Progress in philosophy might not be possible... but even so, what are we supposed to do?  Not ask philosophical questions?  Puzzling over these issues is core to human existence.  Perhaps the value in puzzling over these issues isn't that we end up with objective theories about the nature of reality, but because puzzling <em>changes us</em> for the better. On this conception of philosophy, we might say that the value of philosophy comes from its being a form of therapy. Ancient Greek philosophers advised us to "know thyself", and perhaps the value in striving to know thyself is in coming to transform thyself. </p>

				<h3>A Logical Toolbox</h3>

				<p>Finally, maybe it's a mistake to try to identify philosophy with a particular subject matter.  One of the neat things about philosophy is that if you think of pretty much any subject X, there is someone out there who will say that they work on "The Philosophy of X".  Maybe it's better to say that philosophy is the use of a particular toolbox of <em>methods</em>.  In particular, philosophers are trained in the methods of logic.  Logic is domain-neutral and can be applied to any topic whatsoever, which explains why philosophy has such an enormous ambit.  If you commit a fallacy such as a "use-mention error" or a "scope ambiguity", philosophers will jump all over it.  If you're not trained in those methods, it is extremely easy to get sucked into committing those fallacies.</p>

				<p>From this perspective, philosophy is a second-order check on other disciplines to ensure good intellectual hygiene.</p>


				<h3>All of These!</h3>

				<p>The pragmatic answer, of course, is that philosophy is all of these!  It's a hodgepodge mishmash.  Any philosophy department of size will have multiple of these perspectives represented.</p>

				<p>This should be extremely exciting!  Philosophy asks Big Questions, but it also asks little bitty questions with formal precision.  Philosophy pushes against the limits of our knowledge about the world, but it also explores the most intimate and private of our experiences.  There are so many ways to think about philosophy and do philosophy that you can tailor philosophy to suit your interests.</p>

				<p>We'll be looking at an immense number of issues in this course.  You'll probably find some of them pretty dumb: maybe you'll think they're too trivial, or too academic and abstract, or founded on bad assumptions, or too stoner-ish.  That's fine!  Every philosopher thinks that an awful lot of philosophy is ridiculous.  You wouldn't be much of a philosopher if you didn't.  What I can practically guarantee is that at least some of the questions we'll be looking at will speak to you.  And we'll dig in deep.  And if all goes well, you won't be able to sleep for months.</p>

				<p>(sorry.)</p>

{/*				<Divider />

				<Collapse className="detailsAccordion">
					<Panel header="An Ill-Considered Addendum" key="1">

						<h3 style={{paddingTop: "1em"}}>The Analytic/Continental Distinction </h3>

						<p>There's a big divide in philosophy that I hesitate to even mention.  It generates more confusion than illumination, but it can't be denied that it is an important part of the sociological make-up of the discipline. If you plan to go deeper into philosophy,  you will almost surely run into it, so you should be warned in advance about the perceived distinction between analytic philosophy and continental philosophy.</p>

						<p>It is extremely common for philosophers today to self-identify as either analytic philosophers or continental philosophers.  Analytic philosophers are more common in North America and England; continental philosophers are more common in continental Europe (hence the name).  The two traditions tend to read and cite different groups of philosophers, and the style of argumentation tends to differ. However, it can be difficult to put your finger on exactly what of substance is different between the two camps. Any attempt at describing the difference usually ends up being cartoonish.</p>

						<p>So, I am going to be cartoonish.  Usually, the two camps just hurl insults at each other about why the other form of philosophy is bad.  Here is how a continental philosopher might insult analytic philosophers, and then, vice versa:</p>

						<p style={{marginLeft: "2em"}}><em>Analytic philosophers are robots and nitpicking nerds. They reduce human experience to an equation just because it makes them feel like they are making progress.  They chop the world into pure logic and miss the big picture.  They arrogantly treat every conversation like a debate tournament: they challenge you on all your terminology and try to use logic to make you feel dumb instead of just listening what you have to say.  They prize quick-witted cleverness over knowledge; in fact, they pride themselves on knowing almost nothing about history or society. They esteem math too much and devalue literature.  They dress like slobs. They think that puns are extremely funny.</em></p>

						<p style={{marginLeft: "2em"}}><em>Continental philosophers are poetry-spouting pretentious poseurs. They care more about saying things that sound deep than they care about discovering truths.  They think that blowing a vape cloud in your face counts as a good counterargument.  They'll come back from a vacation abroad with a fake accent. They constantly quote obscure thinkers hoping to intimidate you into thinking they are extremely well-read. They esteem literature too much and devalue math and science. They wear scarves and leather in the summer. They think that puns reveal deep metaphysical truths.</em></p>

						<p>There! Now you know the difference between the two camps.</p>

						<p>I'm obviously being tongue-in-cheek, but here's the thing: I'm not sure the distinction is much more mature than this.  The caricatures above only describe <em>bad philosophers</em>. They are not descriptive of good philosophers, who tend to read widely and engage with all sorts of ideas and traditions. It's fine to ally yourself with one of camps, but maybe try holding yourself above the fray; don't feel you need to identify with either group.</p>
						</Panel>
					</Collapse>*/}
				</div>
			</ContentCard>	    		
			<div style={{height: "35vh"}}/>
			<ScrollToTopOnMount />
		</>
	);

}

export default Lecture;