import React from 'react';
import { Divider } from 'antd';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount.js';
import ContentCard from '../ContentCard/ContentCard.js';
import classes from '../../content/courseContentIndex.js';
import './Prompts.less';

const Prompts = (props) => {

	const firstWeekNum = classes[0].date.isoWeek(); 
	//const classesCulled = classes.filter(x=>(x.date.isoWeek()!==firstWeekNum && x.date.dayOfYear()!==329)); 	// remove first week and Thanksgiving (Fall courses)
	const classesCulled = classes
		.filter((x,i)=>(i>=3))										// remove first three courses (Spring semester)
		.filter(x=>(x.date.dayOfYear()!==50))						// remove class after first break day
		.filter(x=>(x.date.dayOfYear()!==85))						// remove class after break day (Mar 26 class)
	
	const classesWeeks = classesCulled.map(x => {
		let weekNum = x.date.isoWeek()-firstWeekNum;
		//if (x.date.dayOfYear()>45) {weekNum--};						// collapse Feb 15 into week 4
		//if (x.date.dayOfYear()>82) {weekNum--};						// collapse Mar 24 into week 8
		if (x.date.dayOfYear()>70) {weekNum--};						// ignore spring break
		//if (x.date.dayOfYear()>285) {weekNum--};					// irrelevant for spring semester courses
		return {...x, week: weekNum};
	})

	const weeks = classesWeeks.map(x=>x.week).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

	const allPrompts = weeks.map((week,ind) => <>
		<ContentCard id={"week"+week+"prompt"} key={"week"+week+"prompt"}>
			<h2 className="textCenter promptHeading"> {"Week " + week + " Prompts"} </h2>
			{classesWeeks.filter(x=>x.week===week).map((x,index)=>(<>
					<Divider />
					<div className="promptLine">
						<div id={x.hashID} className="promptHashlinkBuffer" />
						<h4 className="promptDate">{x.title + " (due " + x.date.format("MMM D") + ")"}</h4>
						<div className="promptText">{!!x.prompt ? x.prompt : "to be added"}</div>
					</div>
					</>)
			)}
		</ContentCard>
		{(ind===0) ? <ScrollToTopOnMount /> : null}
		</>
		);

	return (
		<>
			<ContentCard>
				<h2 className="textCenter promptHeading">Writing Prompts</h2>
				<Divider />
				<p className="textCenter promptDate" style={{paddingTop: "1em"}}>Choose one prompt per week and write 200-400 words on it.</p>
				<p className="textCenter promptDate">Each is worth 2% of your final grade.</p>
			</ContentCard>
			{allPrompts}	   	    		
			<div style={{height: "35vh"}}/>
		</>
	);
}

export default Prompts;
