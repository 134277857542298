import React from 'react';
import { Button, Icon } from 'antd';

const SideMenuDrawerButton = ( {toggleSider, siderCollapsed} ) => {
	return (
			<Button type="primary" shape="circle" onClick={toggleSider}
	          		style={{position: "fixed", bottom: "35px", right: "25px", height: "60px", width: "60px", zIndex: 200}}>
			  	{siderCollapsed ? 	<Icon type="read" style={{fontSize: 32, position: "relative", top: "2px"}}/> 
			  					:	<Icon type="close" style={{fontSize: 24, position: "relative", top: "2px"}}/> }
			</Button>
	);
}

export default SideMenuDrawerButton;

