import React from 'react';
import {Menu, Icon, Layout} from 'antd';
import SyllabusAnchor from './SideMenuAnchors/SyllabusAnchor.js';
import CategoryAnchor from './SideMenuAnchors/CategoryAnchor.js';
import { Link as RouterLink, Route } from 'react-router-dom';

import allClasses from '../../content/courseContentIndex';

const SideMenu = ( {collapsed, setSider, changeDetailsTab, details, toggleIntro} ) => {

	const { Sider } = Layout;

	const majorCategories = allClasses.map(x=>x.category).filter((x, i, a) => a.indexOf(x) === i);
	const majorCategoryNodes = [].concat(...majorCategories.map( (val, index) =>
								[
						        <Menu.Item key={(index+2).toString()}>
					        		<RouterLink to={"/"+val.toLowerCase()}>
					             		<Icon type="bulb"/>
					             	 	<span className="nav-text">{val}</span>
					             	</RouterLink>
				           		</Menu.Item>,
				           		<Route key={val+index} path={"/"+val.toLowerCase()} render={(props) => (
				            			<CategoryAnchor {...props} category={val}/>
				            			)} />
				           		]
				            	));

	// might be fun to make the light bulbs twoTone with color ##ffff00 when selected.  Seems hard to figure out though.

	return (	<Sider
		      		  trigger={null}
			          breakpoint="lg"
			          collapsedWidth="0"
			          onBreakpoint={broken => setSider(broken)}
			          width="200"
			          collapsed={collapsed}>
			          <Menu
			            theme="dark"
			            mode="inline"
			            defaultSelectedKeys={["1"]}
			            style={{ textAlign: "left" }}>

			            	<Menu.Item key="1">
			            		<RouterLink to="/syllabus">
					              	<Icon type="bulb" />
					              	<span className="nav-text">Syllabus</span>
				              	</RouterLink>
				            </Menu.Item>

			            	<Route path="/syllabus" render={(props) => (
			            		<SyllabusAnchor {...props} details={details} changeDetailsTab={changeDetailsTab}/>
	            			)}/>
	            			
				            <Route exact path="/" render={(props) => (
			            		<SyllabusAnchor {...props} details={details} changeDetailsTab={changeDetailsTab}/>
		            		)}/>

				            {majorCategoryNodes}

				            {/*<Menu.Divider style={{backgroundColor: "#874d00"}}/>*/}

				            <Menu.Item key="menuprompts">
				             		<RouterLink to="/prompts">
				             			<Icon  />
				             	 		<span className="nav-text">Writing Prompts</span>
				             	 	</RouterLink>
				           	</Menu.Item>

				            <Menu.Item onClick={toggleIntro} >
				             		<Icon  />
				             	 	<span className="nav-text">Replay Intro</span>
				           	</Menu.Item>

			          </Menu>
			    </Sider>
			    );

}

export default SideMenu;