import React, { Component } from 'react';
import './AnimationCover.less';
import AnimationCanvas from '../AnimationCanvas/AnimationCanvas';
import AnimationPlayButton from '../AnimationButtons/AnimationPlayBtn';
import LoadingAnim from '../LoadingAnim/LoadingAnim';

//import anime from 'animejs';

import ContainerDimensions from 'react-container-dimensions';

import ThinkQuick from '../Music/ThinkQuickSmallBassy.mp3';
import ReactHowler from 'react-howler';
import raf from 'raf';

import SkipIntroBtn from '../SkipIntroBtn/SkipIntroBtn';

// uses requestanimationframe to control timing for updating seek.  See https://github.com/thangngoc89/react-howler/blob/master/examples/react/src/players/FullControl.js

class AnimationCover extends Component {

	state = {
		playing: false,
		beatsElapsed: 0,
		showButtons: true,
		playedOnce: false,
		showLoading: false,
		animationCanLoad: false,
	}

	togglePlaying = () => {
		this.setState(oldstate=>({playing: !oldstate.playing}));
	}

	componentWillUnmount () {
    	this.clearRAF()
	}

	handleOnPlay = () => {
		if (!this.state.beatsElapsed) {this.player.seek(0);}
	    this.setState({
	      playing: true
	    })
	    this.renderSeekPos();
	}

	renderSeekPos = () => {
	    this.setState({
	      	beatsElapsed: Math.floor((this.player.seek() + 0.1) / 0.3)		// each virtual beat lands 100ms before the actual beat so animations can land just before beat
	    })
	    if (this.state.beatsElapsed>=370) {
	    	this.handleOnEnd();
	    }
	    if (this.state.playing) {
	      	this._raf = raf(this.renderSeekPos);
    	}
	}

	handleOnEnd = () => {
		console.log("ended");
		this.player.stop();
		this.setState({
		      playing: false,
		})
		this.clearRAF();
	    if (document.fullscreenElement) { 
		    document.exitFullscreen() 
		  }
		if (!localStorage.getItem("skipAnimation")) {
			localStorage.setItem("skipAnimation", "true");
		}
		this.props.toggleIntro();
	}

	skipIntro = () => {
		if (!localStorage.getItem("skipAnimation")) {
			localStorage.setItem("skipAnimation", "true");
		}
		this.props.toggleIntro();
	}

	clearRAF = () => {
	    raf.cancel(this._raf)
	}

	startMovie = () => {
		const cover = document.getElementById("animationCover");
		const background = document.getElementById("animationCoverBackground");
		this.showLoading();
		background.addEventListener('transitionend', this.prepareAnimation);
		background.classList.add("animationCoverBackgroundHidden");
		if (window.innerWidth <= 961) { 
			cover.requestFullscreen();
		}
	}

	showLoading = () => {
		this.setState({showLoading: true})
	}

	prepareAnimation = () => {
		this.setState({showButtons: false, animationCanLoad: true})
	}


	render() {

		return (
			<div className="animationCover" id="animationCover">
				<ReactHowler  	
					src={ThinkQuick}
	            	playing={this.state.playing}
	            	preload={true}
	            	onEnd={this.handleOnEnd}
	            	onPlay={this.handleOnPlay}
	            	ref={(ref) => (this.player = ref)}
	            	loop={false}
	            	/>

				<div className="animationCover animationCoverContent">
		            <ContainerDimensions>
		            	{ ({ height, width }) => <AnimationCanvas 	beatsElapsed={this.state.beatsElapsed}
									            					togglePlaying={this.togglePlaying}
									            					playing={this.state.playing}
									            					height={height}
									            					width={width}
									            					animationCanLoad={this.state.animationCanLoad}
									            					/> }
		            </ContainerDimensions>
	            </div> 

	            <div className="animationCover animationCoverIntro animationCoverBackground" id="animationCoverBackground" />



	            <div className="animationCover animationCoverIntro" 
	            	onClick={(this.state.playing || this.state.beatsElapsed) ? this.togglePlaying : null}
	            	>

	            	{(this.state.beatsElapsed>2 && !this.state.playing) ? <SkipIntroBtn handleOnEnd={this.handleOnEnd}/> : null}

	            	<div style={{opacity: 0.01, position: "absolute", transform: "translateX -100vw", fontSize: "1px"}}>
						<span style={{fontFamily: 'Fira Mono'}}>GATC</span><span style={{fontFamily: 'VT323'}}></span>
						<span style={{fontFamily: "Dalek-Philosophy"}}>P</span>
						<span style={{fontFamily: "Primitive-Philosophy"}}>P</span>
						<span style={{fontFamily: "Unifraktur-Philosophy"}}>P</span>
						<span style={{fontWeight: 700}}>a</span>
						<span style={{fontFamily: "Ailerons-Contemporary-Issues"}}>C</span>
						<span style={{fontFamily: "TNG-Contemporary-Issues"}}>C</span>
						<span style={{fontFamily: "Halogen-Contemporary-Issues"}}>C</span>
					</div>
					{/*preload fonts for animation*/}

					{this.state.showButtons ? 	<>
													<div style={{	position: "absolute",
																	margin: "-145px 0 0 -140px",
																	top: "50%",
																	left: "50%",
																	width: 280, 
																	height: 260, 
																	padding: 30,
																	display: "flex",
																	flexDirection: "column"
																	}}>
														{!this.state.showLoading ? 
															<>
																<AnimationPlayButton 
																	click={this.startMovie} 
																	playing={this.state.playing}
																	>
																		PLAY INTRO
																</AnimationPlayButton>
																<p className="textCenter"> (please turn sound on) </p>
																<AnimationPlayButton 
																	click={this.skipIntro} 
																	playing={this.state.playing}
																	>
																		SKIP INTRO
																</AnimationPlayButton>
															</>
															:
															<LoadingAnim 
																togglePlaying={this.togglePlaying}/>
														}
													</div>
												</>
											: null}
				</div>



			</div>


		);
	}
}

export default AnimationCover;

// this should really be on window resize, not container resize.