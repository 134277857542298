import React from 'react';
import text1 from './textbook.jpg';
import { List } from 'antd';

const ReadingsContent = (props) => {
	return (
		<>
	    	<div style={{display: "flex", justifyContent: "center", padding: 0}}>
	    		<List
	    			itemLayout="vertical"
    				size="large"
    				header={
    					<div className="detailsContent">
    					<p>Please look up a specific class on the website to see the readings for that day.</p>
    					<p>All the readings (as well as audio and video media) are required unless explicitly designated as optional.  You are expected to come to class having done them. Many are short: you should reread them to make sure you have understood them to the best of your ability.</p>
    					<p>Please note that a reading being assigned should not be taken as an endorsement of its content. Some of the most famous philosophy texts are famous simply because they make such an obvious error that they work as great teaching tools. </p>
    					<p>You should have the textbook below before the course begins. Other required readings are linked on the website. </p>
    					</div>
    					}
    				>
					<List.Item 	extra={<a href="https://www.amazon.com/Philosophy-Questions-Seeking-Answers-Stephen-Stich/dp/0199329966"><img style={{width: "20vw", maxWidth: "160px", margin: 8, marginLeft: 16}} src={text1} alt="Philosophy: Asking Questions · Seeking Answers"/></a>}>
						        <List.Item.Meta
						          style={{paddingTop: "5px"}}
						          title={<a className="detailsReadingTitle" href="https://www.amazon.com/Philosophy-Questions-Seeking-Answers-Stephen-Stich/dp/0199329966">Philosophy: Asking Questions · Seeking Answers</a>}
						          description="Stephen Stich and Tom Donaldson"
						        />
							      <p className="detailsReadings">Available at the campus bookstore and on <a href="https://www.amazon.com/Philosophy-Questions-Seeking-Answers-Stephen-Stich/dp/0199329966">Amazon</a>.  You can also choose to rent a digital version from a purveyor such as <a href="https://www.redshelf.com/book/937227/philosophy-937227-9780190902506-stephen-stich-tom-donaldson">RedShelf</a>.
							      </p>
					</List.Item>
	    		</List>
	    	</div>
	    </>
	);
}

export default ReadingsContent;
