import React, {PureComponent} from 'react';
import './AnimationCanvas.less';

//import SceneWhatItsLike from '../Scenes/SceneWhatItsLike';
//import SceneComputer from '../Scenes/SceneComputer';
//import SceneFinal from '../Scenes/SceneFinal';

import DivsWhatItsLike from '../Scenes/DivsWhatItsLike';
import DivsComputer from '../Scenes/DivsComputer';
import DivsNews from '../Scenes/DivsNews';
import DivsEthics from '../Scenes/DivsEthics';
import DivsFinal from '../Scenes/DivsFinal';
import AnimationScript from '../Scenes/AnimationScript';





class AnimationCanvas extends PureComponent {


	render () {
		const beat = this.props.beatsElapsed;

		// ensure a 3:2 viewing canvas aspect ratio (doesn't work for super short wide canvas)
		const height = this.props.height;
		const width = this.props.width;
		const size = (height*2>=width*3) ? " tallCanvas" : (width*2>=height*3) ? " wideCanvas" : (width>=height) ? " topbars wideCanvas" : " sidebars tallCanvas";

		const canvasSize = (size===" tallCanvas") ? {top: `calc(50% - ${width*0.75}px)`} : (size===" topbars wideCanvas") ? {top: `calc(50% - ${width*0.33}px)`} : {};
		const canvasFontUnit = (size===" sidebars tallCanvas") ? {"--canvasvw": 66/100+"vh"} : {"--canvasvw": "1vw"};
		const canvasAmendments = {...canvasSize, ...canvasFontUnit};

		return (
				<div className={"animationCanvas"+size} style={canvasAmendments}>

					<DivsWhatItsLike size={size} beat={beat}/>
					<DivsComputer size={size} beat={beat}/>
					<DivsNews size={size} beat={beat}/>
					<DivsEthics size={size} beat={beat}/>
					<DivsFinal size={size} beat={beat}/>

					{ this.props.animationCanLoad ?
						<AnimationScript 	size={size} 
											beat={beat} 
											playing={this.props.playing}/> : null
					}

				</div>
			);
	}
}

export default AnimationCanvas;


// In vertical mode, assume a 2:3 ratio as the logical space and be aware that there will be overlap on some devices.  See
// https://felgo.com/doc/felgo-different-screen-sizes/
// for a helpful overview.

//maybe this can be a functional component?  Does not currently use lifecycle functions or state.  But it IS a purecomponent!  And that's important.

//<p style={{position: "absolute", color: "#fffbe6", fontSize: "14px", bottom: "10px", right: "6%"}} id="beatReadout">
//						{beat} + {size}
//					</p>
