import React from 'react';
import { Tabs } from 'antd';
import ReadingsContent from '../../content/syllabus/ReadingsContent';
import OutcomesContent from '../../content/syllabus/OutcomesContent';
import EvaluationContent from '../../content/syllabus/EvaluationContent';
import PoliciesContent from '../../content/syllabus/PoliciesContent';
import ResourcesContent from '../../content/syllabus/ResourcesContent';
import ContactContent from '../../content/syllabus/ContactContent';

const SyllabusDetails = (props) => {

	const TabPane = Tabs.TabPane;

	return (
			<>
				<h2 className="textCenter" id="placeblat"> Syllabus Details </h2> 
			  	<Tabs 	defaultActiveKey={props.detailsTabOpen} 
			  			activeKey={props.detailsTabOpen} 
			  			tabPosition="top"
			  			animated={false}
			  			onTabClick={props.changeDetailsTab}
			  			size={"large"}
			  			style={{minHeight: "660px"}}
			  		> 
			  		<TabPane tab="Outcomes" key="1" className="contentFontInfo" >
				    	<OutcomesContent />
				    </TabPane>
				    <TabPane tab="Readings" key="2" className="contentFontInfo" >  
				    	<ReadingsContent />
				    </TabPane>
				    <TabPane tab="Evaluation" key="3" className="contentFontInfo" >
				    	<EvaluationContent />
				    </TabPane>
				    <TabPane tab="Policies" key="4" className="contentFontInfo" >
				    	<PoliciesContent />
				    </TabPane>
				     <TabPane tab="Resources" key="5" className="contentFontInfo" >
				    	<ResourcesContent />
				    </TabPane>
				    <TabPane tab="Contact" key="6" className="contentFontInfo" >
				    	<ContactContent />
				    </TabPane>
			  	</Tabs>
		  	</>
		);

}

export default SyllabusDetails;