import { Component } from 'react';
import anime from 'animejs';
import beats from '../beats';


class AnimationScript extends Component {
	
	componentDidMount () {
		this.buildAnime();
	}


	componentDidUpdate(prevProps) {

		if (this.props.beat===1 ) {
			this.timeline.play();
		}
		if (this.props.playing 
				&& prevProps.beat!==this.props.beat 
				&& Math.abs(((this.props.beat-1)/3.68)-this.timeline.progress)>0.1
				&& this.props.beat<368) {
			console.log(`sync! Beats: ${this.props.beat}; Current Progress: ${this.timeline.progress}; ToSync: ${(this.props.beat-1)/3.68}`);
			this.timeline.pause();
			this.timeline.seek(beats(this.props.beat-1));
			this.timeline.play();
		}	// attempt to sync music and animation. 3.68 determined by total length of animation.

		if (this.props.playing !== prevProps.playing && this.props.beat>0) {
			if (this.props.playing) {
				this.timeline.play();
			} else {
				this.timeline.pause();
			}
		}
	}

	getEyesize = (size) => {
		switch (size) {
			case " tallCanvas":
				return [40, "vw"];
			case " wideCanvas":
				return [46, "vh"];
			case " topbars wideCanvas":
				return [40, "vh"];
			default:
				return [40, "vw"];
		}
	};

	getCompsize = (size) => {
		switch (size) {
			case " tallCanvas":
				return [2.5, 0, 30];
			case " sidebars tallCanvas":
				return [2.5, 0, 30];
			default:
				return [1.35, "-4vw", 14];
		}
	};

	getUploadDist = (size) => {
		return (size===" wideCanvas"||size===" topbars wideCanvas") ? "calc(-7 * var(--canvasvw))" : "calc(-20 * var(--canvasvw))";
	} 

	createScaleLoops = (loops) => {
		const arr = [];
		const totloops = loops*4;
		const x = 1;
		for (let i=0; i<totloops; i++) {
			arr.push({value: 1.15, duration: beats(x)});
			arr.push({value: 1, duration: beats(x)});
		}
		return arr;
	}

	createSkewLoops = (loops) => {
		const arr = [];
		const x = 1;
		for (let i=0; i<loops; i++) {
			arr.push({value: "5deg", duration: beats(x)});
			arr.push({value: "2deg", duration: beats(x)});
			arr.push({value: "5deg", duration: beats(x)});
			arr.push({value: 0, duration: beats(x)});
			arr.push({value: "-5deg", duration: beats(x)});
			arr.push({value: "-2deg", duration: beats(x)});
			arr.push({value: "-5deg", duration: beats(x)});
			arr.push({value: 0, duration: beats(x)});
		}
		return arr;
	}


	youBounceDimensions = [{value: 1.15, duration: beats(3), easing: 'easeOutQuart'},
					{value: 1.22, duration: beats(1.6), easing: 'easeOutQuart'},
					{value: 1.24, duration: beats(0.1), easing: 'easeOutQuart'},
					{value: 1.22, duration: beats(0.1), easing: 'easeOutQuart'},
					{value: 1.24, duration: beats(0.1), easing: 'easeOutQuart'},
					{value: 1.22, duration: beats(0.1), easing: 'easeOutQuart'},
					{value: 0.8, duration: beats(2), easing: 'easeOutQuart'},
					{value: 1, duration: beats(1), easing: 'easeOutBack'}];
	youBounceColors = [{value: "#faad14", duration:beats(6), easing: 'easeOutQuart'},
				{value: "#fffbe6", duration:beats(2), easing: 'easeOutQuart'}];

	you1Direction = {targets: ".WILYou1", color: this.youBounceColors}
	you2Direction = {targets: ".WILYou2", color: this.youBounceColors};

	timeline;	

	buildAnime = () => { 

		if (this.props.size === " wideCanvas" || this.props.size === " topbars wideCanvas") {
			this.you1Direction.translateX = {value: "calc(-17 * var(--canvasvw))", delay: beats(5), duration:beats(2), easing: 'easeOutBack'};
			this.you1Direction.scaleX = this.youBounceDimensions;
			this.you2Direction.translateX = {value: "calc(17 * var(--canvasvw))", delay: beats(5), duration:beats(2), easing: 'easeOutBack'};
			this.you2Direction.scaleX = this.youBounceDimensions;
		} else {
			this.you1Direction.translateY = {value: "calc(-14 * var(--canvasvw))", delay: beats(5), duration:beats(2), easing: 'easeOutBack'};
			this.you1Direction.scaleY = this.youBounceDimensions;
			this.you2Direction.translateY = {value: "calc(14 * var(--canvasvw))", delay: beats(5), duration:beats(2), easing: 'easeOutBack'};
			this.you1Direction.translateX = {value: 0};
			this.you2Direction.translateX = {value: 0};
			this.you2Direction.scaleY = this.youBounceDimensions;
		}


		this.timeline = anime.timeline({autoplay: false});
		this.timeline.add( {
			targets: ".WILEverWonderElem",
			opacity: [{value: 1, duration: 1000}],
			scale: [{value: 0.7, duration: 0},
					{value: 1, duration: 2000}],
			delay: anime.stagger(beats(2), {start: 0})	
			}, 0);
		this.timeline.add({
			targets: ".WILchunk1",
			opacity: 1, 
			duration: beats(2),
			scale: [{value: 0.9, delay: 0, duration: 0},
					{value: 1, delay: 10, duration: beats(0.5), easing: "easeOutQuart"}],
			}, beats(8));
		this.timeline.add({
			targets: ".WILchunk2",
			opacity: 1,
			duration: beats(2),
			scale: [{value: 0.9, delay: 0, duration: 0},
					{value: 1, delay: 10, duration: beats(0.5), easing: "easeOutQuart"}],
			}, beats(10));
		this.timeline.add({
			targets: ".WILchunk3",
			opacity: 1, 
			duration: beats(2),
			scale: [{value: 0.9, delay: 0, duration: 0},
					{value: 1, delay: 10, duration: beats(0.5), easing: "easeOutQuart"}],
			}, beats(12));
		this.timeline.add({
			targets: ".WILchunk3 .red",
			color: {value: "#008000", duration: beats(3)}
			}, beats(14));
		this.timeline.add({
			targets: ".WILMyRed",
			opacity: 0,
			scale: 0.8,
			duration: beats(2),
			easing: 'easeInBack',
			}, beats(15));
		this.timeline.add( {
			targets: ".WILEverWonderElem",
			scale: [{value: 1.04, duration: beats(2)},
					{value: 1, duration: beats(1)}],
			translateY: [{value: "-1.4vh", duration: beats(2), easing: "easeOutSine"},
					{value: 0, duration: beats(1)}],
			delay: anime.stagger(beats(2), {start: 0})	
			}, beats(16));
		this.timeline.add( {
			targets: ".WILEyesChunk",
			opacity: {value: 1, duration: beats(3)},
			translateX: [{value: "-3vw", duration: 0}, {value: 0, duration: beats(2)}],
			delay: anime.stagger(beats(1), {start: 0}),
			}, beats(24.5));
		this.timeline.add({
			targets: ".winkyeye",
			opacity: [{value: 1, duration: 100, delay: 0}],
			easing: "linear"
			}, beats(28));
		this.timeline.add({
			targets: ".winkyeyelid",
			top: (this.getEyesize(this.props.size)[0] * -3) + this.getEyesize(this.props.size)[1],
  			right: (this.getEyesize(this.props.size)[0] * 2) + this.getEyesize(this.props.size)[1],
			duration: 100,
			easing: "easeOutSine"
			}, beats(29));
		this.timeline.add({
			targets: ".WILEverWonder, .WILToBeIntro",
			translateY: [{value: "-1.4vh", duration: beats(2)},
						{value: 0, duration: beats(1)}]
			}, beats(29));
		this.timeline.add({
			targets: ".eyescale, .WILEyes",
			translateY: "100vh",
			duration: beats(1),
			easing: "linear",
			delay: 100,
			}, beats(31));
		this.timeline.add({
			targets: ".WILToBeIntro",
			opacity: {value: 1, duration: beats(1)},
			translateY: [{value: "-3vh", duration: 0},
						{value: 0, duration: beats(1)}],
			}, beats(32))		
		this.timeline.add({
			targets: ".eyescale, .WILEyes",
			opacity: 0,
			duration: 1,
			}, beats(34));	
		this.timeline.add( {
			targets: ".WILToBeItem",
			opacity: [{value: 1, duration: beats(1)}],
			scale: [{value: 0.9, duration: 0},
					{value: 1, duration: beats(1)}],
			delay: anime.stagger(beats(1)),	
			}, beats(33));
		this.timeline.add({
			targets: ".WILToBeItem, .WILToBeIntro",
			opacity: {value: 0, duration: beats(2), easing: "linear"},
			translateY: {value: "4vh", duration: beats(2), easing: 'easeInSine'},
			color: {value: "#faad14", duration: beats(3), easing: "linear"},
			}, beats(47));
		this.timeline.add({
			targets: ".WILEverWonderElem",
			scaleY: [{value: 0.6, duration: beats(1), easing: 'easeOutCubic'},
					{value: 1, duration: beats(1), easing:'easeOutBack'}],
			scaleX: [{value: 1.2, duration: beats(1), easing: 'easeOutCubic'},
					{value: 1, duration: beats(1), easing:'easeOutBack'}],
			delay: anime.stagger(beats(1)),
			}, beats(49));
		this.timeline.add({
			targets: ".WILYouYouContainer",
			opacity: 1, 
			duration: beats(1),
			scale: [{value: 0.9, delay: 0, duration: 0},
					{value: 1, delay: 10, duration: beats(1), easing: "easeOutQuart"}],
			}, beats(51));

		this.timeline.add(this.you1Direction, beats(52));
		this.timeline.add(this.you2Direction, beats(52));

		this.timeline.add({
			targets: ".WILQMark, .WILWhatMakes",
			opacity: 0,
			easing: "linear",
			duration: beats(0.5)
			}, beats(62));

		this.timeline.add({
			targets: ".WILYou1",
			rotate: {value: "-10deg", duration: beats(0.5)},
			translateX: {value: "calc(100 * var(--canvasvw))", delay: beats(0.5), duration: beats(3), easing: "easeInBack"},
			translateY: {value: (this.props.size === " wideCanvas" || this.props.size === " topbars wideCanvas") ? 0 : "calc(-14 * var(--canvasvw))" },
			opacity: {value: 0, delay: beats(3.5), duration: 10, easing: 'linear'},
			}, beats(63));

		this.timeline.add({
			targets: ".WILYou2",
			rotate: {value: "-10deg", duration: beats(0.5)},
			translateX: {value: "calc(100 * var(--canvasvw))", delay: beats(0.5), duration: beats(3), easing: "easeInBack"},
			translateY: {value: (this.props.size === " wideCanvas" || this.props.size === " topbars wideCanvas") ? 0 : "calc(14 * var(--canvasvw))" },
			opacity: {value: 0, delay: beats(3.5), duration: 10, easing: 'linear'},
			}, beats(63));


		// starting Computer Scenes

		this.timeline.add( {
			targets: ".GattacaString1",
			translateX: [{value: "700vw", duration: beats(70), easing: 'linear'}],
			}, beats(63));
		this.timeline.add( {
			targets: ".GattacaString2",
			translateX: [{value: "700vw", duration: beats(90), easing: 'linear'}],
			}, beats(63));
		this.timeline.add( {
			targets: ".GattacaString3",
			translateX: [{value: "700vw", duration: beats(110), easing: 'linear'}],
			}, beats(63));
		this.timeline.add({
			targets: ".CompWorry",
			scale: [{value: 0.8, duration: 0},
					{value: 1, duration: beats(2)}],
			opacity: {value: 1, duration: beats(0.5)},
			}, beats(65));
		this.timeline.add({
			targets: ".CompGE",
			scale: [{value: 0.8, duration: 0},
					{value: 1, duration: beats(2)}],
			opacity: {value: 1, duration: beats(0.5)},
			}, beats(73));
		this.timeline.add({
			targets: ".GATTACAletter",
			opacity: 0,
			scaleX: 0.2,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(30),
		}, beats(81));
		this.timeline.add({
			targets: ".ENHANCEletter",
			scaleX: 0.2,
			duration: beats(0.1),
		}, beats(80));
		this.timeline.add({
			targets: ".CompCognitive .ENHANCEletter",
			opacity: 1,
			scaleX: 1,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(81));
		this.timeline.add({
			targets: ".CompEnhancement .ENHANCEletter",
			opacity: 1, 
			scaleX: 1,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(30),
		}, beats(81.5));
		this.timeline.add({
			targets: ".CompAI1s, .CompAI0s",
			duration: 10,
			scaleX: 0.2,
		}, beats(88));
		this.timeline.add({
			targets: ".CompCognitive .ENHANCEletter",
			scaleX: 0.2,
			opacity: 0,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(30),
		}, beats(88.5));
		this.timeline.add({
			targets: ".CompEnhancement .ENHANCEletter",
			scaleX: 0.2,
			opacity: 0,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(88.5));
		this.timeline.add({
			targets: ".CompAI1s",
			scaleX: 1,
			opacity: 1,
			duration: 60,
			easing: 'easeInSine',
			delay: anime.stagger(30),
		}, beats(89));
		this.timeline.add({
			targets: ".CompAI0s",
			scaleX: 1,
			opacity: 1,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(89));
		this.timeline.add({
			targets: ".CompArtificial, .CompIntelligence",
			duration: 10,
			scaleX: 0.2,
			easing: 'linear',
		}, beats(89.5));
		this.timeline.add({
			targets: ".CompAI1s",
			scaleX: 0.2,
			opacity: 0,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(30),
		}, beats(90.5));
		this.timeline.add({
			targets: ".CompAI0s",
			scaleX: 0.2,
			opacity: 0,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(90.5));
		this.timeline.add({
			targets: ".CompArtificial",
			scaleX: 1,
			opacity: 1,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(90.5));
		this.timeline.add({
			targets: ".CompIntelligence",
			scaleX: 1,
			opacity: 1,
			easing: 'easeInSine',
			duration: beats(0.4),
			delay: anime.stagger(25),
		}, beats(90.5));
		this.timeline.add({
			targets: ".CompShouldWorry",
			translateY: "-10vh",
			opacity: 0,
			duration: beats(2),
			easing: "linear",
		}, beats(94));
		this.timeline.add({
			targets: ".ComputerIcon",
			translateY: [{value: "75vh", duration: 0},
						{value: this.getCompsize(this.props.size)[1], duration: beats(0.5)}],
			scale: [{value: this.getCompsize(this.props.size)[0], duration: 0}],
			easing: "easeOutCirc",
		}, beats(96));
		this.timeline.add({
			targets: ".CompArtificial",
			translateY: "-40vh", 
			easing: "easeOutSine",
			translateX: anime.stagger("3vw", {grid: [10, 1], from: 'center', axis: 'x'}),
			rotateZ: anime.stagger([0, 20], {grid: [10, 1], from: 'center', axis: 'x'}),
			duration: beats(1),
		}, beats(96));
		this.timeline.add({
			targets: ".CompArtificial",
			translateY: "90vh",
			easing: "easeInSine",
			translateX: anime.stagger("6vw", {grid: [10, 1], from: 'center', axis: 'x'}),
			rotateZ: anime.stagger([0, 40], {grid: [10, 1], from: 'center', axis: 'x'}),
			duration: beats(1),
		}, beats(97));
		this.timeline.add({
			targets: ".CompIntelligence",
			translateY: "-50vh", 
			easing: "easeOutSine",
			translateX: anime.stagger("3vw", {grid: [12, 1], from: 'center', axis: 'x'}),
			rotateZ: anime.stagger([0, 20], {grid: [12, 1], from: 'center', axis: 'x'}),
			duration: beats(1),
		}, beats(96));
		this.timeline.add({
			targets: ".CompIntelligence",
			translateY: "90vh",
			easing: "easeInSine",
			translateX: anime.stagger("6vw", {grid: [12, 1], from: 'center', axis: 'x'}),
			rotateZ: anime.stagger([0, 40], {grid: [12, 1], from: 'center', axis: 'x'}),
			duration: beats(1),
		}, beats(97));
		this.timeline.add({
			targets: ".CompUpload",
			opacity: 1,
			duration: beats(1),
			translateY: [{value: "10vw", duration: 0},
							{value: 0, duration: beats(1)}],
			easing: "linear",
		}, beats(98));
		this.timeline.add({
			targets: ".CompUpload",
			duration: beats(2),
			translateY: this.getUploadDist(this.props.size),
			scale: 0.3,
			opacity: 0,
			easing: 'easeInSine'
		}, beats(104));
		this.timeline.add({
			targets: ".ComputerIcon",
			scale: this.getCompsize(this.props.size)[2],
			duration: beats(9),
			easing: 'easeInQuad',
		}, beats(106));
		this.timeline.add({
			targets: ".CompScreenGridContainer",
			opacity: 1,
			duration: beats(2),
			easing: 'easeOutSine',
		}, beats(111))
		this.timeline.add({
			targets: ".ComputerIcon",
			opacity: 0,
			duration: beats(1),
			easing: 'linear'
		}, beats(114));
		this.timeline.add({
			targets: ".CompSimulationLetter",
			opacity: {value: 1, easing: 'linear', duration: 10},
			delay: anime.stagger(50),
		}, beats(116));
		this.timeline.add({
			targets: ".CompWhoa",
			opacity: [{value: 1, easing: 'easeOutSine', duration: beats(2)},
					{value: 0, easing: 'easeInQuad', duration: beats(1), delay: beats(3)}],
			translateY: [{value: "-15vh", duration: beats(5), easing: 'easeOutCirc'},
						{value: "-8vh", duration: beats(1), easing: 'easeInQuart'}]
		}, beats(121));
		this.timeline.add({
			targets: ".CompScreenGridContainer, .CompTyping, .WILEverWonder",
			opacity: 0,
			duration: beats(1),
			easing: 'linear',
		}, beats(128));

		// starting News scenes

		this.timeline.add({
			targets: ".newsTrustablesContainerContainer",
			opacity: 1,
			duration: beats(1),
			easing: 'linear'
		}, beats(128));

		this.timeline.add({
			targets: ".newsQMark",
			opacity: 0,
			duration: 1,
			easing: 'linear'
		}, beats(128));

		this.timeline.add({
			targets: ".newsTrustable",
			skew: this.createSkewLoops(8),
			scaleY: this.createScaleLoops(8),
			easing: "easeInOutSine",
		}, beats(129)-140);

		this.timeline.add({
			targets: ".newsTrustable7",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(129)-30);

		this.timeline.add({
			targets: ".newsTrustable2",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(133)-30);

		this.timeline.add({
			targets: ".newsTrustable4",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(137)-30);

		this.timeline.add({
			targets: ".newsTrustable1",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(141)-30);

		this.timeline.add({
			targets: ".newsTrustable8",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(145)-30);

		this.timeline.add({
			targets: ".newsTrustable10",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(149)-30);

		this.timeline.add({
			targets: ".newsTrustable5",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(153)-30);

		this.timeline.add({
			targets: ".newsTrustable9",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(157)-30);

		this.timeline.add({
			targets: ".newsTrustable3",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(161)-30);

		this.timeline.add({
			targets: ".newsTrustable6",
			scale: [{value: 1.8, duration: 0},
						{value: 1, duration: beats(2), easing: 'linear'}],
			opacity: {value: 1, duration: beats(2), easing: 'linear'}
		}, beats(165)-30);

		this.timeline.add({
			targets: ".WILEverWonder",
			opacity: 1,
			duration: beats(1),
			delay: 5,
			left: [	{value: "-100%", duration: 1},
					{value: "6%", duration: beats(4), easing: 'easeOutSine'}],
		}, beats(168));
		this.timeline.add({
			targets: ".newsCanTrust",
			opacity: {value: 1, duration: 300, easing: 'linear'},
			left: [	{value: "-100%", duration: 0},
							{value: 0, duration: beats(4), easing: 'easeOutSine'}
							],
		}, beats(172));
		this.timeline.add({
			targets: ".newsQMark",
			opacity: 1,
			duration: beats(1),
			easing: 'linear'
		}, beats(177));
		this.timeline.add({
			targets: ".newspaper",
			scale: [ 	{value: 0.1, duration: 0},
						{value: 1.15, duration: beats(8), easing: 'easeInQuart'},
						{value: 1, duration: beats(1), easing: 'easeOutQuart'}],
			rotate: {value: "6490deg", duration: beats(8), easing: 'easeInSine'},
			opacity: {value: 1, duration: beats(2)}
		}, beats(181));
		this.timeline.add({
			targets: ".newspaper",
			scale: 0.4,
			rotate: "5200deg",
			duration: beats(3),
			easing: 'easeInBack',
			opacity: {value: 0, duration: beats(2), delay: beats(1), easing: 'linear'}
		}, beats(192));
		this.timeline.add({
			targets: ".newsCanTrust, .newsTrustable",
			opacity: 0,
			duration: 100,
			easing: 'linear',
		}, beats(193));

		// beginning Ethics scripts

		this.timeline.add({
			targets: ".ethicsGround",
			left: 0,
			opacity: [{value: 0, duration: 0}, {value: 1, duration: 1, easing: 'linear'}],
			duration: beats(2),
			easing: 'easeInSine'
		}, beats(194));

		this.timeline.add({
			targets: 'ethicsPig3',
			duration: 0,
			scaleX: -1,
		}, beats(194));

		this.timeline.add({
			targets: ".ethicsPig3",
			translateY: [{value: "-2em", duration: beats(0.5), easing: 'easeInSine'},
						{value: 0, duration: beats(0.5), easing: 'easeOutSine'},
						{value: "-2em", duration: beats(0.5), easing: 'easeInSine'},
						{value: 0, duration: beats(0.5), easing: 'easeOutSine'}],
		}, beats(198));

		this.timeline.add({
			targets: ".ethicsOKLetter",
			opacity: 1,
			duration: 150,
			easing: 'linear',
			delay: anime.stagger(30),
		}, beats(195));

		this.timeline.add({
			targets: ".ethicsPig1",
			translateY: [{value: "-2em", duration: beats(0.5), easing: 'easeInSine'},
						{value: 0, duration: beats(0.5), easing: 'easeOutSine'},
						{value: "-2em", duration: beats(0.5), easing: 'easeInSine'},
						{value: 0, duration: beats(0.5), easing: 'easeOutSine'}],
		}, beats(200));

		this.timeline.add({
			targets: ".ethicsOink",
			translateY: [{value: "1.5em", duration: 0},
						{value: 0, duration: beats(5)}],
			opacity: [{value: 0, duration: 0},
						{value: 1, duration: beats(1)},
						{value: 0, duration: beats(1), delay: beats(3)}],
			easing: 'linear',
		}, beats(198));

		this.timeline.add({
			targets: ".ethicsKill",
			opacity: 1,
			duration: 300,
			easing: 'linear',
		}, beats(202));

		this.timeline.add({
			targets: ".ethicsKnifeBlock",
			opacity: [{value: 1, duration: beats(1), easing: 'linear'}]
		}, beats(202));

		this.timeline.add({
			targets: ".ethicsAnimalsLetter",
			opacity: 1,
			duration: 150,
			easing: 'linear',
			delay: anime.stagger(30),
		}, beats(204));

		this.timeline.add({
			targets: ".ethicsKnifeBlock",
			rotate: [{value: "45deg", duration: 0},
					{value: "-90deg", duration: beats(1)}],
			easing: 'easeInCirc',
			delay: anime.stagger(beats(1)),
		}, beats(205));

		this.timeline.add({
			targets: ".ethicsKnifeBlock",
			opacity: {value: 0, duration: 50, easing: 'linear'},
			delay: anime.stagger(beats(1)),
		}, beats(206));

		this.timeline.add({
			targets: ".ethicsPig",
			opacity: 0,
			easing: 'linear',
			duration: 1,
			delay: anime.stagger(beats(1)),
		}, beats(205.8));
		this.timeline.add({
			targets: ".ethicsMeat",
			opacity: {value: 1, easing: 'linear', duration: 1},
			scale: {value: 0.7, duration: 0},
			translateY: [{value: "-2em", duration: beats(0.25), easing: 'easeInSine'},
						{value: 0, duration: beats(0.25), easing: 'easeOutSine'},],
			delay: anime.stagger(beats(1)),
		}, beats(205.8));
		this.timeline.add({
			targets: ".ethicsGround, .ethicsVeggie",
			translateY: "-100vh",
			duration: beats(10),
			easing: "easeInOutQuad",
		}, beats(209));
		this.timeline.add({
			targets: ".ethicsOwe",
			translateY: [{value: "100vh", duration: 0},
						{value: "0vh", duration: beats(10)}],
			easing: "easeInOutQuad",
		}, beats(209));
		this.timeline.add({
			targets: ".ethicsLess",
			scale: 0.2,
			easing: "linear",
			duration: beats(12),
		}, beats(216));
		this.timeline.add({
			targets: ".ethicsPrivacy",
			translateY: [{value: "100vh", duration: 0},
						{value: "0vh", duration: beats(10)}],
			easing: "easeInOutQuad",
		}, beats(221));
		this.timeline.add({
			targets: ".ethicsOwe",
			translateY: "-100vh",
			duration: beats(10),
			easing: "easeInOutQuad",
		}, beats(221));
		this.timeline.add({
			targets: ".ethicsPrivacyWord",
			translateY: [{value: "0.55em", duration: 0},
						{value: "0", duration: beats(4), easing: "linear"},
						{value: "-0.2em", duration: beats(0.5), easing: "easeOutSine", delay: beats(2)},
						{value: "0", duration: beats(0.5), easing: "easeInSine"},
						{value: "0.55em", duration: beats(1), easing: "easeOutQuart", delay: beats(1)}],
		}, beats(231));
		this.timeline.add({
			targets: ".ethicsPrivacyWord",
			color: "#eb7c9c",
			duration: beats(1),
			easing: "linear",
		}, beats(237));
		this.timeline.add({
			targets: ".ethicsPrivacy",
			translateY: "-100vh",
			duration: beats(10),
			easing: "easeInOutQuad",
		}, beats(240));

		this.timeline.add({
			targets: ".ethicsOb",
			opacity: [{value: 1},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)}],
			duration: 1,
			delay: beats(2),
			easing: 'linear',
		}, beats(240));

		this.timeline.add({
			targets: ".ethicsSub",
			opacity: [{value: 0},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)},
						{value: 1, delay: beats(2)},
						{value: 0, delay: beats(2)}
						],
			duration: 1,
			delay: beats(2),
			easing: 'linear',
		}, beats(240));

		this.timeline.add({
			targets: ".ethicsJustWar",
			translateY: [{value: "100vh", duration: 0},
						{value: "0vh", duration: beats(10)}],
			easing: "easeInOutQuad",
		}, beats(240));
		this.timeline.add({
			targets: ".ethicsJustWar, .WILEverWonder",
			translateY: "-100vh",
			duration: beats(10),
			easing: "easeInOutQuad",
		}, beats(252));
		this.timeline.add({
			targets: ".ethicsDoorContainer",
			opacity: [{value: 0, duration: 0},
					{value: 1, duration: 1, easing: "linear"}],
		}, beats(260)-10);
		this.timeline.add({
			targets: ".ethicsHugeDoorContainer",
			opacity: [{value: 0, duration: 0},
					{value: 1, duration: 1, easing: "linear"}],
		}, beats(260.5));

		this.timeline.add({
			targets: ".ethicsSwing",
			perspective: "calc(0.1*var(--canvasvw))",
			duration: 0,
		}, beats(260)-10);
		this.timeline.add({
			targets: ".ethicsDoorContainer",
			perspective: "calc(0.1*var(--canvasvw))",
			duration: 0,
		}, beats(260)-10);
		this.timeline.add({
			targets: ".ethicsSwing",
			perspective: "calc(0.1*var(--canvasvw))",
			rotateX: "90deg",
			duration: beats(4),
			easing: "easeInSine",
			delay: anime.stagger(beats(2), {start: 0}),
		}, beats(260));

		this.timeline.add({
			targets: ".ethicsSwing",
			opacity: 0,
			duration: 1,
			easing: "linear",
			delay: anime.stagger(beats(2), {start: 0}),
		}, beats(264));

		this.timeline.add({
			targets: ".ethicsDoorContainer",
			perspective: "calc(12*var(--canvasvw))",
			rotateX: "90deg",
			duration: beats(8),
			easing: "easeInSine",
		}, beats(284));

		this.timeline.add({
			targets: ".ethicsDoorContainer",
			opacity: 0,
			duration: 1,
			easing: 'linear',
		}, beats(292)-1);
		
		this.timeline.add({
			targets: ".ethicsPurposeWord",
			translateY: "-100vh",
			duration: beats(2),
			easing: "easeInSine",
			delay: anime.stagger(beats(0.5))
		}, beats(286));

		this.timeline.add({
			targets: ".finalEverWonder",
			opacity: [{value: 0, duration: 0},
						{value: 1, duration: beats(10), easing: 'linear'}],
			scale: [{value: 0.4, duration: 0},
						{value: 1, duration: beats(12.5), easing: 'easeInQuad'}],
			rotate: [{value: "5deg", duration: 50, easing: "linear", delay: beats(12.5)},
					{value: "-5deg", duration: 100, easing: "linear"},
					{value: "5deg", duration: 100, easing: "linear"},
					{value: "-5deg", duration: 100, easing: "linear"},
					{value: "5deg", duration: 100, easing: "linear"},
					{value: "0deg", duration: 50, easing: "linear"},]
		}, beats(289));

		this.timeline.add({
			targets: ".ethicsHugeDoorContainer, .finalEverWonder",
			translateX: "100vw",
			easing: 'easeInQuad',
			duration: beats(12),
		}, beats(306));

		this.timeline.add({
			targets: ".finalWord",
			duration: 10,
			opacity: 1,
			easing: "linear",
			delay: anime.stagger(beats(2)),
		}, beats(321)-50);

		this.timeline.add({
			targets: ".finalWordP1",
			duration: 2,
			opacity: 0,
			easing: 'linear',
		}, beats(329)-90);

		this.timeline.add({
			targets: ".finalWordP2",
			duration: 2,
			opacity: 1,
			easing: 'linear',
		}, beats(329)-90);

		this.timeline.add({
			targets: ".finalWordC1",
			duration: 2,
			opacity: 0,
			easing: 'linear',
		}, beats(331)-90);

		this.timeline.add({
			targets: ".finalWordC2",
			duration: 2,
			opacity: 1,
			easing: 'linear',
		}, beats(331)-90);

		this.timeline.add({
			targets: ".finalWordP2",
			duration: 2,
			opacity: 0,
			easing: 'linear',
		}, beats(333)-90);

		this.timeline.add({
			targets: ".finalWordP3",
			duration: 2,
			opacity: 1,
			easing: 'linear',
		}, beats(333)-90);

		this.timeline.add({
			targets: ".finalWordC2",
			duration: 2,
			opacity: 0,
			easing: 'linear',
		}, beats(335)-100);

		this.timeline.add({
			targets: ".finalWordC3",
			duration: 2,
			opacity: 1,
			easing: 'linear',
		}, beats(335)-100);



// final movement
		this.timeline.add({
			targets: ".finalWords",
			scale: 0.85,
			translateY: "-12vh",
			duration: beats(6),
			easing: "easeInOutSine",
		}, beats(336));

		this.timeline.add({
			targets: ".finalCredit",
			opacity: 1,
			translateY: "-16vh", 
			duration: beats(20),
			easing: "easeOutSine",
		}, beats(340));

		this.timeline.add({
			targets: ".finalCredit, .finalWords",
			opacity: 0,
			duration: beats(4),
			easing: "linear",
		}, beats(364));
	}


	render () {
		return null;
	}
}

export default AnimationScript;