import React from 'react';
import { Divider } from 'antd';
import ReadingsContent from '../../content/syllabus/ReadingsContent';
import OutcomesContent from '../../content/syllabus/OutcomesContent';
import EvaluationContent from '../../content/syllabus/EvaluationContent';
import PoliciesContent from '../../content/syllabus/PoliciesContent';
import ContentCard from '../ContentCard/ContentCard';
import ResourcesContent from '../../content/syllabus/ResourcesContent';
import ContactContent from '../../content/syllabus/ContactContent';

const SyllabusDetailsSmall = (props) => {

	return (
	      	<>
	      	<ContentCard id="outcomes">
	      		<h2 className="textCenter"> Outcomes </h2>
	      		<Divider />
				<OutcomesContent />
			</ContentCard>
	      	<ContentCard id="readings">
	      		<h2 className="textCenter"> Readings </h2>
	      		<Divider />
				<ReadingsContent />
			</ContentCard>
			<ContentCard id="evaluation">
	      		<h2 className="textCenter"> Evaluation </h2>
	      		<Divider />
				<EvaluationContent />
			</ContentCard>
			<ContentCard id="policies">
	      		<h2 className="textCenter"> Policies </h2>
	      		<Divider />
				<PoliciesContent />
			</ContentCard>
			<ContentCard id="resources">
	      		<h2 className="textCenter"> Resources </h2>
	      		<Divider />
				<ResourcesContent />
			</ContentCard>
			<ContentCard id="contact">
	      		<h2 className="textCenter"> Contact </h2>
	      		<Divider />
				<ContactContent />
			</ContentCard>
			</>
		);

}

export default SyllabusDetailsSmall;